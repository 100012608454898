import { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { StoreContext } from "../../store/context";

import { useThemeStyles } from "../../styles/themes";

const useStyles = makeStyles((theme) => ({
  root: {
    "& h2": {
      fontFamily: "'rawline', 'Open Sans', sans-serif",
      color: "#231f20",
      fontWeight: 600,
    }
  },
  label: {
    fontFamily: "'rawline', 'Open Sans', sans-serif",
    fontWeight: "bold",
    textTransform: "capitalize",
    boxShadow: "unset",
    fontSize: "14px"
  },
}));

const GeneralModal = (props) => {
  const { actions, state } = useContext(StoreContext);
  const title = state.dialog.dialogTitle;
  const content = state.dialog.dialogContent;
  // const trimQuote = true;
  const classes = {
    ...useStyles(),
    ...useThemeStyles()
  };
  // let lastError;
  // const content = (typeof state.dialog.dialogContent === 'object' && state.dialog.dialogContent !== null) ?
  //   Object.values(content).map(function(obj){
  //     if ( obj !== lastError) {
  //       lastError = obj;
  //       return obj + '<br/>';
  //     }
  //   }).join('') : state.dialog.dialogContent;

  const handleClose = () => {
    actions.setDialog({ isDialogShow: false });
  };

  return (
    <Dialog
      open={state.isDialogShow}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" classes={{ root: classes.root }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{ __html: content }} id="alert-dialog-description" style={{ fontFamily: "'rawline', 'Open Sans', sans-serif", color: "#231f20" }} />
      </DialogContent>
      <DialogActions>
        <Button className={clsx(`${classes.btnOutlinedSecondary} btn-modal`, { "btn-boost": !state.prerequisites })} variant="outlined" color="secondary" onClick={handleClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralModal;
