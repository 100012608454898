import { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../store/context";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { useThemeStyles } from "../../styles/themes";
import "./ErrorPage.scss";

// const ERROR_MESSAGE_FOR_HTTP_502 = "Sorry, we are not able to process your application right now. Please try again later";
// const GENERIC_MESSAGE = "Sorry, we are not able to process your application right now. Please try again later";

const ErrorPage = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  // const [errorMsg, setErrorMsg] = useState("");

  // useEffect(() => {
  //   // populate additional error message
  //   if (state.errorPageError) {
  //     let msg = "";
  //     if (state.errorPageError.data?.error?.message?.body) {
  //       msg = state.errorPageError.data.error.message.body;
  //     }
  //     // else if (state.errorPageError.status == 502) {
  //     //   msg = ERROR_MESSAGE_FOR_HTTP_502;
  //     // } else {
  //     //   msg = GENERIC_MESSAGE;
  //     // }
  //     setErrorMsg(msg);
  //   } else {
  //     setErrorMsg("");
  //   }
  // }, [state.errorPageError]);

  const redirectHome = () => {
    if (state.pToken) {
      actions.cancelPayment();
    } else {
      window.location.href = process.env.REACT_APP_BNPL_DASHBOARD_URL;
    }
  };

  const handleContact = () => {
    window.open(`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`);
  };

  return (
    <>
      <Header />
      <div className="page page-error">
        <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
          <div className="banner-holder" style={{ backgroundImage: "url(/images/sorry-banner.svg)" }}>
            <p className="banner-title">Sorry...</p>
            <p className="banner-desc">We are unable to process your application right now.</p>
          </div>
          <p className="text-desc">
            We are currently experiencing technical difficulties and we apologise for any inconvenience this might have caused. Please come back and try again later.
            <br />
            <br />
            Thank you for your patience.
          </p>
          {/* {errorMsg && (
            <p className="text-desc">
              {errorMsg}
            </p>
          )} */}
          <div className="action-holder">
            {!state.embedded && (
              <Button className={clsx(`${classes.btnContained} btn-next`, { "btn-boost": !state.prerequisites })} variant="contained" color="primary" onClick={redirectHome}>
                Return Home
              </Button>
            )}
            <Button className={clsx(`${classes.btnOutlinedSecondary} btn-next btn-second`, { "btn-boost": !state.prerequisites })} variant="outlined" color="secondary" onClick={handleContact}>
              Contact Support
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withUbdAnalyticsStep(ErrorPage, {
  stepId: "ErrorPage",
  endJourneyOnStepStart: true,
});
