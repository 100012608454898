import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { StoreContext } from "../../store/context";
import Landing from "./Landing";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordOTP from "./ForgotPasswordOTP";
import NewPassword from "./NewPassword";
import PasswordSuccess from "./PasswordSuccess";
import SignInOTP from "./SignInOTP";

const METADATA_URL_PARAM_PREFIX = "mt_";
const enableOfflineCheckout = (process.env.REACT_APP_BNPL_CHECKOUT_OFFLINE_ENABLE == "1");

const getContent = (step) => {
  switch (step) {
    case "Landing":
      return <Landing />;
    case "SignInOTP":
      return <SignInOTP />;
    case "ForgotPassword":
      return <ForgotPassword />;
    case "ForgotPasswordOTP":
      return <ForgotPasswordOTP />;
    case "NewPassword":
      return <NewPassword />;
    case "PasswordSuccess":
      return <PasswordSuccess />;
    default:
      return null;
  }
};

const SignIn = () => {
  const { state, actions } = useContext(StoreContext);
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    actions.setData({
      currentPage: "signIn",
    });

    // Removes storage
    sessionStorage.removeItem("clientId");

    const clientId = params.get("clientId");
    if (clientId) {
      sessionStorage.setItem("clientId", clientId);
    }

    if (params.get("pToken")) {
      actions.setData({
        pToken: params.get("pToken"),
        clientId,
        step: "Landing",
      });
    } else if (enableOfflineCheckout && params.get("pMode") == "offline") {
      const metadata = {};
      params.forEach((value, key) => {
        if (key.startsWith(METADATA_URL_PARAM_PREFIX)) {
          metadata[key.replace(METADATA_URL_PARAM_PREFIX, "")] = value;
        }
      });
      actions.setData({
        pMode: params.get("pMode"),
        clientId,
        metadata,
        step: "Landing",
      });
    } else {
      actions.setStep(null);
      actions.showInvalidUrlDialog();
    }
  }, []);

  return (
    <>
      {getContent(state.step)}
    </>
  );
};

export default SignIn;
