import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import checkedBox from "../../assets/images/checkbox-active.svg";
import uncheckedBox from "../../assets/images/checkbox-inactive.svg";

const useStyles = makeStyles({
  icon: {
    borderRadius: 3,
    width: 21,
    height: 21,
    boxShadow: "0px 2px 4px #00000029;",
    border: "1px solid #99B5C5;",
    backgroundColor: "#FFFFFF",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  iconNew: {
    width: 24,
    height: 24,
  },
  root: {
    width: 41,
    height: 41
  }
});

const CustomCheckboxTerms = ({ newStyle = false, ...props }) => {
  const classes = useStyles();

  return newStyle ? (
    <>
      <Checkbox
        color="primary"
        disableRipple
        disableTouchRipple
        icon={(
          <img
            className={classes.iconNew}
            alt="checkbox"
            src={uncheckedBox}
          />
        )}
        checkedIcon={(
          <img
            className={classes.iconNew}
            alt="checkbox"
            src={checkedBox}
          />
        )}
        inputProps={{ "aria-label": "decorative checkbox" }}
        {...props}
      />
    </>
  ) : (
    <Checkbox
      classes={{ root: classes.root }}
      // disableRipple
      color="default"
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};

export default CustomCheckboxTerms;
