import _ from "lodash";
// Clevertap
import clevertap from "clevertap-web-sdk";
import {
  IS_LOADING,
  IS_BTN_LOADING,
  IS_PROCESSING,
  SET_DATA,
  SET_PAGE_DATA,
  RESET_DATA,
  SET_STEP,
  REMOVE_DATA,
  SET_DIALOG,
} from "../types";
import api from "../../utils/api";
import { APPLICATION_STATUS } from "../../constants/applicationStatus";

export const useActions = (state, dispatch) => {
  const showInvalidUrlDialog = () => {
    dispatch({
      type: SET_DIALOG,
      data: {
        dialog: {
          dialogTitle: "Error",
          dialogContent: "Invalid Url."
        },
        isDialogShow: true
      }
    });
  };

  const showErrorDialog = (error) => {
    dispatch({
      type: SET_DIALOG,
      data: {
        dialog: {
          dialogTitle: "Error",
          dialogContent: error?.data?.error?.message?.body ? error.data.error.message.body : "Something went wrong, Please try again later."
        },
        isDialogShow: true
      }
    });
  };

  const showCustomErrorDialog = (title, content) => {
    dispatch({
      type: SET_DIALOG,
      data: {
        dialog: {
          dialogTitle: title,
          dialogContent: content
        },
        isDialogShow: true
      }
    });
  };

  const terminateJourneyWithErrorPage = (error) => {
    dispatch({ type: SET_DATA, data: { currentPage: "error", errorPageError: error } });
  };

  const actions = {
    setLoading: (data) => {
      dispatch({ type: IS_LOADING, data });
    },
    setBtnLoading: (data) => {
      dispatch({ type: IS_BTN_LOADING, data });
    },
    setIsProcessing: (data) => {
      dispatch({ type: IS_PROCESSING, data });
    },
    setStep: (data) => {
      dispatch({ type: SET_STEP, data });
    },
    setData: (data, name) => {
      dispatch({ type: SET_DATA, data, name });
    },
    setPageData: (data, name) => {
      dispatch({ type: SET_PAGE_DATA, data, name });
    },
    removeData: (name) => {
      dispatch({ type: REMOVE_DATA, name });
    },
    resetData: () => {
      dispatch({ type: RESET_DATA });
    },
    setDialog: (data) => {
      dispatch({ type: SET_DIALOG, data });
    },
    showInvalidUrlDialog,
    terminateJourneyWithErrorPage,
    showCustomErrorDialog,
    cancelPayment: () => {
      dispatch({ type: IS_LOADING, data: true });
      return api.putPartner("/bnpl-gateway/checkout/cancel", {}, { Authorization: state.pToken }).then((res) => {
        window.location.href = res.data.redirectURL;
      }).catch((error) => {
        dispatch({ type: IS_LOADING, data: false });
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    prerequisites: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      const { clientId } = data;
      const dynamicPath = api.injectQueryParamString("/paylater/onboarding/prerequisite", {
        clientId
      });
      return api.get(dynamicPath).then((res) => {
        console.log("prerequisites", res);
        dispatch({ type: SET_DATA, data: { prerequisites: res.data } });
      }).catch((error) => {
        showErrorDialog(error);
        terminateJourneyWithErrorPage(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getPostcodes: () => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get("/paylater/onboarding/postcodes").then((res) => {
        console.log("getPostcodes", res);
        dispatch({ type: SET_DATA, data: { postcodes: res.data } });
      }).catch((error) => {
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    initialOnboarding: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      const { clientId } = state;
      const dynamicPath = api.injectQueryParamString("/paylater/onboarding/application", {
        clientId
      });
      return api.post(dynamicPath, data).then((res) => {
        console.log("initialOnboarding", res);
        dispatch({ type: SET_DATA, data: { applicationId: res.data.applicationId, step: "Step2OTP" } });
      }).catch((error) => {
        console.log("initialOnboarding error", error);
        if (error?.data.error?.code === 1626) {
          // PLS_HIGH_RISK_GRADE 1626
          dispatch({ type: SET_DATA, data: { step: "ScoringFailure" } });
        } else if ([1605, 1619, 1624, 1600, 1004].includes(error?.data.error?.code)) {
          /**
           * Non-Retry-able errorCodes, terminate journey
           * PLS_EXISTING_USER 1605
           * PLS_DEACTIVATED_APPLICATION_FOUND 1619
           * PLS_ACCOUNT_DISABLED 1624
           * PLS_RESEND_OTP_NOT_ALLOWED 1600
           * INVALID_ARGUMENT 1004
          */
          showErrorDialog(error);
          dispatch({ type: SET_DATA, data: { step: "ScoringFailure" } });
        } else {
          /**
           * Retry-able errorCodes
           * & any other errorCodes not captured by previous IF clauses.
          */
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    validateOnboardOTP: (data) => {
      console.log("validateOnboardOTP data", data);
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/paylater/onboarding/application/otp/validate", data).then((res) => {
        console.log("validateOnboardOTP", res);
        dispatch({ type: SET_DATA, data: { step: "Step3Password" } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    resendOnboardOTP: (data) => {
      // dispatch({ type: IS_LOADING, data: true });
      return api.post("/paylater/onboarding/application/otp/resend", data).then((res) => {
        console.log("resendOnboardOTP", res);
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        // dispatch({ type: IS_LOADING, data: false });
      });
    },
    setPassword: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/paylater/onboarding/application/password", data).then((res) => {
        console.log("setPassword", res);
        dispatch({ type: SET_DATA, data: { kycToken: res.data.kycToken, customerId: res.data.customerId, applicationId: res.data.applicationId } });
        actions.getSessionToken(res.data.customerId, res.data.kycToken);
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getSessionToken: (customerId, kycToken) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/customer/signin/session?customerId=${customerId}`, { Authorization: kycToken }).then((res) => {
        console.log("getSessionToken", res);
        // ls.set('sessionToken', res.data.sessionToken);
        dispatch({ type: SET_DATA, data: { sessionToken: res.data.sessionToken, step: "Step4Address" } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    updateOnboarding: (data, retry = true) => {
      console.log({ Authorization: state.sessionToken });
      dispatch({ type: IS_LOADING, data: true });
      return api.put(`/paylater/onboarding/application?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("updateOnboarding", res);
        if (state.step === "Step5EmploymentDetails") {
          actions.confirmApplication({ applicationId: state.applicationId });
        } else {
          dispatch({ type: SET_DATA, data: { step: "Step5EmploymentDetails" } });
        }
      }).catch((error) => {
        if (error?.status === 403 && retry) {
          getSessionToken().then((res) => {
            if (res) actions.updateOnboarding(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    confirmApplication: (data, retry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(`/paylater/onboarding/application/confirm?customerId=${state.customerId}`, {
        ...data,
        tncInd: true, // T&C consent flag
      }, { Authorization: state.sessionToken }).then((res) => {
        console.log("confirmApplication", res);
        dispatch({ type: SET_DATA, data: { applicationConfirmed: true } });
        dispatch({ type: SET_DATA, data: { step: "Step6KYC" } });
      }).catch((error) => {
        console.log("confirmApplication error", error);
        if (error?.status === 403 && retry) {
          getSessionToken().then((res) => {
            if (res) actions.confirmApplication(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    verifyKYC: (data, retry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(`/paylater/onboarding/kyc?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("verifyKYC", res);
        if (res.data.status === APPLICATION_STATUS.KYC_SUCCESS) {
          dispatch({ type: SET_DATA, data: { step: "Step7ShareLocation" } });
        }
      }).catch((error) => {
        if (error?.status === 403 && retry) {
          getSessionToken().then((res) => {
            if (res) actions.verifyKYC(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    creditScoring: (data, retry = true) => {
      dispatch({ type: SET_DATA, data: { step: "ScoringLoading" } });
      return api.post(`/paylater/onboarding/scoring?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("creditScoring", res);
        if (res.data?.status === APPLICATION_STATUS.CREDIT_SCORE_SUCCESS) {
          dispatch({
            type: SET_DATA,
            data: {
              step: "Step8Mandate",
              eligibilityAmount: res.data.eligibilityAmount
            }
          });
        }
      }).catch((error) => {
        console.log("creditScoring error", error);
        if (error?.status === 403 && retry) {
          getSessionToken().then((res) => {
            if (res) actions.creditScoring(data, false);
          });
        } else if (error?.data.error?.code === 1626) {
          // PLS_HIGH_RISK_GRADE 1626 (special handle)
          if (state.enableHighRiskQuestionnaire) {
            dispatch({ type: SET_DATA, data: { step: "StepHighRisk" } });
          } else {
            dispatch({ type: SET_DATA, data: { step: "ScoringFailure" } });
          }
        } else if ([1605, 1609].includes(error?.data.error?.code)) {
          /**
           * Show Scoring Failed page
           * PLS_EXISTING_USER 1605
           * PLS_CREDIT_SCORING_FAILED 1609
          */
          showErrorDialog(error);
          dispatch({ type: SET_DATA, data: { step: "ScoringFailure" } });
        } else if ([1610, 1004].includes(error?.data.error?.code)) {
          /**
           * Non-Retry-able errorCodes, Show Scoring Failed page
           * PLS_CREDIT_SCORING_SERVER_ERROR 1610
           * INVALID_ARGUMENT 1004
          */
          showErrorDialog(error);
          dispatch({ type: SET_DATA, data: { step: "ScoringFailure" } });
        } else if (error?.status >= 500) {
          // Temp Fix when backend down
          showErrorDialog(error);
          // showCustomErrorDialog("Error", "Sorry, we are not able to process your application right now. Please try again later");
          terminateJourneyWithErrorPage(error);
        } else {
          /**
           * Other errors, hide loading spinner
          */
          showErrorDialog(error);
          if (state.currentPage == "signUp" && state.step) {
            dispatch({ type: SET_DATA, data: { step: state.stepDefinition.alias.ScoringLoading } });
          }
        }
      });
    },
    initialMandate: (data, retry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(`/paylater/onboarding/mandate/initiate?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("initialMandate", res);
        createFormPost(res.data.mandateUrl);
        // window.location.href = res.data.mandateUrl;
      }).catch((error) => {
        if (error?.status === 403 && retry) {
          getSessionToken().then((res) => {
            if (res) actions.initialMandate(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getMandateStatus: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(`/paylater/onboarding/mandate/status?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("getMandateStatus ", res.data.status);
        if (res.data.status === "APPROVED") {
          // dispatch({ type: SET_DATA, data: { step: "ConfirmSignUpOTP" } });
          const confirmPreAuthSubmissionData = {
            applicationId: state.applicationId,
          };
          actions.confirmPreAuthSubmission(confirmPreAuthSubmissionData);
        } else if (res.data.status === "PENDING") {
          _.debounce(() => {
            console.log("debounce ", "getMandateStatus");
            actions.getMandateStatus(data, true);
          }, 30000);
        } else if (res.data.status === "NOT_FOUND") {
          showCustomErrorDialog("Error", "Mandate status not found.");
        }
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.getMandateStatus(data, false);
          });
        } else if (error.status === 400 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.getMandateStatus(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    resendContractOTP: (data, reTry = true) => {
      return api.post(`/paylater/onboarding/contract/otp?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("resendContractOTP", res);
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.resendContractOTP(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      });
    },
    verifyContractOTP: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(`/paylater/onboarding/contract/otp/validate?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("verifyContractOTP", res);
        const confirmPreAuthSubmissionData = {
          applicationId: state.applicationId,
        };
        actions.confirmPreAuthSubmission(confirmPreAuthSubmissionData);
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.verifyContractOTP(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    confirmPreAuthSubmission: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });

      return api.post(`/paylater/onboarding/contract/confirm?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("confirmPreAuthSubmission", res);

        handleApplicationSubmitted(state.applicationId, res.data.status, res.data.eligibilityAmount, res.data.requireConsentFlag);
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.confirmPreAuthSubmission(data, false);
          });
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    updateLimitConsent: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });

      // DEV:
      // if (state.debug) {
      //   dispatch({ type: SET_DATA, data: { applicationStatus: APPLICATION_STATUS.SUCCESS, step: "ConsentConfirmed" } });
      //   dispatch({ type: IS_LOADING, data: false });
      //   return;
      // }

      return api.put(`/paylater/onboarding/limit/consent?customerId=${state.customerId}`, {
        ...data,
        applicationId: state.applicationId
      }, { Authorization: state.sessionToken }).then((res) => {
        console.log("updateLimitConsent", res);

        // PayFlex send clevertap event after consent
        clevertap.event.push("BNPL_ACTIVATED", {});

        dispatch({ type: SET_DATA, data: { applicationStatus: APPLICATION_STATUS.SUCCESS, step: "ConsentConfirmed" } });
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.updateLimitConsent(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    signIn: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/customer/signin", data).then((res) => {
        if (res.status === 200) {
          // ls.set("kycToken", res.data.kycToken);
          state.customerId = res.data.customerId;
          state.kycToken = res.data.kycToken;
          actions.startTransaction();
          // dispatch({ type: SET_DATA, data : { customerId: res.data.customerId, kycToken: res.data.kycToken }});
        }
      }).catch((error) => {
        dispatch({ type: IS_LOADING, data: false });
        console.log("signIn error", error);
        if (error.data?.error.code === 1310) {
          dispatch({ type: SET_DATA, data: { credential: data, step: "SignInOTP" } });
          // {"status":{"code":400,"message":"HTTP 400 - Invalid request message!"},"error":{"code":1310,"message":{"style":"NONE","body":"6-digit OTP is required to proceed."}}}
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {

      });
    },
    signInResendOTP: (data, reTry = true) => {
      return api.post("/customer/signin/otp/resend", data).then((res) => {
        console.log("signInResendOTP", res);
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.signViaOTP(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      });
    },
    signInViaOTP: (data, reTry = true) => {
      return api.post("/customer/signin/otp/validate", data).then((res) => {
        console.log("signInViaOTP", res);
        if (res.status === 200) {
          // ls.set("kycToken", res.data.kycToken);
          state.customerId = res.data.customerId;
          state.kycToken = res.data.kycToken;
          state.credential = null;
          actions.startTransaction();
          // dispatch({ type: SET_DATA, data : { customerId: res.data.customerId, kycToken: res.data.kycToken }});
        }
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.signViaOTP(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      });
    },
    forgotPassword: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/customer/reset/password", data).then((res) => {
        dispatch({ type: SET_DATA, data: { step: "ForgotPasswordOTP" } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    forgotPasswordResendOTP: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/customer/reset/password/otp/resend", data).then((res) => {

      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    forgotPasswordValidateOTP: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post("/customer/reset/password/otp/validate", data).then((res) => {
        dispatch({ type: SET_DATA, data: { step: "NewPassword", kycToken: res.data.kycToken, customerId: res.data.customerId } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    resetPassword: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.put(`/customer/reset/password?customerId=${state.customerId}`, data, { Authorization: state.kycToken }).then((res) => {
        dispatch({ type: SET_DATA, data: { step: "PasswordSuccess" } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getSupplementary: (data, reTry = true) => {
      return api.get(`/paylater/transaction/supplementary?customerId=${state.customerId}&transactionId=${state.transaction.transactionId}&principal=${data.principal}&tenure=${data.tenure}&timeUnit=${data.timeUnit}`, { Authorization: state.sessionToken }).then((res) => {
        console.log("getSupplementary", res);
        if (res.data && res.data.length > 0) {
          dispatch({ type: SET_DATA, data: { supplementary: res.data[0], step: "Insurance" } });
        } else {
          const params = {
            transactionId: state.transaction.transactionId,
            paymentPlan: {
              timeUnit: data.timeUnit,
              tenure: data.tenure
            },
            supplementaryServiceIds: []
          };
          actions.updatePreviewTransaction(params);
        }
      }).catch((error) => {
        console.log("getSupplementary error", error);
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.getSupplementary(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      });
    },
    updatePreviewTransaction: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.put(`/paylater/transaction/preview?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("updatePreviewTransaction", res);
        dispatch({ type: SET_DATA, data: { preview: res.data } });
        if (state.skipInitiationAgreement === false) {
          actions.getInitiationAgreement();
        } else {
          dispatch({ type: SET_DATA, data: { step: "Contract" } });
        }
      }).catch((error) => {
        console.log("updatePreviewTransaction error", error);
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.updatePreviewTransaction(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    acceptTransaction: (data, reTry = true) => {
      return api.post(`/paylater/transaction/accept?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("acceptTransaction", res);

        if (res.status === 200) {
          dispatch({ type: SET_DATA, data: { step: "ContractOTP" } });
        }
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.acceptTransaction(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      });
    },
    transactionValidateOTP: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(`/paylater/transaction/otp/validate?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("transactionValidateOTP", res);
        actions.setStep("Processing");
        actions.confirmTransaction({ transactionId: data.transactionId, paymentPlan: { timeUnit: data.paymentPlan.timeUnit, tenure: data.paymentPlan.tenure } });
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.transactionValidateOTP(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    transactionResendOTP: (data, reTry = true) => {
      return api.post(`/paylater/transaction/otp/resend?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("transactionResendOTP", res);
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.transactionResendOTP(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      });
    },
    confirmTransaction: (data, reTry = true) => {
      return api.post(`/paylater/transaction/confirm?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
        console.log("confirmTransaction", res);
        dispatch({ type: IS_LOADING, data: false });
        dispatch({ type: SET_DATA, data: { step: "SuccessfulPayment", IS_LOADING: false } });
      }).catch((error) => {
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.confirmTransaction(data, false);
          });
        } else {
          dispatch({ type: IS_LOADING, data: false });
          showErrorDialog(error);
        }
      });
    },
    startTransaction: () => {
      dispatch({ type: IS_LOADING, data: true });
      getSessionToken().then((respond) => {
        if (respond) {
          if (state.pToken) {
            // online
            getTransactionToken();
          } else if (state.pMode == "offline") {
            // offline purchase
            getOfflineTransactionToken();
          }
        }
      });
    },
    getInitiationAgreement: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/paylater/transaction/initiationAgreement?customerId=${state.customerId}&transactionId=${state.transaction.transactionId}`, { Authorization: state.sessionToken }).then((res) => {
        console.log("getInitiationAgreement", res);
        if (res.data.skipInitiationAgreement === false) {
          dispatch({ type: SET_DATA, data: { initiationAgreement: res.data, step: "WakalahContract" } });
        } else {
          dispatch({ type: SET_DATA, data: { step: "Contract" } });
        }
      }).catch((error) => {
        console.log("getInitiationAgreement error", error);
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.getInitiationAgreement(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    acceptInitiationAgreement: (data, reTry = true) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(`/paylater/transaction/initiationAgreement/accept?customerId=${state.customerId}`, { transactionId: state.transaction.transactionId }, { Authorization: state.sessionToken }).then((res) => {
        console.log("acceptInitiationAgreement", res);
        dispatch({ type: SET_DATA, data: { step: "Contract" } });
      }).catch((error) => {
        console.log("acceptInitiationAgreement error", error);
        if (error.status === 403 && reTry) {
          getSessionToken().then((res) => {
            if (res) actions.acceptInitiationAgreement(data, false);
          });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getPiiData: () => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/paylater/onboarding/piiData?customerId=${state.customerId}&applicationId=${state.applicationId}`, { Authorization: state.sessionToken }).then((res) => {
        console.log("getPiiData", res);
        dispatch({ type: SET_DATA, data: { piiData: res.data, step: "Step1PersonalDetails" } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getKycToken: () => {
      dispatch({ type: IS_LOADING, data: true });
      actions.setStep(null); // prevent showing empty form too fast
      return api.get(`/paylater/monitoring/configuration?loginToken=${state.lToken}&applicationId=${state.applicationId}`).then((res) => {
        state.kycToken = res.data.kycToken;
        state.customerId = res.data.customerId;
        dispatch({ type: SET_DATA, data: { kycToken: res.data.kycToken, customerId: res.data.customerId } });

        getSessionToken().then((getSessionTokenRes) => {
          // resume journey
          if (res.data.status == APPLICATION_STATUS.CONSENT_PENDING || res.data.status == APPLICATION_STATUS.MANDATE_APPROVED) {
            handleApplicationSubmitted(state.applicationId, res.data.status, res.data.eligibilityAmount, res.data.requireConsentFlag);
          } else if (getSessionTokenRes) { actions.getPiiData(); }
        });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getBankList: () => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get("/paylater/onboarding/banks").then((res) => {
        dispatch({ type: SET_DATA, data: { banks: res.data } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    getHighRiskQuestionnaire: () => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(
        `/paylater/onboarding/assessment/questionnaire?applicationId=${state.applicationId}&customerId=${state.customerId}`, { Authorization: state.sessionToken }
      ).then((res) => {
        dispatch({ type: SET_DATA, data: { highRisk: res.data } });
      }).catch((error) => {
        showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    performHighRiskGrading: (data) => {
      dispatch({ type: IS_LOADING, data: true });
      return api.post(
        `/paylater/onboarding/assessment/grade?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }
      ).then((res) => {
        console.log("performHighRiskGrading", res);
        if (res.data?.status === APPLICATION_STATUS.CREDIT_SCORE_SUCCESS) {
          dispatch({
            type: SET_DATA,
            data: {
              step: "Step8Mandate",
              eligibilityAmount: res.data.eligibilityAmount
            }
          });
        }
      }).catch((error) => {
        console.log("performHighRiskGrading error", error);
        if (error?.data.error?.code === 1626) {
          dispatch({ type: SET_DATA, data: { step: "ScoringFailure" } });
        } else {
          showErrorDialog(error);
        }
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
    initiateOfflineTransaction: (data) => {
      state.transactionAmount = data.amount;
      state.metadata = {
        ...state.metadata,
        userNotes: data.notes
      };
      initiateTransaction();
    },
    getDashboardUrl: () => {
      dispatch({ type: IS_LOADING, data: true });
      return api.get(`/bnpl-gateway/onboarding/dashboard/url?customerId=${state.customerId}&applicationId=${state.applicationId}`, { Authorization: state.sessionToken }).then((res) => {
        console.log("getDashboardUrl", res);
        dispatch({ type: SET_DATA, data: { dashboardUrl: res.data.redirectionUrl } });
      }).catch((error) => {
        // showErrorDialog(error);
      }).finally(() => {
        dispatch({ type: IS_LOADING, data: false });
      });
    },
  };

  const handleApplicationSubmitted = (applicationId, applicationStatus, eligibilityAmount, requireConsentFlag) => {
    // Check applicationStatus to resume journey
    if (applicationStatus == APPLICATION_STATUS.CONSENT_PENDING || applicationStatus == APPLICATION_STATUS.SUCCESS) {
      // Non PayFlex send clevertap event without consent
      if (!requireConsentFlag) {
        clevertap.event.push("BNPL_ACTIVATED", {});
      }

      dispatch({ type: SET_DATA,
        data: {
          eligibilityAmount: eligibilityAmount,
          requireConsentFlag: requireConsentFlag,
          applicationStatus: applicationStatus,
          step: "ApplicationSubmitted",
        }
      });
    } else if (applicationStatus == APPLICATION_STATUS.MANDATE_APPROVED && applicationId) {
      actions.getMandateStatus({ applicationId }, false);
    }
  };

  const getSessionToken = () => {
    console.log({ Authorization: state.kycToken });
    return api.get(`/customer/signin/session?customerId=${state.customerId}`, { Authorization: state.kycToken }).then((res) => {
      // ls.set('sessionToken', res.data.sessionToken);
      state.sessionToken = res.data.sessionToken;
      dispatch({ type: SET_DATA, data: { sessionToken: res.data.sessionToken } });
      return true;
    }).catch((error) => {
      dispatch({ type: IS_LOADING, data: false });
      showErrorDialog(error);
      return false;
    });
  };

  const getTransactionToken = () => {
    dispatch({ type: IS_LOADING, data: true });
    return api.postPartner("/bnpl-gateway/checkout/transaction", { customerId: state.customerId }, { Authorization: state.pToken }).then((res) => {
      console.log("getTransactionToken", res);
      state.transactionToken = res.data.transactionToken;
      state.payment_uuid = res.data.payment_uuid;
      state.orderId = res.data.order_id;
      state.customer = res.data.customer;
      state.merchant = res.data.merchant;
      state.transaction_description = res.data.transaction_description;
      state.ex_merchant_id = res.data.ex_merchant_id;
      state.ex_sub_merchant_id = res.data.ex_sub_merchant_id;
      state.metadata = res.data.metadata;
      state.transaction_api_version = res.data.api_version;

      // online purchase: immediately initiate transaction
      initiateTransaction();
    }).catch((error) => {
      dispatch({ type: IS_LOADING, data: false });
      showErrorDialog(error);
    }).finally(() => {
    });
  };

  const getOfflineTransactionToken = (reTry = true) => {
    dispatch({ type: IS_LOADING, data: true });
    return api.postPartner("/bnpl-gateway/checkout/offline", { customerId: state.customerId, clientId: state.clientId }, { Authorization: state.sessionToken }).then((res) => {
      console.log("getOfflineTransactionToken", res);
      state.transactionToken = res.data.transactionToken;
      // state.payment_uuid = res.data.payment_uuid; // offline no paymentUuid
      state.orderId = res.data.order_id;
      state.customer = res.data.customer;
      state.merchant = res.data.merchant;
      state.transaction_description = res.data.transaction_description;
      state.ex_merchant_id = res.data.ex_merchant_id;
      state.ex_sub_merchant_id = res.data.ex_sub_merchant_id;
      state.metadata = {
        ...res.data.metadata,
        ...state.metadata
      };
      state.transaction_min_amount = res.data.min_amount;
      state.transaction_max_amount = res.data.max_amount;
      state.transaction_api_version = res.data.api_version;

      // offline purchase: redirect to offline UI
      dispatch({ type: SET_DATA, data: { ...state, currentPage: "transaction", step: "OfflinePurchase" } });
    }).catch((error) => {
      console.log("getOfflineTransactionToken error", error);
      if (error.status === 403 && reTry) {
        getSessionToken().then((res) => {
          if (res) getOfflineTransactionToken(false);
        });
      } else {
        dispatch({ type: SET_DATA, data: { step: "Landing" } });
        showErrorDialog(error);
      }
    }).finally(() => {
      dispatch({ type: IS_LOADING, data: false });
    });
  };

  const initiateTransaction = (reTry = true) => {
    dispatch({ type: IS_LOADING, data: true });
    const data = {
      orderId: state.orderId,
      transactionToken: state.transactionToken,
      transactionDescription: state.transaction_description,
      merchantId: state.ex_merchant_id,
      subMerchantId: state.ex_sub_merchant_id,
      metadata: state.metadata,
      amount: state.transactionAmount // for offline purchase
    };
    return api.post(`/paylater/transaction/initiate?customerId=${state.customerId}`, data, { Authorization: state.sessionToken }).then((res) => {
      console.log("initiateTransaction", res);
      state.transaction = res.data;
      state.skipInitiationAgreement = res.data.skipInitiationAgreement;
      if (state.pToken) {
        // online
        updateBnplGatewayPayment();
      } else if (state.pMode == "offline") {
        // offline purchase
        dispatch({ type: SET_DATA, data: { ...state, currentPage: "transaction", step: "PaymentPlan" } });
      }
    }).catch((error) => {
      console.log("initiateTransaction error", error);
      if (error.status === 403 && reTry) {
        getSessionToken().then((res) => {
          if (res) initiateTransaction(false);
        });
      } else {
        if (state.pToken) {
          dispatch({ type: SET_DATA, data: { step: "Landing" } });
        }
        showErrorDialog(error);
      }
    }).finally(() => {
      dispatch({ type: IS_LOADING, data: false });
    });
  };

  const updateBnplGatewayPayment = () => {
    const data = {
      transactionId: state.transaction.transactionId,
      customerId: state.customerId
    };
    return api.putPartner("/bnpl-gateway/checkout/payment", data, { Authorization: state.pToken }).then((res) => {
      console.log("updateBnplGatewayPayment", res);
      dispatch({ type: SET_DATA, data: { ...state, currentPage: "transaction", step: "PaymentPlan" } });
    }).catch((error) => {
      dispatch({ type: IS_LOADING, data: false });
      showErrorDialog(error);
    });
  };

  const createFormPost = (url) => {
    const params = {};
    const urlDestructure = url.split("?");
    const path = urlDestructure[0];
    const queryString = urlDestructure[1];
    const sURLVariables = queryString.split("&");
    for (let i = 0; i < sURLVariables.length; i++) {
      const [sParameterKey, sParameterValue] = sURLVariables[i].split("=");
      params[sParameterKey] = sParameterValue;
    }

    const form = document.createElement("form");
    form.method = "post";
    form.action = path;
    form.enctype = "application/x-www-form-urlencoded";

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = decodeURIComponent(params[key].replace(/\+/g, " "));
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  };

  return actions;
};
