import { unstable_createMuiStrictModeTheme as createMuiTheme, makeStyles } from "@material-ui/core";
import { BaseColorPalette, DerivedColorPalette } from "./colors";

/**
 * @type { import("@material-ui/core").ThemeOptions }
 */
const AspirasiDefaultMuiThemeObj = {
  typography: {
    fontFamily: "'rawline', 'Open Sans', sans-serif",
  },
  palette: {
    primary: {
      light: BaseColorPalette.colorPrimaryLight,
      main: BaseColorPalette.colorPrimary,
      dark: BaseColorPalette.colorPrimaryDark,
      contrastText: BaseColorPalette.colorWhite
    },
    secondary: {
      light: BaseColorPalette.colorSecondaryLight,
      main: BaseColorPalette.colorSecondary,
      dark: BaseColorPalette.colorSecondaryDark,
      contrastText: BaseColorPalette.colorWhite
    },
  },
  overrides: {
    MuiButton: {
      root: {
        outline: "0",
      },
      contained: {
        "&$disabled": {
          color: BaseColorPalette.colorWhite,
          backgroundColor: BaseColorPalette.colorPrimaryLighter,
        },
      }
    },
  },
  colors: {
    ...BaseColorPalette,
    ...DerivedColorPalette
  }
};

const createDefaultMuiTheme = () => createMuiTheme(AspirasiDefaultMuiThemeObj);

const createDynamicMuiTheme = (themingConfigurations) => {
  const dynamicTheme = createMuiTheme({
    ...AspirasiDefaultMuiThemeObj,
    custom: themingConfigurations?.uiProperty
  });
  console.log("theme changed:", dynamicTheme);
  return dynamicTheme;
};

// Theme Styles
const important = (cssVal) => (`${cssVal} !important`);
const importantAll = (cssObject) => {
  const newCssObj = {};
  Object.keys(cssObject).forEach((cssKey) => {
    newCssObj[cssKey] = important(cssObject[cssKey]);
  });
  return newCssObj;
};
const getFontThemeConfig = (fontObj, setImportant = true, ignoredProps = []) => {
  const temp = {
    fontSize: setImportant ? important(fontObj.size) : fontObj.size,
    fontWeight: setImportant ? important(fontObj.weight) : fontObj.weight,
    textTransform: setImportant ? important(fontObj.textTransform) : fontObj.textTransform,
    color: setImportant ? important(fontObj.color) : fontObj.color,
    textAlign: setImportant ? important(fontObj.alignment) : fontObj.alignment,
    textDecoration: setImportant ? important(fontObj.textDecoration) : fontObj.textDecoration,
    textDecorationColor: setImportant ? important(fontObj.textDecorationColor) : fontObj.textDecorationColor,
  };
  ignoredProps.forEach((propName) => {
    delete temp[propName];
  });
  return temp;
};

const useThemeStyles = makeStyles((theme) => {
  return {
    content: {
      fontSize: "16px",
      fontWeight: "normal",
      textTransform: "none",
      textAlign: "left",
      color: theme.colors.colorPrimaryText,
      ...theme.custom && {
        ...getFontThemeConfig(theme.custom.body.content.font, false),
      },
      "& p, & a": {
        color: theme.colors.colorPrimaryText,
        ...theme.custom && {
          color: theme.custom.body.content.font.color,
        },
        "&.center": {
          textAlign: "center",
        }
      },
    },
    headerText: {
      ...importantAll({
        background: theme.colors.colorWhite,
        opacity: "1",
        color: theme.colors.colorHeaderPartnerPowerByText, // aspirasi: colorPrimary,
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "uppercase",
        textAlign: "left",
      }),
      ...theme.custom && {
        background: important(theme.custom.header.background),
        opacity: important(theme.custom.header.opacity),
        ...getFontThemeConfig(theme.custom.header.font)
      },
    },
    stepText: {
      ...importantAll({
        color: theme.colors.colorPrimary,
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "uppercase",
        textAlign: "left",
      }),
      ...theme.custom && {
        ...getFontThemeConfig(theme.custom.body.stepNumber.font)
      },
    },
    footer: {
      background: theme.colors.colorFooterBackground,
      ...theme.custom && {
        background: important(theme.custom.footer.background),
        opacity: important(theme.custom.footer.opacity)
      },
      "& a, & span": {
        fontSize: important("12px"),
        color: important(theme.colors.colorFooterText),
        textDecoration: important("none"),
        ...theme.custom && {
          ...getFontThemeConfig(theme.custom.footer.font)
        },
        "&:hover": {
          textDecoration: important("underline")
        }
      }
    },
    title: {
      ...({
        color: theme.colors.colorPrimaryText,
        fontSize: "28px !important",
        fontWeight: "700 !important",
        textTransform: "capitalize !important",
        textAlign: "left !important",
        lineHeight: "1.3em !important"
      }),
      ...theme.custom && {
        ...getFontThemeConfig(theme.custom.body.heading.font)
      },
      "&.center": {
        textAlign: important("center")
      },
      "&.regular": {
        fontWeight: important("400")
      }
    },
    subtitle: {
      ...({
        color: theme.colors.colorPrimaryText,
        fontSize: "16px !important",
        textAlign: "left !important",
        lineHeight: "1.3em !important"
      }),
      ...theme.custom && {
        ...getFontThemeConfig(theme.custom.body.subHeading.font)
      },
      "&.small": {
        fontSize: important("14px"),
      },
      "&.bold": {
        fontWeight: important("bold")
      },
      "&.center": {
        textAlign: important("center")
      }
    },
    divider: {
      borderTop: `1px solid ${theme.colors.colorDivider}`,
      ...theme.custom && {
        borderTop: important(theme.custom.body.divider.template1.border),
        opacity: important(theme.custom.body.divider.template1.opacity),
      }
    },
    verticalDivider: {
      borderLeft: `1px solid ${theme.colors.colorDivider}`,
      ...theme.custom && {
        borderLeft: important(theme.custom.body.divider.template1.border),
        opacity: important(theme.custom.body.divider.template1.opacity),
      }
    },
    btnContained: {
      // color: theme.colors.colorWhite,
      // backgroundColor: theme.colors.colorPrimary,
      borderRadius: important("24px"),
      // fontSize: important("16px"),
      // fontWeight: important("bold"),
      textTransform: important("capitalize"),
      ...theme.custom && {
        background: important(theme.custom.body.button.template1.background),
        // borderRadius: important(theme.custom.body.button.template1.borderRadius),
        border: important(theme.custom.body.button.template1.border),
        opacity: important(theme.custom.body.button.template1.opacity),
        ...getFontThemeConfig(theme.custom.body.button.template1.font, true, ["textTransform"]),
        "&.Mui-disabled": {
          color: important(theme.colors.colorWhite),
          background: important(theme.colors.colorButtonDisabledBg)
        }
      },
      // "&.btn-boost": {
      //   color: important("#FFFFFF"),
      //   border: important("0px"),
      //   opacity: important("1"),
      //   fontSize: important("16px"),
      //   background: important("#ea0029"),
      //   textAlign: important("center"),
      //   fontWeight: important("bold"),
      //   // borderRadius: important("8px"),
      //   // textTransform: important("uppercase"),
      //   textDecoration: important("none"),
      // },
      "&.btn-modal": {
        fontSize: important("14px"),
        textTransform: important("capitalize"),
      },
      "&.btn-big": {
        borderRadius: important("8px"),
      }
    },
    btnOutlinedSecondary: {
      // color: theme.colors.colorPrimary,
      // backgroundColor: theme.colors.colorWhite,
      // borderColor: theme.colors.colorPrimary,
      borderRadius: important("24px"),
      // fontSize: important("16px"),
      // fontWeight: important("bold"),
      textTransform: important("capitalize"),
      ...theme.custom && {
        background: important(theme.custom.body.button.template2.background),
        // borderRadius: important(theme.custom.body.button.template2.borderRadius),
        border: important(theme.custom.body.button.template2.border),
        opacity: important(theme.custom.body.button.template2.opacity),
        ...getFontThemeConfig(theme.custom.body.button.template2.font, true, ["textTransform"]),
        "&.Mui-disabled": {
          border: important("0px"),
          color: important(theme.colors.colorWhite),
          background: important(theme.colors.colorButtonDisabledBg)
        }
      },
      // "&.btn-boost": {
      //   color: important("#124A67"),
      //   border: important("1.5px solid #124A67"),
      //   opacity: important("1"),
      //   fontSize: important("16px"),
      //   background: important("#FFFFFF"),
      //   textAlign: important("center"),
      //   fontWeight: important("bold"),
      //   // borderRadius: important("8px"),
      //   // textTransform: important("uppercase"),
      //   textDecoration: important("none"),
      // },
      "&.btn-modal": {
        fontSize: important("14px"),
        textTransform: important("capitalize"),
      }
    },
    inputAutoFilled: {
      ...importantAll({
        color: theme.colors.colorPrimaryDarker,
        fontSize: "16px",
        lineHeight: "1.3em"
      }),
      "&.bold": {
        fontWeight: important("bold")
      },
    },
    inputTextBox: {
      ...theme.custom && {
        // background: important(theme.custom.body.inputField.textBox.template1.background),
        borderRadius: important(theme.custom.body.inputField.textBox.template1.borderRadius),
        border: important(theme.custom.body.inputField.textBox.template1.border),
        opacity: important(theme.custom.body.inputField.textBox.template1.opacity),
        "&:focus, &:focus-within": {
          boxShadow: important(theme.custom.body.inputField.textBox.template1.boxShadow || `0px 0px 6px ${theme.colors.colorBoxShadow}`),
        },
        ...getFontThemeConfig(theme.custom.body.inputField.textBox.template1.font),
        "&.form-error-border": {
          border: important(`1px solid ${theme.colors.colorFormInputErrorBorder}`),
          backgroundColor: important(theme.colors.colorFormInputErrorBackgroundColor),
        }
      }
    },
    inputTextArea: {
      ...theme.custom && {
        // background: important(theme.custom.body.inputField.textBox.template1.background),
        borderRadius: important(theme.custom.body.inputField.textBox.template1.borderRadius),
        border: important(theme.custom.body.inputField.textBox.template1.border),
        opacity: important(theme.custom.body.inputField.textBox.template1.opacity),
        "&:focus, &:focus-within": {
          boxShadow: important(theme.custom.body.inputField.textBox.template1.boxShadow || `0px 0px 6px ${theme.colors.colorBoxShadow}`),
        },
        ...getFontThemeConfig(theme.custom.body.inputField.textBox.template1.font)
      }
    },
    inputSelectBox: {
      ...theme.custom && {
        // background: important(theme.custom.body.inputField.selectBox.template1.background),
        borderRadius: important(theme.custom.body.inputField.selectBox.template1.borderRadius),
        border: important(theme.custom.body.inputField.selectBox.template1.border),
        opacity: important(theme.custom.body.inputField.selectBox.template1.opacity),
        "&:focus, &:focus-within": {
          boxShadow: important(theme.custom.body.inputField.selectBox.template1.boxShadow),
        },
        ...getFontThemeConfig(theme.custom.body.inputField.selectBox.template1.font),
        "& .MuiAutocomplete-popupIndicator": {
          color: important(theme.custom.body.inputField.selectBox.template1.font.color)
        }
      }
    },
    inputCheckBoxDefault: {
      ...theme.custom && {
        background: important(theme.custom.body.inputField.checkbox.template1.default.background),
        borderRadius: important(theme.custom.body.inputField.checkbox.template1.default.borderRadius),
        border: important(theme.custom.body.inputField.checkbox.template1.default.border),
        opacity: important(theme.custom.body.inputField.checkbox.template1.default.opacity),
        ...getFontThemeConfig(theme.custom.body.inputField.checkbox.template1.default.font)
      }
    },
    inputCheckBoxActive: {
      ...theme.custom && {
        background: important(theme.custom.body.inputField.checkbox.template1.active.background),
        borderRadius: important(theme.custom.body.inputField.checkbox.template1.active.borderRadius),
        border: important(theme.custom.body.inputField.checkbox.template1.active.border),
        opacity: important(theme.custom.body.inputField.checkbox.template1.active.opacity),
        ...getFontThemeConfig(theme.custom.body.inputField.checkbox.template1.active.font)
      }
    },
    inputRadio: {
      ...theme.custom && {
        "&.Mui-checked": {
          color: important(theme.custom.body.inputField.radio.template1.active.background),
        }
      },
    },
    inputOtpDigit: {
      "& input": {
        borderBottom: important(`2px solid ${theme.colors.colorPrimary}`)
      },
      "&:focus-within input": {
        boxShadow: important(`inset 0px -6px 8px -9px ${theme.colors.colorPrimary}, 0 6px 6px -7px ${theme.colors.colorPrimary}`),
        "-moz-box-shadow": important(`inset 0px -6px 8px -9px ${theme.colors.colorPrimary}, 0 6px 6px -7px ${theme.colors.colorPrimary}`),
        "-webkit-box-shadow": important(`inset 0px -6px 8px -9px ${theme.colors.colorPrimary}, 0 6px 6px -7px ${theme.colors.colorPrimary}`)
      }
    },
    btnToggle: {
      background: theme.colors.colorPrimaryToggleButtonBackground,
      color: theme.colors.colorPrimaryButtonText,
      borderRadius: "8px",
      border: `1px solid ${theme.colors.colorPrimaryToggleButtonBorder}`,
      fontSize: "14px",
      ...theme.custom && {
        background: important(theme.custom.body.inputField.toogle.template2.default.background),
        borderRadius: important(theme.custom.body.inputField.toogle.template2.default.borderRadius),
        border: important(theme.custom.body.inputField.toogle.template2.default.border),
        opacity: important(theme.custom.body.inputField.toogle.template2.default.opacity),
        ...getFontThemeConfig(theme.custom.body.inputField.toogle.template2.default.font)
      },
      "&.Mui-selected": {
        ...theme.custom && {
          background: important(theme.custom.body.inputField.toogle.template2.active.background),
          borderRadius: important(theme.custom.body.inputField.toogle.template2.active.borderRadius),
          border: important(theme.custom.body.inputField.toogle.template2.active.border),
          opacity: important(theme.custom.body.inputField.toogle.template2.active.opacity),
          ...getFontThemeConfig(theme.custom.body.inputField.toogle.template2.active.font)
        }
      }
    },
    btnIcon: {
      color: important(theme.colors.colorPrimary),
      ...theme.custom && {
        color: important(theme.custom.body.button.template1.background),
      }
    },
    customScrollbar: {
      "&::-webkit-scrollbar-track": {
        boxShadow: `inset 0 0 10px 10px ${theme.colors.colorScrollbarTrackShadow}`,
        borderRadius: "10px",
        ...theme.custom && importantAll(theme.custom.body.scrollBar.template1.webkitScrollbarTrack)
      },
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${theme.colors.colorPrimaryLighter}`,
        borderRadius: "10px",
        ...theme.custom && importantAll(theme.custom.body.scrollBar.template1.webkitScrollbarThumb)
      }
    },
    kycRow: {
      "& .kyc-step-holder": {
        "& .kyc-step": {
          ...theme.custom && importantAll(theme.custom.body.stepper.template1.active)
        },
        "& .kyc-step-line": {
          ...theme.custom && {
            backgroundColor: important(theme.custom.body.stepper.template1.active.background)
          }
        }
      },
      "& .kyc-image-holder": {
        background: theme.colors.colorPrimaryTransparent,
        border: `1px solid ${theme.colors.colorPrimaryLightGrey}`,
        ...theme.custom && {
          background: important(theme.custom.body.inputField.selectBox.template1.background),
          border: important(theme.custom.body.inputField.selectBox.template1.border)
        }
      }
    },
    txnTotalPaymentAmount: {
      ...importantAll({
        fontSize: "21px",
        fontWeight: "bold",
        textTransform: "none",
        color: theme.colors.colorTxnCardPrimary,
      })
      // ...theme.custom && {
      //   color: important(theme.custom.body.card.template2.font.color),
      // }
    },
    txnInstallmentHolder: {
      "& .tracking-holder .tracking-point-holder": {
        "& .tracking-point": {
          border: theme.colors.colorTxnInstallmentTrackingInactiveBg,
          background: theme.colors.colorTxnInstallmentTrackingInactiveBg,
          ...theme.custom && {
            background: important(theme.custom.body.stepper.template1.default.background),
            border: important(theme.custom.body.stepper.template1.default.border),
          }
        },
        "& .tracking-line": {
          backgroundColor: theme.colors.colorTxnInstallmentTrackingInactiveBg,
          ...theme.custom && {
            backgroundColor: important(theme.custom.body.stepper.template1.default.background),
          }
        }
      },
      "& .tracking-holder:first-of-type .tracking-point-holder": {
        "& .tracking-point": {
          border: theme.colors.colorTxnCardPrimary,
          background: theme.colors.colorTxnCardPrimary,
          ...theme.custom && {
            background: important(theme.custom.body.stepper.template1.active.background),
            border: important(theme.custom.body.stepper.template1.active.border),
          }
        }
      }
    },
    txnPaymentProcessingHolder: {
      "& .lds-default div": {
        background: theme.colors.colorPrimary,
        ...theme.custom && {
          background: important(theme.custom.header.font.color),
        }
      }
    },
    txnPaymentSuccessfulHolder: {
      "& .total-payment": {
        border: `1px solid ${theme.colors.colorPrimary}`,
        boxShadow: `0px 0px 16px ${theme.colors.colorPrimaryTransparent}`,
        // ...theme.custom && {
        //   border: important(theme.custom.body.button.template2.border),
        //   boxShadow: important(theme.custom.body.button.template2.border),
        // }
      },
    },
  };
});

export { createDefaultMuiTheme, createDynamicMuiTheme, useThemeStyles };
