import { useContext } from "react";
import { Button, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { StoreContext } from "../../../store/context";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";
import { formatter } from "../../../utils";

import { useThemeStyles } from "../../../styles/themes";
import "./OfflinePurchase.scss";

const DEFAULT_MIN_AMOUNT = 100; // RM1
const DEFAULT_MAX_AMOUNT = 100000000; // RM1mil
const MERCHANT_STORE_DISPLAY_NAME_METADATA_KEY = "outletName";
const Message = {
  REQUIRED: "This is a required field",
  NUMERIC: "Amount must be numeric",
  NUMERIC_MIN: (num) => `Minimum amount is RM ${formatter.format(num)}`,
  NUMERIC_MAX: (num) => `Maximum amount is RM ${formatter.format(num)}`,
};

const OfflinePurchase = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const merchantMinAllowedAmount = (state.transaction_min_amount || DEFAULT_MIN_AMOUNT) / 100;
  const merchantMaxAllowedAmount = (state.transaction_max_amount || DEFAULT_MAX_AMOUNT) / 100;

  const validationSchema = Yup.object().shape({
    transactionAmount: Yup.number().typeError(Message.NUMERIC)
      .min(merchantMinAllowedAmount, Message.NUMERIC_MIN(merchantMinAllowedAmount))
      .max(merchantMaxAllowedAmount, Message.NUMERIC_MAX(merchantMaxAllowedAmount))
      .required(Message.REQUIRED),
    transactionNotes: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      transactionAmount: "0.00",
      transactionNotes: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        amount: values.transactionAmount * 100,
        notes: values.transactionNotes
      };
      actions.initiateOfflineTransaction(data);
    },
  });

  return (
    state.prerequisites
    && (
      <div>
        <Header />
        <div className="page page-offline-purchase">

          <div className={`${classes.content} content hide-scrollbar`}>
            <IconButton className="btn-back" disabled>
              <img alt="back" src="/images/rounded-left-arrow.svg" />
            </IconButton>

            <PayLaterLogoTranslator underBackButton />

            <div className="floor-wrapper">
              <div className="floor-content-holder">
                <div className="floor-content-heading">Purchase Price</div>
                <div className="form-control-holder field-amount">
                  <div className="form-control-flex-wrap">
                    <div className="form-control-prefix-holder">
                      <span className="form-control-prefix">RM</span>
                    </div>
                    <input
                      id="transactionAmount"
                      name="transactionAmount"
                      placeholder="Enter amount"
                      className={clsx("form-input", "offline-field bigger", { "form-error-border": formik.touched.transactionAmount && formik.errors.transactionAmount })}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.transactionAmount}
                    />
                  </div>
                  {formik.touched.transactionAmount && formik.errors.transactionAmount
                    ? (<span className="form-error">{formik.errors.transactionAmount}</span>) : null}
                </div>
              </div>
            </div>

            <div className="box-wrapper">
              <div className="box-content-title">For</div>
              <div className="box-content-holder">
                <div className={clsx("box-content-left", { "no-partner-logo": !state.prerequisites.partner })}>
                  {!state.prerequisites.partner
                    ? (<img className="logo-partner" src="/images/ico_scan_grey.svg" alt="no-partner-logo" />)
                    : (<img className="logo-partner" src={state.prerequisites.partner?.logoUrl} alt="logo-partner" />)}
                </div>

                <div className="box-content-right">
                  <span className="box-content-heading">
                    {state.prerequisites.partner?.name || state.merchant?.name}
                  </span>
                  <br />
                  <span>{state.metadata?.[MERCHANT_STORE_DISPLAY_NAME_METADATA_KEY]}</span>
                </div>
              </div>
            </div>

            <div className="box-wrapper">
              <div className="box-content-title">Notes</div>
              <div className="box-content-holder">
                <div className="form-control-holder field-notes">
                  <img alt="edit" src="/images/ico_edit.svg" />
                  <input
                    id="transactionNotes"
                    name="transactionNotes"
                    placeholder="Add notes"
                    className={clsx("form-input", "offline-field", { "form-error-border": formik.touched.transactionNotes && formik.errors.transactionNotes })}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.transactionNotes}
                  />
                  {formik.touched.transactionNotes && formik.errors.transactionNotes
                    ? (<span className="form-error">{formik.errors.transactionNotes}</span>) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="action-holder">
            <Button color="primary" variant="contained" className={`${classes.btnContained} btn-next`} onClick={formik.handleSubmit}>Continue</Button>
            {/* <p className="confirm-terms">By clicking the button, you have confirmed that you have read, understood and agree to the Terms and Conditions and Privacy Notice.</p> */}
          </div>

        </div>
      </div>
    )
  );
};

export default OfflinePurchase;
