import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, InputBase, MenuItem, Select, Collapse, TextField, IconButton } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import * as Yup from "yup";
import _ from "lodash";
import clsx from "clsx";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./StepHighRisk.scss";

const Message = {
  REQUIRED: "This is a required field"
};

const filterOptions = (options, inputValue) => {
  return _.filter(options, ((option) => option.postcode.startsWith(inputValue)));
};

const StepHighRisk = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [postcodeOptions, setPostcodeOptions] = useState([]);
  const [statesOfCountry, setStatesOfCountry] = useState([]);
  const [postcode, setPostcode] = useState("");
  const [questions, setQuestions] = useState();
  const [highRiskQuestionIds, setHighRiskQuestionIds] = useState();

  // TODO: i18n
  const lang = "en";

  const validationSchema = Yup.object().shape({
    applicationId: Yup.string(),
    mainSourceOfIncomeId: Yup.string().required(Message.REQUIRED),
    employmentCompanyName: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("employmentCompanyName")) ? schema.required(Message.REQUIRED) : schema;
    }),
    employmentCompanyAddressLine1: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("employmentCompanyAddressLine1")) ? schema.required(Message.REQUIRED) : schema;
    }),
    employmentCompanyAddressLine2: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("employmentCompanyAddressLine2")) ? schema.required(Message.REQUIRED) : schema;
    }),
    employmentCompanyAddressLine3: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("employmentCompanyAddressLine3")) ? schema.required(Message.REQUIRED) : schema;
    }),
    employmentCompanyAddressPostcodeObj: Yup.object().nullable().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("employmentCompanyAddressPostcodeId")) ? schema.required(Message.REQUIRED) : schema;
    }),
    employmentCompanyAddressState: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("employmentCompanyAddressState")) ? schema.required(Message.REQUIRED) : schema;
    }),
    typesOfAssetsSoldId: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("typesOfAssetsSoldId")) ? schema.required(Message.REQUIRED) : schema;
    }),
    profitsFromBusinessNature: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("profitsFromBusinessNature")) ? schema.required(Message.REQUIRED) : schema;
    }),
    profitsFromBusinessName: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("profitsFromBusinessName")) ? schema.required(Message.REQUIRED) : schema;
    }),
    incomeFromInvestmentDetails: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("incomeFromInvestmentDetails")) ? schema.required(Message.REQUIRED) : schema;
    }),
    giftOrDonationDonorDetails: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("giftOrDonationDonorDetails")) ? schema.required(Message.REQUIRED) : schema;
    }),
    otherSourceOfIncomeDetails: Yup.string().when("mainSourceOfIncomeId", (mainSourceOfIncomeId, schema) => {
      return (isFieldMandatory("otherSourceOfIncomeDetails")) ? schema.required(Message.REQUIRED) : schema;
    }),
  });

  const formik = useFormik({
    initialValues: {
      applicationId: state.applicationId,
      mainSourceOfIncomeId: "",
      employmentCompanyName: "",
      employmentCompanyAddressLine1: "",
      employmentCompanyAddressLine2: "",
      employmentCompanyAddressLine3: "",
      employmentCompanyAddressPostcodeObj: null,
      employmentCompanyAddressCity: "",
      employmentCompanyAddressState: "",
      typesOfAssetsSoldId: "",
      profitsFromBusinessNature: "",
      profitsFromBusinessName: "",
      incomeFromInvestmentDetails: "",
      giftOrDonationDonorDetails: "",
      otherSourceOfIncomeDetails: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        applicationId: values.applicationId,
        inputs: {
          mainSourceOfIncomeId: values.mainSourceOfIncomeId,
          ...isFieldShow("employmentCompanyName") && {
            employmentCompanyName: values.employmentCompanyName || null,
          },
          ...isFieldShow("employmentCompanyAddressLine1") && {
            employmentCompanyAddress: {
              line1: values.employmentCompanyAddressLine1,
              line2: values.employmentCompanyAddressLine2 || null,
              line3: values.employmentCompanyAddressLine3 || null,
              postCodeId: values.employmentCompanyAddressPostcodeObj.postcodeId,
            }
          },
          ...isFieldShow("typesOfAssetsSoldId") && {
            typesOfAssetsSoldId: values.typesOfAssetsSoldId || null
          },
          ...isFieldShow("profitsFromBusinessNature") && {
            profitsFromBusinessNature: values.profitsFromBusinessNature || null,
            profitsFromBusinessName: values.profitsFromBusinessName || null
          },
          ...isFieldShow("incomeFromInvestmentDetails") && {
            incomeFromInvestmentDetails: values.incomeFromInvestmentDetails || null
          },
          ...isFieldShow("giftOrDonationDonorDetails") && {
            giftOrDonationDonorDetails: values.giftOrDonationDonorDetails || null
          },
          ...isFieldShow("otherSourceOfIncomeDetails") && {
            otherSourceOfIncomeDetails: values.otherSourceOfIncomeDetails || null
          },
        }
      };

      // AnalyticsStep: End step
      analyticsStep.handleStepEnd();

      actions.performHighRiskGrading(data);
    },
  });

  const isFieldMandatory = (fieldName) => {
    return (isFieldShow(fieldName) && questions?.[highRiskQuestionIds?.[fieldName]]?.mandatory === true);
  };

  const isFieldShow = (fieldName) => {
    const fieldDependencies = questions?.[highRiskQuestionIds?.[fieldName]]?.dependencies;
    const dependentField = fieldDependencies ? questions?.[fieldDependencies.questionId]?.keyname : null;
    const dependentValues = fieldDependencies?.answers || []; // array

    if (dependentField && dependentValues.length > 0) {
      return (dependentValues.indexOf(formik.values[dependentField]) > -1);
    }
    return false;
  };

  const handleSourceOfFundsChange = (value) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      mainSourceOfIncomeId: value
    }));
  };

  const handleTypeOfAssetsSoldChange = (value) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      typesOfAssetsSoldId: value
    }));
  };

  const getPostcodeObjByPostcode = (postCodeStr) => {
    if (String(postCodeStr).length == 5) {
      return postcodeOptions.find((option) => option.postcode == postCodeStr);
    }
    return undefined;
  };

  const handlePostcodeStrChange = (postCodeStr) => {
    const postcodeObj = getPostcodeObjByPostcode(postCodeStr);
    if (postcodeObj) {
      handlePostcodeChange(postcodeObj);
    }
  };

  const handlePostcodeChange = (postcodeObj) => {
    if (postcodeObj) {
      const found = statesOfCountry.find((obj) => obj.stateId === postcodeObj.stateId);

      formik.setValues((prevValues) => ({
        ...prevValues,
        employmentCompanyAddressState: found.name,
        employmentCompanyAddressCity: postcodeObj.postOfficeLocation,
        employmentCompanyAddressPostcodeObj: postcodeObj,
      }));
    } else {
      formik.setValues((prevValues) => ({
        ...prevValues,
        employmentCompanyAddressState: "",
        employmentCompanyAddressCity: "",
        employmentCompanyAddressPostcodeObj: null,
      }));
    }
  };

  useEffect(() => {
    actions.getHighRiskQuestionnaire();
    actions.getPostcodes();
  }, []);

  useEffect(() => {
    if (state.highRisk) {
      const tempQuestions = {};
      const tempHighRiskQuestionIds = {};
      state.highRisk.forEach((q) => {
        tempQuestions[q.questionId] = q;
        tempHighRiskQuestionIds[q.keyname] = q.questionId;
      });
      console.log("tempQuestions", tempQuestions);
      setQuestions(tempQuestions);
      setHighRiskQuestionIds(tempHighRiskQuestionIds);
    }
  }, [state.highRisk]);

  useEffect(() => {
    if (state.postcodes) {
      setPostcodeOptions(state.postcodes.postcodes);
      setStatesOfCountry(state.postcodes.states);
    }
  }, [state.postcodes]);

  return (
    state.prerequisites
      && (
        <>
          <Header />
          <div className="page page-high-risk-details">
            <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
              <IconButton className="btn-back">
                <img alt="back" src="/images/rounded-left-arrow.svg" />
              </IconButton>
              {stepText && (
                <div className="stepper-holder">
                  <span className={`${classes.stepText} step-text`}>
                    {stepText}
                  </span>
                  {/* { state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                  <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
                </div>
              )}

              <div>
                <p className={`${classes.title} title`}>Source of Funds</p>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="form-control-holder">
                  <p className="form-title">What is the main source of funds/income?</p>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    id="mainSourceOfIncomeId"
                    name="mainSourceOfIncomeId"
                    displayEmpty
                    className={clsx("form-input form-select-input", classes.inputSelectBox,
                      { placeholder: !formik.values.mainSourceOfIncomeId },
                      { "form-error-border": formik.touched.mainSourceOfIncomeId && formik.errors.mainSourceOfIncomeId })}
                    onChange={(e) => handleSourceOfFundsChange(e.target.value)}
                    value={formik.values.mainSourceOfIncomeId}
                    input={<InputBase />}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {
                      questions?.[highRiskQuestionIds?.mainSourceOfIncomeId]?.answers.map((ans, i) => <MenuItem key={`sourceOfFunds${i}`} value={ans.answerId}>{ans.value[lang]}</MenuItem>)
                    }
                  </Select>
                  {formik.touched.mainSourceOfIncomeId && formik.errors.mainSourceOfIncomeId
                    ? (<span className="form-error">{formik.errors.mainSourceOfIncomeId}</span>) : null}
                </div>

                <div className={`${classes.divider} divider`} />

                <Collapse in={isFieldShow("employmentCompanyName")}>
                  <div className="form-control-holder">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.employmentCompanyName]?.value[lang]}</p>
                    <input
                      id="employmentCompanyName"
                      name="employmentCompanyName"
                      placeholder="ABC Trading Sdn Bhd"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.employmentCompanyName && formik.errors.employmentCompanyName })}
                      value={formik.values.employmentCompanyName}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.employmentCompanyName && formik.errors.employmentCompanyName
                      ? (<span className="form-error">{formik.errors.employmentCompanyName}</span>) : null}
                  </div>
                </Collapse>

                <Collapse in={isFieldShow("employmentCompanyAddressLine1")}>
                  <div className="form-control-holder form-address">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.employmentCompanyAddressLine1]?.value[lang]}</p>
                    <input
                      id="employmentCompanyAddressLine1"
                      name="employmentCompanyAddressLine1"
                      placeholder="Address 1"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.employmentCompanyAddressLine1 && formik.errors.employmentCompanyAddressLine1 })}
                      value={formik.values.employmentCompanyAddressLine1}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.employmentCompanyAddressLine1 && formik.errors.employmentCompanyAddressLine1
                      ? (<span className="form-error">{formik.errors.employmentCompanyAddressLine1}</span>) : null}
                  </div>

                  <div className="form-control-holder form-address">
                    <input
                      id="employmentCompanyAddressLine2"
                      name="employmentCompanyAddressLine2"
                      placeholder="Address 2"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.employmentCompanyAddressLine2 && formik.errors.employmentCompanyAddressLine2 })}
                      value={formik.values.employmentCompanyAddressLine2}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.employmentCompanyAddressLine2 && formik.errors.employmentCompanyAddressLine2
                      ? (<span className="form-error">{formik.errors.employmentCompanyAddressLine2}</span>) : null}
                  </div>

                  <div className="form-control-holder form-address">
                    <input
                      id="employmentCompanyAddressLine3"
                      name="employmentCompanyAddressLine3"
                      placeholder="Address 3"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.employmentCompanyAddressLine3 && formik.errors.employmentCompanyAddressLine3 })}
                      value={formik.values.employmentCompanyAddressLine3}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.employmentCompanyAddressLine3 && formik.errors.employmentCompanyAddressLine3
                      ? (<span className="form-error">{formik.errors.employmentCompanyAddressLine3}</span>) : null}
                  </div>

                  <div className="form-control-holder">
                    <p className="form-title">Postcode</p>
                    <Autocomplete
                      id="employmentCompanyAddressPostcodeObj"
                      name="employmentCompanyAddressPostcodeObj"
                      className={clsx("form-auto-complete", classes.inputSelectBox, { "form-error-border": formik.touched.employmentCompanyAddressPostcodeObj && formik.errors.employmentCompanyAddressPostcodeObj })}
                      value={formik.values.employmentCompanyAddressPostcodeObj}
                      onInputChange={(event, userInputPostcode) => {
                        setPostcode(userInputPostcode);
                        handlePostcodeStrChange(userInputPostcode);
                      }}
                      onChange={(event, userSelectedPostcodeObj) => {
                        handlePostcodeChange(userSelectedPostcodeObj);
                      }}
                      filterOptions={(options, { inputValue }) => filterOptions(options, postcode)}
                      options={postcodeOptions}
                      getOptionLabel={(option) => option.postcode}
                      getOptionSelected={(option) => option.postcodeId}
                      renderInput={(params) => (
                        <TextField
                          className="form-text-field"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            maxLength: 5,
                            placeholder: "Postcode",
                            name: "employmentCompanyAddressPostcodeObj",
                            ...analyticsStep.inputProps
                          }}
                        />
                      )}
                    />
                    {formik.touched.employmentCompanyAddressPostcodeObj && formik.errors.employmentCompanyAddressPostcodeObj
                      ? (<span className="form-error">{formik.errors.employmentCompanyAddressPostcodeObj}</span>) : null}
                  </div>

                  <div className="form-control-holder">
                    <p className="form-title">City</p>
                    <input
                      id="employmentCompanyAddressCity"
                      name="employmentCompanyAddressCity"
                      disabled
                      placeholder="City"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.employmentCompanyAddressCity && formik.errors.employmentCompanyAddressCity })}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.employmentCompanyAddressCity}
                    />
                    {formik.touched.employmentCompanyAddressCity && formik.errors.employmentCompanyAddressCity
                      ? (<span className="form-error">{formik.errors.employmentCompanyAddressCity}</span>) : null}
                  </div>

                  <div className="form-control-holder">
                    <p className="form-title">State</p>
                    <input
                      id="employmentCompanyAddressState"
                      name="employmentCompanyAddressState"
                      disabled
                      placeholder="State"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.employmentCompanyAddressState && formik.errors.employmentCompanyAddressState })}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.employmentCompanyAddressState}
                    />
                    {formik.touched.employmentCompanyAddressState && formik.errors.employmentCompanyAddressState
                      ? (<span className="form-error">{formik.errors.employmentCompanyAddressState}</span>) : null}
                  </div>

                </Collapse>

                <Collapse in={isFieldShow("typesOfAssetsSoldId")}>
                  <div className="form-control-holder">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.typesOfAssetsSoldId]?.value[lang]}</p>
                    <Select
                      IconComponent={KeyboardArrowDownIcon}
                      id="typesOfAssetsSoldId"
                      name="typesOfAssetsSoldId"
                      displayEmpty
                      className={clsx("form-input form-select-input", classes.inputSelectBox,
                        { placeholder: !formik.values.typesOfAssetsSoldId },
                        { "form-error-border": formik.touched.typesOfAssetsSoldId && formik.errors.typesOfAssetsSoldId })}
                      onChange={(e) => handleTypeOfAssetsSoldChange(e.target.value)}
                      value={formik.values.typesOfAssetsSoldId}
                      input={<InputBase />}
                    >
                      <MenuItem value="" disabled>Select One</MenuItem>
                      {
                        questions?.[highRiskQuestionIds?.typesOfAssetsSoldId]?.answers.map((ans, i) => <MenuItem key={`assetType${i}`} value={ans.answerId}>{ans.value[lang]}</MenuItem>)
                      }
                    </Select>
                    {formik.touched.typesOfAssetsSoldId && formik.errors.typesOfAssetsSoldId
                      ? (<span className="form-error">{formik.errors.typesOfAssetsSoldId}</span>) : null}
                  </div>
                </Collapse>

                <Collapse in={isFieldShow("profitsFromBusinessNature")}>
                  <div className="form-control-holder">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.profitsFromBusinessNature]?.value[lang]}</p>
                    <input
                      id="profitsFromBusinessNature"
                      name="profitsFromBusinessNature"
                      placeholder="Nature of business"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.profitsFromBusinessNature && formik.errors.profitsFromBusinessNature })}
                      value={formik.values.profitsFromBusinessNature}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.profitsFromBusinessNature && formik.errors.profitsFromBusinessNature
                      ? (<span className="form-error">{formik.errors.profitsFromBusinessNature}</span>) : null}
                  </div>
                </Collapse>

                <Collapse in={isFieldShow("profitsFromBusinessName")}>
                  <div className="form-control-holder">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.profitsFromBusinessName]?.value[lang]}</p>
                    <input
                      id="profitsFromBusinessName"
                      name="profitsFromBusinessName"
                      placeholder="Name of business"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.profitsFromBusinessName && formik.errors.profitsFromBusinessName })}
                      value={formik.values.profitsFromBusinessName}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.profitsFromBusinessName && formik.errors.profitsFromBusinessName
                      ? (<span className="form-error">{formik.errors.profitsFromBusinessName}</span>) : null}
                  </div>
                </Collapse>

                <Collapse in={isFieldShow("incomeFromInvestmentDetails")}>
                  <div className="form-control-holder">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.incomeFromInvestmentDetails]?.value[lang]}</p>
                    <textarea
                      id="incomeFromInvestmentDetails"
                      name="incomeFromInvestmentDetails"
                      placeholder="Investment details..."
                      className={clsx("form-input", classes.inputTextArea, { "form-error-border": formik.touched.incomeFromInvestmentDetails && formik.errors.incomeFromInvestmentDetails })}
                      value={formik.values.incomeFromInvestmentDetails}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.incomeFromInvestmentDetails && formik.errors.incomeFromInvestmentDetails
                      ? (<span className="form-error">{formik.errors.incomeFromInvestmentDetails}</span>) : null}
                  </div>
                </Collapse>

                <Collapse in={isFieldShow("giftOrDonationDonorDetails")}>
                  <div className="form-control-holder">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.giftOrDonationDonorDetails]?.value[lang]}</p>
                    <textarea
                      id="giftOrDonationDonorDetails"
                      name="giftOrDonationDonorDetails"
                      placeholder="Gift or donation details..."
                      className={clsx("form-input", classes.inputTextArea, { "form-error-border": formik.touched.giftOrDonationDonorDetails && formik.errors.giftOrDonationDonorDetails })}
                      value={formik.values.giftOrDonationDonorDetails}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.giftOrDonationDonorDetails && formik.errors.giftOrDonationDonorDetails
                      ? (<span className="form-error">{formik.errors.giftOrDonationDonorDetails}</span>) : null}
                  </div>
                </Collapse>

                <Collapse in={isFieldShow("otherSourceOfIncomeDetails")}>
                  <div className="form-control-holder">
                    <p className="form-title">{questions?.[highRiskQuestionIds?.otherSourceOfIncomeDetails]?.value[lang]}</p>
                    <textarea
                      id="otherSourceOfIncomeDetails"
                      name="otherSourceOfIncomeDetails"
                      placeholder="Source of income details..."
                      className={clsx("form-input", classes.inputTextArea, { "form-error-border": formik.touched.otherSourceOfIncomeDetails && formik.errors.otherSourceOfIncomeDetails })}
                      value={formik.values.otherSourceOfIncomeDetails}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.otherSourceOfIncomeDetails && formik.errors.otherSourceOfIncomeDetails
                      ? (<span className="form-error">{formik.errors.otherSourceOfIncomeDetails}</span>) : null}
                  </div>
                </Collapse>

                <div className="action-holder">
                  <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" type="submit">
                    Continue
                  </Button>
                </div>
              </form>
            </div>
            <Footer />
          </div>
        </>
      )
  );
};

export default withUbdAnalyticsStep(StepHighRisk, {
  stepId: "StepHighRisk",
  capturingFieldNames: [
    "employmentCompanyName",
    "employmentCompanyAddressLine1",
    "employmentCompanyAddressLine2",
    "employmentCompanyAddressLine3",
    "employmentCompanyAddressPostcodeObj",
    "profitsFromBusinessNature",
    "profitsFromBusinessName",
    "incomeFromInvestmentDetails",
    "giftOrDonationDonorDetails",
    "otherSourceOfIncomeDetails"
  ],
});
