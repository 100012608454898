import axios from "axios";

const API_URL = process.env.REACT_APP_API;
const API_URL_PARTNER_GATEWAY = process.env.REACT_APP_PARTNER_GATEWAY_API;

const api = {
  post(urlParam, payload, headers = {}) {
    return new Promise((resolve, reject) => axios
      .post(`${API_URL}${urlParam}`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      }));
  },
  put(urlParam, payload, headers = {}) {
    return new Promise((resolve, reject) => axios
      .put(`${API_URL}${urlParam}`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      }));
  },
  get(urlParam, headers = {}) {
    return new Promise((resolve, reject) => axios
      .get(`${API_URL}${urlParam}`, {
        withCredentials: true,
        headers: {
          ...headers
        }
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      }));
  },
  postPartner(urlParam, payload, headers = {}) {
    return new Promise((resolve, reject) => axios
      .post(`${API_URL_PARTNER_GATEWAY}${urlParam}`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      }));
  },
  putPartner(urlParam, payload, headers = {}) {
    return new Promise((resolve, reject) => axios
      .put(`${API_URL_PARTNER_GATEWAY}${urlParam}`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      }));
  },
  /**
   * Append query string params to URL. eg: domain.com/path?aaa=1&bbb=2&ccc=3
   * @param {*} url URL of API
   * @param {*} params Object of parameters
   * @returns A new string with appended URL
   */
  injectQueryParamString(url, params = {}) {
    const qs = Object.keys(params).filter((key) => params[key]).map((key) => {
      return `${key}=${params[key]}`;
    });
    return `${url}?${qs.join("&")}`;
  },
};

export default api;
