import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import routes from "../../../router";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./Step0ProductPage.scss";

const Step0ProductPage = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const [homePageConfig, setHomePageConfig] = useState(null);
  const history = useHistory();

  // TODO: i18n
  const lang = "en";

  useEffect(() => {
    // validate missing lToken when prerequisites.skipPassword = true
    if (state.prerequisites?.journeyConfigurations?.global?.skipPassword && !state.lToken && !state.debug) {
      actions.setStep(null);
      actions.showInvalidUrlDialog();
    }

    // Get product info contents from prerequisite API
    if (state.prerequisites?.journeyConfigurations) {
      setHomePageConfig(state.prerequisites?.journeyConfigurations?.homePage);
    }
  }, [state.prerequisites?.journeyConfigurations]);

  const startSignUp = () => {
    if (state.prerequisites) {
      if (state.lToken) {
        actions.getKycToken();
      } else {
        actions.setStep("Step1PersonalDetails");
      }
    }
  };

  const handleBack = () => {
    if (state.pToken) {
      history.push(`${routes.SignIn.path}${location.search}`);
    } else {
      window.location.href = process.env.REACT_APP_BNPL_DASHBOARD_URL;
    }
  };

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-product-page">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>

            <p className={`${classes.headerText} header-text`}>
              {homePageConfig?.header[lang]}
            </p>

            <div>
              {homePageConfig?.hideLogo !== true && <img className="banner" src="/images/paylater-logo-boost.png" alt="banner" />}
              <p className={`${classes.title} title`}>{homePageConfig?.title[lang]}</p>
              <p className={`${classes.subtitle} sub-title`}>{homePageConfig?.body.description[lang]}</p>
            </div>

            <p className="essential">{homePageConfig?.body.essential.title[lang]}</p>
            <div className={`${classes.divider} divider`} />

            {
              homePageConfig?.body.essential.items.length && homePageConfig?.body.essential.items.map((item, i) => {
                return (
                  <div key={`essentialItem${i}`} className="essentialItem-holder">
                    <img className="essentialItem-icon" src={item.iconUrl} alt="icon" />
                    <p className="essentialItem-text" dangerouslySetInnerHTML={{ __html: item.descriptionAsHtml[lang] }} />
                  </div>
                );
              })
            }

            <div className="action-holder">
              {homePageConfig?.body.button.submit.show && (
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={startSignUp}>
                  {homePageConfig?.body.button.submit.displayText[lang]?.toLowerCase()}
                </Button>
              )}
              {homePageConfig?.body.button.return.show && (
                <Button className={`${classes.btnOutlinedSecondary} btn-next btn-second`} variant="outlined" color="secondary" onClick={handleBack}>
                  {homePageConfig?.body.button.return.displayText[lang]?.toLowerCase()}
                </Button>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default Step0ProductPage;
