// rollbar - same configuration you would create for the Rollbar.js SDK
const RollbarConfig = {
  accessToken: "7107669de8e2478ca6aa6ba23ba2261b",
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ENV === "production" ? "production" : "development",
  payload: {
    environment: process.env.REACT_APP_ENV === "production" ? "production" : "development"
  }
};
export default RollbarConfig;
