import { useContext } from "react";
import { Button } from "@material-ui/core";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { formatter } from "../../../utils";

import { useThemeStyles } from "../../../styles/themes";
import "./ScoringSuccess.scss";

const ScoringSuccess = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { analyticsStep } = props;

  const toMandate = () => {
    // AnalyticsStep: End step
    analyticsStep.handleStepEnd();

    actions.setStep("Step8Mandate");
  };

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-scoring-success">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <div className="banner-holder" style={{ backgroundImage: "url(/images/success-banner.svg)" }}>
              <p className="banner-title">Congratulations!</p>
              <p className="banner-desc">You have fulfilled the eligibility requirements.</p>
            </div>

            <div className="financial-amount-holder">
              <p className="financial-title">
                Approved Amount Limit
                <img src="/images/circle-question-grey.svg" alt="icon" className="tip-icon" onClick={() => actions.setData({ isALDialogShow: true })} />
              </p>
              <p className="financial-amount">
                {`RM ${formatter.format(state.eligibilityAmount / 100)}`}
              </p>
            </div>

            <div className="action-holder">
              <Button className={`${classes.btnContained} btn-next btn-big`} variant="contained" color="primary" onClick={toMandate}>
                <p className="btn-title">Accept & Proceed</p>
                <p className="btn-desc">Continue to set up direct debit</p>
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(ScoringSuccess, {
  stepId: "ScoringSuccess",
  capturingFieldNames: [],
});
