import { useContext, useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import OTPInput, { ResendOTP } from "otp-input-react";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./Step2OTP.scss";

const Step2OTP = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [OTP, setOTP] = useState("");

  const resendOTP = () => {
    actions.resendOnboardOTP({ applicationId: state.applicationId });
  };

  const submitOTP = () => {
    const data = {
      applicationId: state.applicationId,
      otp: OTP,
      otpType: "ONBOARDING",
    };

    // AnalyticsStep: End step
    analyticsStep.handleStepEnd();

    actions.validateOnboardOTP(data);
  };

  const otpBtn = (otpProp) => (
    <Button
      onClick={otpProp.onClick}
      disabled={otpProp.disabled}
      color="secondary"
      variant="outlined"
      className={`${classes.btnOutlinedSecondary} btn-next btn-second`}
    >
      {otpProp.remainingTime !== 0
        ? `Please wait ${otpProp.remainingTime} seconds`
        : "Resend OTP"}
    </Button>
  );

  return (
    state.prerequisites
      && (
        <>
          <Header />
          <div className="page page-otp-signup">
            <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
              <IconButton className="btn-back">
                <img alt="back" src="/images/rounded-left-arrow.svg" />
              </IconButton>
              {stepText && (
                <div className="stepper-holder">
                  <span className={`${classes.stepText} step-text`}>
                    {stepText}
                  </span>
                  {/* { state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                  <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
                </div>
              )}

              <div>
                <p className={`${classes.title} title`}>Verify Your Phone Number</p>
                <p className={`${classes.subtitle} small sub-title`}>
                  We have sent a 6-digit OTP verification code to
                  {" "}
                  {" "}
                  <span className="phone">
                    {state.phoneNumber}
                  </span>
                </p>
              </div>

              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus={false}
                OTPLength={6}
                otpType="number"
                className={`${classes.inputOtpDigit} otp-input`}
                disabled={false}
              />
              <p className="six-digit-text">6-Digit OTP Code</p>

              <div className="action-holder">
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" disabled={OTP.length < 6 || state.isLoading} onClick={submitOTP}>
                  Verify
                </Button>
                <ResendOTP
                  className="otp-resend"
                  maxTime={60}
                  renderButton={otpBtn}
                  renderTime={() => null}
                  onResendClick={resendOTP}
                />
              </div>
            </div>
            <Footer />
          </div>
        </>
      )
  );
};

export default withUbdAnalyticsStep(Step2OTP, {
  stepId: "Step2OTP",
  capturingFieldNames: [],
  startJourneyOnStepStart: true,
});
