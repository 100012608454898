import { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { StoreContext } from "../../../store/context";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";
import { addQueryParam, formatter } from "../../../utils";

import { useThemeStyles } from "../../../styles/themes";
import "./SuccessfulPayment.scss";

const REDIRECT_TIMER_SECONDS = 10;

const SuccessfulPayment = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  let [timeRemaining, setTimeRemaining] = useState(REDIRECT_TIMER_SECONDS);
  const [countdownTimer, setCountdownTimer] = useState(0);
  const [autoReturnTimer, setAutoReturnTimer] = useState(0);

  const handleReturn = () => {
    if (state.transaction_api_version && parseInt(state.transaction_api_version) > 1) {
      window.location.href = addQueryParam(state.merchant?.merchant_redirect_url, {
        paymentUuid: state.payment_uuid,
        status: 1
      });
    } else {
      window.location.href = addQueryParam(state.merchant?.merchant_redirect_url, {
        payment_id: state.payment_uuid,
        payment_uuid: state.payment_uuid,
        status: 1
      });
    }
  };

  const initRedirectTimer = (seconds) => {
    if (state.pToken) {
      timeRemaining = seconds - 1;
      setCountdownTimer(setInterval(() => {
        setTimeRemaining(timeRemaining--);
      }, 1000));
      setAutoReturnTimer(setTimeout(() => {
        handleReturn();
      }, seconds * 1000));
    }
    return 0;
  };

  useEffect(() => {
    initRedirectTimer(REDIRECT_TIMER_SECONDS);
    return () => {
      clearInterval(countdownTimer);
      clearTimeout(autoReturnTimer);
    };
  }, []);

  useEffect(() => {
    if (timeRemaining <= 0) {
      clearInterval(countdownTimer);
    }
  }, [timeRemaining]);

  return (
    state.prerequisites
    && (
      <div>
        <Header />
        <div className="page page-successful-payment">
          <div className={`${classes.content} content hide-scrollbar`}>
            <PayLaterLogoTranslator />
            <div className={`${classes.txnPaymentSuccessfulHolder} successful-holder`}>
              <img className="successful-logo" src="/images/successful-icon.svg" alt="logo" />
              <p className={`${classes.subtitle} bold center title`}>Successful Transaction!</p>
              <p className="total-payment">
                <span>
                  Total Payment:
                  {" "}
                </span>
                <span className="total-amount">{`${state.selectedPlan?.installmentAmount?.currency.symbol} ${formatter.format(state.selectedPlan?.installmentAmount?.value / 100)}`}</span>
              </p>
              <div className={`${classes.divider} divider`} />
              <div className="flex-holder">
                <p className="paid-to">
                  <span>
                    Paid To:
                    {" "}
                  </span>
                  <span className="merchant">{state.merchant?.name}</span>
                </p>
                {/* <p className="date">on 10 Apr 2021, 4:53pm</p> */}
              </div>
              {/* <div className="balance-holder">
                  <p className="balance-credit">Balance Boost PayLater Limit</p>
                  <p className="balance-amount">{`RM ${creditLimit ? formatter.format(creditLimit.value / 100) : ""}`}</p>
                </div> */}
              <Button color="primary" variant="contained" className={`${classes.btnContained} btn-next`} onClick={handleReturn}>
                Return to
                {" "}
                {state.merchant?.name}
              </Button>
              {state.pToken && timeRemaining > 0 && (
                <p className="center countdown-text small">
                  {`Auto redirecting in ${timeRemaining}...`}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SuccessfulPayment;
