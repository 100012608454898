import { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import OTPInput, { ResendOTP } from "otp-input-react";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";
import { StoreContext } from "../../../store/context";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./SignInOTP.scss";

const SignInOTP = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { signInViaOTP, signInResendOTP } = actions;
  const [OTP, setOTP] = useState("");

  const resendOTP = () => {
    const params = {
      customerType: state.credential.customerType,
      emailAddress: state.credential.email,
    };
    signInResendOTP(params);
  };

  const submitOTP = () => {
    const params = {
      ...state.credential,
      emailAddress: state.credential.email
    };
    params.otp = OTP;
    signInViaOTP(params);
  };

  const otpBtn = (otpProp) => (
    <Button
      onClick={otpProp.onClick}
      disabled={otpProp.disabled}
      color="secondary"
      variant="outlined"
      className={`${classes.btnOutlinedSecondary} btn-next btn-second`}
    >
      {otpProp.remainingTime !== 0
        ? `Please wait ${otpProp.remainingTime} seconds`
        : "Resend OTP"}
    </Button>
  );

  return (
    state.prerequisites
      && (
        <div>
          <Header />
          <div className="page page-signin-otp">
            <div className={`${classes.content} content hide-scrollbar`}>
              <PayLaterLogoTranslator />
              <p className={`${classes.title} center title`}>Verify Your Phone Number</p>
              <p className={`${classes.subtitle} small center sub-title`}>
                We have sent a 6-digit OTP verification code to
                {" "}
                {" "}
                <span className="phone">
                  {state.customer?.msisdn}
                </span>
              </p>

              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus={false}
                OTPLength={6}
                otpType="number"
                className={`${classes.inputOtpDigit} otp-input`}
                disabled={false}
              />
              <p className="six-digit-text">6-Digit OTP Code</p>

              <div className="action-holder">
                <Button variant="contained" color="primary" className={`${classes.btnContained} btn-next`} type="submit" disabled={OTP.length < 6 || state.isLoading} onClick={submitOTP}>
                  Verify & Accept Contract
                </Button>
                <ResendOTP
                  className="otp-resend"
                  maxTime={60}
                  renderButton={otpBtn}
                  renderTime={() => null}
                  onResendClick={resendOTP}
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )
  );
};

export default SignInOTP;
