import React, { useEffect, useState, useRef } from "react";
import Webcam from "react-webcam";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CameraIcon from "@material-ui/icons/Camera";
import { useRollbar } from "@rollbar/react";

import "./CustomCamera.scss";
// import overlay from "../../../public/images/icImageFrame.png"

const useStyles = makeStyles({
  iconImg: {
    height: "100%",
    width: "100%",
    padding: "10px",
  },
  input: {
    display: "none",
  },
  dialogCamera: {
    lineHeight: "0px"
  },
  paper: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
  cameraButton: {
    background: "white",
    position: "absolute",
    bottom: "5%",
    outline: "none",
    left: "50%",
    zIndex: "2",
    marginLeft: "-20px",
    border: "solid",
  }
});

const classMap = {
  NRIC: "icContainer",
  SELFIE: "selfieContainer"
};

const overlayImage = {
  NRIC: "overlayImage",
  SELFIE: ""
};

// inspired by https://unpkg.com/browse/scandit-sdk@4.6.1/src/lib/cameraAccess.ts
const backCameraKeywords = [
  "rear",
  "back",
  "rück",
  "arrière",
  "trasera",
  "trás",
  "traseira",
  "posteriore",
  "后面",
  "後面",
  "背面",
  "后置", // alternative
  "後置", // alternative
  "背置", // alternative
  "задней",
  "الخلفية",
  "후",
  "arka",
  "achterzijde",
  "หลัง",
  "baksidan",
  "bagside",
  "sau",
  "bak",
  "tylny",
  "takakamera",
  "belakang",
  "אחורית",
  "πίσω",
  "spate",
  "hátsó",
  "zadní",
  "darrere",
  "zadná",
  "задня",
  "stražnja",
  "belakang",
  "बैक"
];

function isBackCameraLabel(label) {
  const lowercaseLabel = label.toLowerCase();
  return backCameraKeywords.some((keyword) => lowercaseLabel.includes(keyword));
}

const Camera = ({ getImage, facingMode, camType, mirrored = false }) => {
  const classes = useStyles();
  const webcamRef = useRef(null);
  const [openModelBox, setModelBox] = useState(true);
  const [starter, setStarter] = useState(0);
  const [cameraDevice, setCameraDeviceId] = useState(null);
  const [hasUserMedia, setUserMedia] = useState(null);
  const rollbar = useRollbar(); // <-- must have parent Provider

  async function requestCameraPermission() {
    // if (!isMiui() && !isVivo()) {

    // Get camera permission before enumerate the devices. Stop the video streaming after getting camera permission
    await getCameraPermission();
    await navigator.mediaDevices.enumerateDevices().then((devices) => {
      const backCameras = [];
      const frontCameras = [];

      devices.forEach((device) => {
        if (device.kind === "videoinput") {
          if (isBackCameraLabel(device.label)) {
            backCameras.push(device);
            // setCameraDeviceId(device.deviceId);
          } else {
            frontCameras.push(device);
            // setCameraDeviceId(device.deviceId);
          }
        }
      });
      let cameraPool = backCameras.length > 0 ? backCameras : frontCameras;
      if (facingMode === "environment" && backCameras.length > 0) {
        cameraPool = backCameras;
      }
      if (facingMode === "user" && frontCameras.length > 0) {
        cameraPool = frontCameras;
      }

      // sort camera pool by label
      cameraPool = cameraPool.sort((camera1, camera2) => camera1.label.localeCompare(camera2.label));

      // Check if cameras are labeled with resolution information, take the higher-resolution one in that case
      // Otherwise pick the first camera
      let selectedCameraIndex = 0;

      const cameraResolutions = cameraPool.map((camera) => {
        const regExp = RegExp(/\b([0-9]+)MP?\b/, "i");
        const match = regExp.exec(camera.label);
        if (match !== null) {
          return parseInt(match[1], 10);
        }
        return NaN;
      });

      if (!cameraResolutions.some((cameraResolution) => Number.isNaN(cameraResolution))) {
        selectedCameraIndex = cameraResolutions.lastIndexOf(Math.max(...cameraResolutions));
      }
      console.log(`Camera component : facingMode - ${facingMode} camType - ${camType} selectedCameraIndex - ${selectedCameraIndex} cameraPool - ${JSON.stringify(cameraPool)} backCameras - ${JSON.stringify(backCameras)} frontCameras - ${JSON.stringify(frontCameras)}`);
      rollbar.info(`Camera component : facingMode - ${facingMode} camType - ${camType} selectedCameraIndex - ${selectedCameraIndex} cameraPool - ${JSON.stringify(cameraPool)} backCameras - ${JSON.stringify(backCameras)} frontCameras - ${JSON.stringify(frontCameras)}`);
      setCameraDeviceId(cameraPool[selectedCameraIndex]?.deviceId);
      setUserMedia(true);
    });
  }

  useEffect(() => {
    if (starter === 0) {
      setStarter(1);
      // let videoConstraint = { video: true, audio: false };
      // videoConstraint.video = { facingMode: facingMode };
      // videoConstraint.video = {facingMode: {exact: facingMode}};
      requestCameraPermission();
    } else if (hasUserMedia != null) {
      // empty
    }
  }, [hasUserMedia]);

  const getCameraPermission = () => navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then((stream) => {
    stream.getTracks().forEach((track) => {
      if (track.readyState === "live" && track.kind === "video") {
        track.stop();
      }
    });
  }).catch(() => {
    console.log("getCameraPermission error");
    alert("Please allow camera permission on browser settings.");
    rollbar.warning("No camera permission on browser settings.");
    setUserMedia(false);
    getImage(null);
  });

  const handleImage = async (e) => {
    e.preventDefault();
    if (hasUserMedia) {
      const screenShot = webcamRef.current.getScreenshot();
      window.stream.getTracks().forEach((track) => track.stop());
      getImage(screenShot);
      setModelBox(false);
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = (ev) => {
        getImage(ev.target.result);
      };
    }
  };

  // if (hasUserMedia === null) {
  //   return ();
  //     // <div>
  //     //   {/* <Skeleton variant="rect" width={250} height={150} />
  //     //   <Skeleton variant="text" width={250} />
  //     //   <Skeleton variant="text" width={250} /> */}
  //     //   <CircularProgress  size="10"/>
  //     // </div>
  // }

  return (
    <>
      {hasUserMedia ? (
        <>
          <Dialog
            className={classes.dialogCamera}
            classes={{ paper: classes.paper }}
            scroll="body"
            open={openModelBox}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

          >
            <Webcam
              mirrored={mirrored}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="100%"
              height="100%"
              style={{ top: "-100px", left: "-200px" }}
              // videoConstraints={{
              //   facingMode: facingMode
              // }}
              onUserMedia={(stream) => {
                window.stream = stream;
              }}
              onUserMediaError={() => {
                setUserMedia(false);
                getImage(null);
              }}
              videoConstraints={{ deviceId: cameraDevice }}
            />
            <div className={`${classMap[camType]}`} />
            {(camType === "NRIC") ? (
              <div className={`${overlayImage[camType]}`} style={{ backgroundImage: "url(/images/icImageFrame.png)" }} />
            ) : (
              <div className={`${overlayImage[camType]}`} />
            )}

            <IconButton className={classes.cameraButton} onClick={handleImage}>
              <CameraIcon />
            </IconButton>
          </Dialog>
        </>
      ) : (
        <>
          {/* <input
            accept="image/*"
            capture="camera"
            className={classes.input}
            id="contained-button-file"
            onChange={handleImage}
            type="file"
          />

          <label htmlFor="contained-button-file">
            <IconButton color="primary" component="span" className="iconBtn">
              <ImageSearchIcon className={classes.iconImg} color="disabled" />
            </IconButton>
          </label> */}
        </>
      )}
    </>
  );
};

// function isMiui() {
//   const ua = navigator.userAgent.toLowerCase();
//   if (ua.match(/MiuiBrowser/i)) return true;
//   return false;
// }

// function isVivo() {
//   const ua = navigator.userAgent.toLowerCase();
//   if (ua.match(/VivoBrowser/i)) return true;
//   return false;
// }

export default Camera;
