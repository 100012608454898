import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { StoreContext } from "../../store/context";

import "./Header.scss";

const Header = () => {
  const { i18n } = useTranslation();
  const { state, actions } = useContext(StoreContext);

  const handleChange = (event, value) => {
    i18n.changeLanguage(value);
    if (value) {
      localStorage.setItem("bnpl-language", value);
    }
  };

  const handleCancelPayment = () => {
    actions.cancelPayment();
  };

  return (
    <header className="header">
      <div className="header-flex-holder left">
        {state.pToken && (<Button className="btn-cancel" onClick={handleCancelPayment} startIcon={<ClearIcon style={{ color: "#414141" }} />}>Cancel</Button>)}
      </div>
      <div className="header-flex-holder middle">
        {/* <img className="logo-lazada" src="/images/lazada-logo2x.png" alt="logo-lazada"></img>
        <span className="power-by">POWERD BY</span> */}
        <img className="logo-aspirasi" src="/images/boost-logo.png" alt="logo-aspirasi" />
      </div>
      <div className="header-flex-holder right">
        {/* <ToggleButtonGroup className="language-toggle-group" size="small" value={i18n.language} exclusive onChange={handleChange}>
          <ToggleButton className="language-toggle en" value="en">
            EN
            </ToggleButton>
          <ToggleButton className="language-toggle" value="ms">
            BM
            </ToggleButton>
        </ToggleButtonGroup> */}
      </div>
    </header>
  );
};

export default Header;
