import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, IconButton, InputBase, MenuItem, Select, Collapse } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import * as Yup from "yup";
import clsx from "clsx";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./Step5EmploymentDetails.scss";

const validationSchema = Yup.object().shape({
  applicationId: Yup.string(),
  employmentStatusObj: Yup.object().nullable().required("This is a required field"),
  totalAssetMoreThanThreeMillion: Yup.lazy((value) => {
    if (value === undefined) {
      return Yup.string().required("This is a required field");
    }
    return Yup.mixed().notRequired();
  }),
  occupationTypeObj: Yup.object().nullable().when("employmentStatusObj", (employmentStatusObj, schema) => {
    if (employmentStatusObj && employmentStatusObj.occupationRequired) {
      return schema.required("This is a required field");
    }
    return schema;
  }),
  industryObj: Yup.object().nullable().when("occupationTypeObj", (occupationTypeObj, schema) => {
    if (occupationTypeObj && occupationTypeObj.industryRequired) {
      return schema.required("This is a required field");
    }
    return schema;
  }),
  monthlyIncomeObj: Yup.object().nullable().when("employmentStatusObj", (employmentStatusObj, schema) => {
    if (employmentStatusObj) {
      return schema.required("This is a required field");
    }
    return schema;
  }),
  maritalStatusObj: Yup.object().nullable().required("This is a required field"),
  noOfDependentsObj: Yup.object().nullable().required("This is a required field"),
});

const isFieldShow = (formik, fieldName) => {
  if (fieldName === "occupationTypeObj") {
    return !!(formik.values.employmentStatusObj?.occupationRequired);
  } if (fieldName === "industryObj") {
    return !!((formik.values.employmentStatusObj?.occupationRequired) && (formik.values.occupationTypeObj?.industryRequired));
  } if (fieldName === "monthlyIncomeObj") {
    if (formik.values.monthlyIncomeObj) {
      return true;
    } if (formik.values.employmentStatusObj?.occupationRequired === false) {
      return true;
    } if (formik.values.employmentStatusObj?.occupationRequired === true && formik.values.occupationTypeObj?.industryRequired === false) {
      return true;
    } if (formik.values.employmentStatusObj?.occupationRequired === true && formik.values.occupationTypeObj?.industryRequired === true && formik.values.industryObj) {
      return true;
    }
    return false;
  } if (fieldName === "noOfDependentsObj") {
    return !!(formik.values.maritalStatusObj);
  }
  return false;
};

const Step5EmploymentDetails = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [isBoostJourney, setIsBoostJourney] = useState(!!state.piiData);

  // TODO: i18n
  const lang = "en";

  const formik = useFormik({
    initialValues: {
      applicationId: state.applicationId,
      employmentStatusObj: "",
      occupationTypeObj: "",
      industryObj: "",
      monthlyIncomeObj: "",
      maritalStatusObj: "",
      noOfDependentsObj: "",
      totalAssetMoreThanThreeMillion: state.prerequisites?.riskProfile.totalAssetMoreThanThreeMillion ? undefined : null,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        applicationId: values.applicationId,
        employmentStatusId: values.employmentStatusObj.employmentStatusId,
        monthlyIncomeId: values.monthlyIncomeObj.monthlyIncomeId,
        maritalStatusId: values.maritalStatusObj.maritalStatusId,
        totalAssetMoreThanThreeMillion: values.totalAssetMoreThanThreeMillion,
        noOfDependentsId: values.noOfDependentsObj.noOfDependentId,
      };

      if (values.employmentStatusObj.occupationRequired) {
        data.occupationTypeId = values.occupationTypeObj.occupationTypeId;
      }

      if (values.occupationTypeObj?.industryRequired) {
        data.industryCategoryId = values.industryObj.industryCategoryId;
      }

      // AnalyticsStep: End step
      analyticsStep.handleStepEnd();

      actions.updateOnboarding(data);
    },
  });

  useEffect(() => {
    // Autofill form from piiData
    var newValues = {}

    // Currently in PII Data for Boost.
    if (state.piiData?.employmentStatusId) {
      newValues["employmentStatusObj"] = state.prerequisites?.riskProfile.employmentStatuses?.find((item) => item.employmentStatusId === state.piiData.employmentStatusId);
    }

    if (state.piiData?.businessCategory?.categoryId) {
      newValues["industryObj"] = state.prerequisites?.riskProfile.industryCategories?.find((item) => item.industryCategoryId === state.piiData.businessCategory.categoryId.toString());
    }

    // Not currently in PII Data, may be applicable for future use.
    if (state.piiData?.monthlyIncomeId) {
      newValues["monthlyIncomeObj"] = state.prerequisites?.riskProfile.monthlyIncomeRanges?.find((item) => item.monthlyIncomeId === state.piiData.monthlyIncomeId);
    }

    if (state.piiData?.maritalStatusId) {
      newValues["maritalStatusObj"] = state.prerequisites?.riskProfile.maritalStatuses?.find((item) => item.maritalStatusId === state.piiData.maritalStatusId);
    }

    if (state.piiData?.noOfDependentId) {
      newValues["noOfDependentObj"] = state.prerequisites?.riskProfile.noOfDependents?.find((item) => item.noOfDependentId === state.piiData.noOfDependentId);
    }

    if (newValues) {
      setIsBoostJourney(true);
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...newValues
      }));
    }
  }, [state.piiData]);

  const handleEmploymentStatusChange = (value) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      employmentStatusObj: value,
      occupationTypeObj: value.occupationRequired ? prevValues.occupationTypeObj : "",
      industryObj: value.occupationRequired ? prevValues.industryObj : "",
    }));
  };

  const handleOccupationChange = (value) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      occupationTypeObj: value,
      industryObj: value.industryRequired ? prevValues.industryObj : "",
    }));
  };

  const handleMaritalStatusChange = (value) => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      maritalStatusObj: value,
      noOfDependentsObj: "",
    }));
  };

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-employment-details">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <IconButton className="btn-back">
              <img alt="back" src="/images/rounded-left-arrow.svg" />
            </IconButton>
            {stepText && (
              <div className="stepper-holder">
                <span className={`${classes.stepText} step-text`}>
                  {stepText}
                </span>
                {/* {state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
              </div>
            )}

            {isBoostJourney ? (
              <div>
                <p className={`${classes.title} title`}>Provide Additional Info</p>
                <p className={`${classes.subtitle} sub-title`}>This will help us in checking your eligibility.</p>
              </div>
            ) : (
              <div>
                <p className={`${classes.title} title`}>Know you better</p>
                <p className={`${classes.subtitle} sub-title`}>Please provide us with your details.</p>
              </div>
            )}

            <form onSubmit={formik.handleSubmit}>
              <div className="form-control-holder">
                <p className="form-title">Employment Status</p>
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  id="employmentStatusObj"
                  name="employmentStatusObj"
                  displayEmpty
                  className={clsx("form-input form-select-input", classes.inputSelectBox,
                    { placeholder: !formik.values.employmentStatusObj },
                    { "form-error-border": formik.touched.employmentStatusObj && formik.errors.employmentStatusObj })}
                  onChange={(e) => handleEmploymentStatusChange(e.target.value)}
                  value={formik.values.employmentStatusObj}
                  input={<InputBase />}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {state.prerequisites?.riskProfile.employmentStatuses?.map((item, i) => <MenuItem key={`employmentStatus${i}`} value={item}>{item.value[lang]}</MenuItem>)}
                </Select>
                {formik.touched.employmentStatusObj && formik.errors.employmentStatusObj
                  ? (<span className="form-error">{formik.errors.employmentStatusObj}</span>) : null}
              </div>
              <Collapse in={isFieldShow(formik, "occupationTypeObj")}>
                <div className="form-control-holder">
                  <p className="form-title">Occupation Type</p>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    id="occupationTypeObj"
                    name="occupationTypeObj"
                    displayEmpty
                    className={clsx("form-input form-select-input", classes.inputSelectBox,
                      { placeholder: !formik.values.occupationTypeObj },
                      { "form-error-border": formik.touched.occupationTypeObj && formik.errors.occupationTypeObj })}
                    onChange={(e) => handleOccupationChange(e.target.value)}
                    value={formik.values.occupationTypeObj}
                    input={<InputBase />}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {state.prerequisites?.riskProfile.occupationTypes?.map((item, i) => <MenuItem key={`occupation${i}`} value={item}>{item.value[lang]}</MenuItem>)}
                  </Select>
                  {formik.touched.occupationTypeObj && formik.errors.occupationTypeObj
                    ? (<span className="form-error">{formik.errors.occupationTypeObj}</span>) : null}
                </div>
              </Collapse>
              <Collapse in={isFieldShow(formik, "industryObj")}>
                <div className="form-control-holder">
                  <p className="form-title">Industry</p>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    id="industryObj"
                    name="industryObj"
                    displayEmpty
                    className={clsx("form-input form-select-input", classes.inputSelectBox,
                      { placeholder: !formik.values.industryObj },
                      { "form-error-border": formik.touched.industryObj && formik.errors.industryObj })}
                    onChange={formik.handleChange}
                    value={formik.values.industryObj}
                    input={<InputBase />}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {state.prerequisites?.riskProfile.industryCategories?.map((item, i) => <MenuItem key={`industry${i}`} value={item}>{item.value[lang]}</MenuItem>)}
                  </Select>
                  {formik.touched.industryObj && formik.errors.industryObj
                    ? (<span className="form-error">{formik.errors.industryObj}</span>) : null}
                </div>
              </Collapse>
              <Collapse in={isFieldShow(formik, "monthlyIncomeObj")}>
                <div className="form-control-holder">
                  <p className="form-title">Monthly Income</p>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    id="monthlyIncomeObj"
                    name="monthlyIncomeObj"
                    displayEmpty
                    className={clsx("form-input form-select-input", classes.inputSelectBox,
                      { placeholder: !formik.values.monthlyIncomeObj },
                      { "form-error-border": formik.touched.monthlyIncomeObj && formik.errors.monthlyIncomeObj })}
                    onChange={formik.handleChange}
                    value={formik.values.monthlyIncomeObj}
                    input={<InputBase />}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {state.prerequisites?.riskProfile.monthlyIncomeRanges?.map((item, i) => <MenuItem key={`monthlyIncome${i}`} value={item}>{item.value[lang]}</MenuItem>)}
                  </Select>
                  {formik.touched.monthlyIncomeObj && formik.errors.monthlyIncomeObj
                    ? (<span className="form-error">{formik.errors.monthlyIncomeObj}</span>) : null}
                </div>
              </Collapse>

              {state.prerequisites?.riskProfile?.totalAssetMoreThanThreeMillion && (
                <>
                  <div className={`${classes.divider} divider`} />

                  <div className="form-control-holder">
                    <p className="form-title">Does all assets you own sum up to RM3 million or above?</p>
                    <div className="form-toggle-group">
                      <ToggleButton
                        className={`${classes.btnToggle} toggle-btn`}
                        value={state.prerequisites?.riskProfile?.totalAssetMoreThanThreeMillion?.[0].totalAssetMoreThanThreeMillionId}
                        selected={formik.values.totalAssetMoreThanThreeMillion === state.prerequisites?.riskProfile?.totalAssetMoreThanThreeMillion?.[0].totalAssetMoreThanThreeMillionId}
                        onChange={() => {
                          formik.setFieldValue("totalAssetMoreThanThreeMillion", state.prerequisites?.riskProfile?.totalAssetMoreThanThreeMillion?.[0].totalAssetMoreThanThreeMillionId);
                        }}
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        className={`${classes.btnToggle} toggle-btn`}
                        value={state.prerequisites?.riskProfile?.totalAssetMoreThanThreeMillion?.[1].totalAssetMoreThanThreeMillionId}
                        selected={formik.values.totalAssetMoreThanThreeMillion === state.prerequisites?.riskProfile?.totalAssetMoreThanThreeMillion?.[1].totalAssetMoreThanThreeMillionId}
                        onChange={() => {
                          formik.setFieldValue("totalAssetMoreThanThreeMillion", state.prerequisites?.riskProfile?.totalAssetMoreThanThreeMillion?.[1].totalAssetMoreThanThreeMillionId);
                        }}
                      >
                        No
                      </ToggleButton>
                    </div>
                    {formik.touched.totalAssetMoreThanThreeMillion && formik.errors.totalAssetMoreThanThreeMillion
                      ? (<span className="form-error" style={{ textAlign: "center" }}>{formik.errors.totalAssetMoreThanThreeMillion}</span>) : null}
                  </div>
                </>
              )}

              <div className={`${classes.divider} divider`} />
              <div className="form-control-holder">
                <p className="form-title">Marital Status</p>
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  id="maritalStatusObj"
                  name="maritalStatusObj"
                  displayEmpty
                  className={clsx("form-input form-select-input", classes.inputSelectBox,
                    { placeholder: !formik.values.maritalStatusObj },
                    { "form-error-border": formik.touched.maritalStatusObj && formik.errors.maritalStatusObj })}
                  onChange={(e) => handleMaritalStatusChange(e.target.value)}
                  value={formik.values.maritalStatusObj}
                  input={<InputBase />}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {state.prerequisites?.riskProfile.maritalStatuses?.map((item, i) => <MenuItem key={`monthlyIncome${i}`} value={item}>{item.value[lang]}</MenuItem>)}
                </Select>
                {formik.touched.maritalStatusObj && formik.errors.maritalStatusObj
                  ? (<span className="form-error">{formik.errors.maritalStatusObj}</span>) : null}
              </div>
              <Collapse in={isFieldShow(formik, "noOfDependentsObj")}>
                <div className="form-control-holder">
                  <p className="form-title">Number of Dependants</p>
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    id="noOfDependentsObj"
                    name="noOfDependentsObj"
                    displayEmpty
                    className={clsx("form-input form-select-input", classes.inputSelectBox,
                      { placeholder: !formik.values.noOfDependentsObj },
                      { "form-error-border": formik.touched.noOfDependentsObj && formik.errors.noOfDependentsObj })}
                    onChange={formik.handleChange}
                    value={formik.values.noOfDependentsObj}
                    input={<InputBase />}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {state.prerequisites?.riskProfile.noOfDependents.map((item, i) => <MenuItem key={`noOfDependents${i}`} value={item}>{item.value[lang]}</MenuItem>)}
                  </Select>
                  {formik.touched.noOfDependentsObj && formik.errors.noOfDependentsObj
                    ? (<span className="form-error">{formik.errors.noOfDependentsObj}</span>) : null}
                </div>
              </Collapse>
              <div className="action-holder">
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" type="submit">
                  Check Eligibility
                </Button>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(Step5EmploymentDetails, {
  stepId: "Step5EmploymentDetails",
  capturingFieldNames: [],
});
