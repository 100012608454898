import { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
// Clevertap
import clevertap from "clevertap-web-sdk";
import clsx from "clsx";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { UbdAnalyticsStoreProvider } from "@aspirasi/react-data-analytics";
import RollbarConfig from "./config/rollbar";
import StoreProvider, { StoreContext } from "./store/context";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Transaction from "./pages/Transaction";
import ErrorPage from "./pages/ErrorPage";
import GeneralModal from "./components/GeneralModal";
import AvailableModal from "./components/AvailableModal";
import PageLoader from "./components/PageLoader";
// import ProtectedRoute from "./router/ProtectedRoute";
import routes from "./router";

import { createDefaultMuiTheme, createDynamicMuiTheme } from "./styles/themes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

// const renderComponents = routes.map((route, index) => (
//   route.isProtected
//   ? <ProtectedRoute
//       path={route.path}
//       exact={route.exact}
//       component={route.component}
//       key={index}
//     />
//   : <Route
//       path={route.path}
//       exact={route.exact}
//       component={route.component}
//       key={index}
//     />
// ));

// Environmental configs
const {
  REACT_APP_CLEVERTAP_ID
} = process.env;

const App = () => {
  const history = useHistory();
  const { state } = useContext(StoreContext);
  const [theme, setTheme] = useState(createDefaultMuiTheme);
  const params = new URLSearchParams(document.location.search.substring(1));

  // useEffect(() => {
  //   // Dynamic API-based Theming
  //   if (state.prerequisites?.themingConfigurations) {
  //     setTheme(createDynamicMuiTheme(state.prerequisites?.themingConfigurations));
  //   }
  // }, [state.prerequisites]);

  useEffect(() => {
    // Switching of page programatically
    switch (state.currentPage) {
      case "error":
        history.push(routes.ErrorPage.path);
        break;
      case "transaction":
        state.currentPage = "";
        history.push(routes.Transaction.path);
        break;
      default:
        break;
    }
  }, [state.currentPage]);

  useEffect(() => {
    clevertap.init(REACT_APP_CLEVERTAP_ID);
    clevertap.spa = true;
    clevertap.setLogLevel(3);
  });

  return (
    <Provider config={RollbarConfig}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <div className={clsx("App", { "embedded": params.get("embedded") === "true" })}>
            <Switch>
              <Route exact path={routes.SignIn.path} component={SignIn} />
              <Route exact path={routes.SignUp.path} component={SignUp} />
              <Route exact path={routes.Transaction.path} component={Transaction} />
              <Route exact path={routes.ErrorPage.path} component={ErrorPage} />
            </Switch>
            <GeneralModal />
            <AvailableModal />
            <PageLoader />
          </div>
        </ThemeProvider>
      </ErrorBoundary>
    </Provider>
  );
};

const ANALYTICS_JOURNEY_CONFIG = {
  name: "ONBOARDING",
  subName: "BNPL_PAYLATER",
  productId: "", // blank, later set by initState()
  subProductId: "", // blank, later set by initState()
};

const ANALYTICS_ACTION_HANDLERS = {
  initState: (analyticsState, analyticsStateDispatch, appState) => {
    switch (appState.currentPage) {
      case "signUp": {
        // Initialise productId & subProductId from state
        analyticsStateDispatch({
          name: "ONBOARDING",
          productId: appState.productId,
          subProductId: appState.subProductId
        });
        break;
      }
      // case "other":
      //   break;
      default:
        break;
    }
  },
  initSessionKey: (analyticsState, analyticsStateDispatch, appState) => {
    switch (appState.currentPage) {
      case "signUp": {
        if (!analyticsState.analytics.sessionKey) {
          // Use application ID from state as sessionKey
          if (appState.applicationId) {
            const sessionKey = `appId_${appState.applicationId}`;
            analyticsStateDispatch({ sessionKey });
          }
        }
        break;
      }
      // case "other":
      //   break;
      default:
        break;
    }
  },
};

const AppWrapper = () => {
  return (
    <StoreProvider>
      <UbdAnalyticsStoreProvider
        journeyConfig={ANALYTICS_JOURNEY_CONFIG}
        handlers={ANALYTICS_ACTION_HANDLERS}
        appStoreContext={StoreContext}
      >
        <Router basename={process.env.PUBLIC_URL || ""}>
          <App />
        </Router>
      </UbdAnalyticsStoreProvider>
    </StoreProvider>
  );
};

export default AppWrapper;
