import { useContext, useState } from "react";
import { Button, IconButton } from "@material-ui/core";
import OTPInput, { ResendOTP } from "otp-input-react";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { APPLICATION_STATUS } from "../../../constants/applicationStatus";

import { useThemeStyles } from "../../../styles/themes";
import "./ConfirmSignUpOTP.scss";

const ConfirmSignUpOTP = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const [OTP, setOTP] = useState("");

  const resendOTP = () => {
    const data = {
      applicationId: state.applicationId,
    };
    actions.resendContractOTP(data);
  };

  const submitOTP = () => {
    const data = {
      applicationId: state.applicationId,
      otp: OTP,
      otpType: APPLICATION_STATUS.SIGN_UP_CONFIRMED
    };
    actions.verifyContractOTP(data);
  };

  const otpBtn = (otpProp) => (
    <Button
      onClick={otpProp.onClick}
      disabled={otpProp.disabled}
      color="secondary"
      variant="outlined"
      className={`${classes.btnOutlinedSecondary} btn-next btn-second`}
    >
      {otpProp.remainingTime !== 0
        ? `Please wait ${otpProp.remainingTime} seconds`
        : "Resend OTP"}
    </Button>
  );

  return (
    state.prerequisites
      && (
        <>
          <Header />
          <div className="page page-confirm-signup-otp">
            <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
              <IconButton className="btn-back">
                <img alt="back" src="/images/rounded-left-arrow.svg" />
              </IconButton>
              <p className={`${classes.title} title`}>Confirm Your Sign-up</p>
              <p className={`${classes.subtitle} small sub-title`}>
                We have sent a 6-digit OTP verification code to
                {" "}
                {" "}
                <span className="phone">
                  {state.phoneNumber}
                </span>
              </p>

              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus={false}
                OTPLength={6}
                otpType="number"
                className={`${classes.inputOtpDigit} otp-input`}
                disabled={false}
              />
              <p className="six-digit-text">6-Digit OTP Code</p>

              <div className="action-holder">
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" disabled={OTP.length < 6 || state.isLoading} onClick={submitOTP}>
                  Confirm
                </Button>
                <ResendOTP
                  className="otp-resend"
                  maxTime={60}
                  renderButton={otpBtn}
                  renderTime={() => null}
                  onResendClick={resendOTP}
                />
              </div>
            </div>
            <Footer />
          </div>
        </>
      )
  );
};

export default ConfirmSignUpOTP;
