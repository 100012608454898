import { useContext, useState } from "react";
import { useFormik } from "formik";
import { Button, IconButton } from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import * as Yup from "yup";
import clsx from "clsx";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import CustomCamera from "../../../components/CustomCamera";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./Step6KYC.scss";

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const validationSchema = Yup.object().shape({
  applicationId: Yup.string(),
  selfie: Yup.string().required("This is a required field"),
  nric: Yup.string().required("This is a required field"),
});

const Step6KYC = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [nricCamera, setNricCamera] = useState(false);
  const [selfieCamera, setSelfieCamera] = useState(false);

  const formik = useFormik({
    initialValues: {
      applicationId: state.applicationId,
      selfie: "",
      nric: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("applicationId", values.applicationId);
      formData.append("selfie", dataURLtoFile(values.selfie));
      formData.append("nric", dataURLtoFile(values.nric));

      // AnalyticsStep: End step
      analyticsStep.handleStepEnd();

      actions.verifyKYC(formData);
    },
  });

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-kyc">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <IconButton className="btn-back">
              <img alt="back" src="/images/rounded-left-arrow.svg" />
            </IconButton>
            {stepText && (
              <div className="stepper-holder">
                <span className={`${classes.stepText} step-text`}>
                  {stepText}
                </span>
                {/* {state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
              </div>
            )}

            <div>
              <p className={`${classes.title} title`}>You’re almost there…</p>
              <p className={`${classes.subtitle} sub-title`}>Let’s get to know you a little better so that we can offer you the most suitable solution option.</p>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="kyc-holder">
                <div className={`kyc-row ${classes.kycRow}`}>
                  <div className="kyc-step-holder">
                    {(formik.values.nric) ? (
                      <img className="kyc-step-complete" src="/images/tick.svg" alt="icon" />
                    ) : (
                      <p className="kyc-step">1</p>
                    )}
                    <span className="kyc-step-line" />
                  </div>
                  <div className="kyc-content-holder">
                    <p className="kyc-title">Please take a photo of your MyKad</p>
                    <p className="kyc-sub-title">Make sure it is clear and in full color.</p>
                    <div className={clsx("kyc-image-holder", { "kyc-image-complete-holder": formik.values.nric })}>
                      {(formik.values.nric) ? (
                        <>
                          <img className="kyc-image" src={formik.values.nric} alt="nric" />
                          <div className="kyc-action-holder" onClick={() => setNricCamera(true)}>
                            <AutorenewIcon className="kyc-action-icon" />
                            <p className="kyc-action-text">RETAKE</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <img className="kyc-placeholder" src="/images/mykad-image.svg" alt="mykad" />
                          <div className="kyc-action-holder" onClick={() => setNricCamera(true)}>
                            <PhotoCameraIcon className="kyc-action-icon" />
                            <p className="kyc-action-text">TAKE PHOTO</p>
                          </div>
                        </>
                      )}
                    </div>
                    {formik.touched.nric && formik.errors.nric
                      ? (<span className="ktc-form-error">{formik.errors.nric}</span>) : null}
                  </div>
                </div>

                <div className={`kyc-row ${classes.kycRow}`}>
                  <div className="kyc-step-holder">
                    {(formik.values.selfie) ? (
                      <img className="kyc-step-complete" src="/images/tick.svg" alt="icon" />
                    ) : (
                      <p className="kyc-step">2</p>
                    )}
                    <span className="kyc-step-line" />
                  </div>
                  <div className="kyc-content-holder">
                    <p className="kyc-title">Please take a photo of yourself (selfie)</p>
                    <p className="kyc-sub-title">Make sure it is clear and in full color.</p>
                    <div className={clsx("kyc-image-holder", { "kyc-image-complete-holder": formik.values.selfie })}>
                      {(formik.values.selfie) ? (
                        <>
                          <img className="kyc-image" src={formik.values.selfie} alt="nric" />
                          <div className="kyc-action-holder" onClick={() => setSelfieCamera(true)}>
                            <AutorenewIcon className="kyc-action-icon" />
                            <p className="kyc-action-text">RETAKE</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <img className="kyc-placeholder" src="/images/selfie-image.svg" alt="selfie" />
                          <div className="kyc-action-holder" onClick={() => setSelfieCamera(true)}>
                            <PhotoCameraIcon className="kyc-action-icon" />
                            <p className="kyc-action-text">TAKE PHOTO</p>
                          </div>
                        </>
                      )}
                    </div>
                    {formik.touched.selfie && formik.errors.selfie
                      ? (<span className="ktc-form-error">{formik.errors.selfie}</span>) : null}
                  </div>
                </div>

                <div className={`kyc-row ${classes.kycRow}`}>
                  <div className="kyc-step-holder">
                    <p className="kyc-step">3</p>
                    <span className="kyc-step-line" />
                  </div>
                  <div className="kyc-content-holder">
                    <Button disabled={!(formik.values.nric && formik.values.selfie)} className={`${classes.btnContained} btn-next`} variant="contained" color="primary" type="submit">
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </div>
        {(nricCamera) && (
          <CustomCamera
            facingMode="environment"
            camType="NRIC"
            getImage={async (img) => {
              if (img) {
                formik.setFieldValue("nric", img);
              }
              setNricCamera(false);
            }}
          />
        )}
        {(selfieCamera) && (
          <CustomCamera
            mirrored
            facingMode="user"
            camType="SELFIE"
            getImage={async (img) => {
              if (img) {
                formik.setFieldValue("selfie", img);
              }
              setSelfieCamera(false);
            }}
          />
        )}
      </>
    )
  );
};

export default withUbdAnalyticsStep(Step6KYC, {
  stepId: "Step6KYC",
  capturingFieldNames: [],
});
