/* Note: Please use colors from colors.scss */

// Base Color Palette
const BaseColorPalette = {
  colorWhite: "#ffffff",
  colorBlack: "#000000",
  colorPrimaryLighter2: "#f5f5fa", // "#e5f9ff",
  colorPrimaryLighter: "#ff8888", // aspirasi: "#b5ecfc",
  colorPrimaryLight: "#f8a6b4", // aspirasi: "#61caed",
  colorPrimary: "#ea0029", // aspirasi: "#05a0d3",
  colorPrimaryDark: "#bd0a0a", // aspirasi: "#005b8b",
  colorPrimaryDarker: "#124a67", // aspirasi: "#124a67",
  colorPrimaryLightGrey: "#8a8a8c", // aspirasi: "#9eb0b9",
  colorPrimaryGrey: "#58595b", // aspirasi: "#617e8d",
  colorButtonDisabledBg: "rgba(0, 0, 0, 0.12)",
  colorTextPrimary: "#231f20", // aspirasi: "#333333",
  colorTextSecondary: "#8a8a8c", // aspirasi: "#717171",
  colorSecondaryLight: "#8a8a8c",
  colorSecondary: "#58595b",
  colorSecondaryDark: "#231f20",
};

// Derived Color Palette
const DerivedColorPalette = {
  colorPrimaryTransparent: "#05a0d329", // aspirasi: "#05a0d329", // rgba() will not work here
  colorPrimaryText: BaseColorPalette.colorSecondaryDark,
  colorPrimaryButtonText: BaseColorPalette.colorSecondaryDark,
  colorPrimaryActionIcon: BaseColorPalette.colorPrimaryDarker,
  colorPrimaryScoringSpinner: BaseColorPalette.colorPrimary,
  colorPrimaryToggleButtonBackground: BaseColorPalette.colorPrimaryLighter2,
  colorPrimaryToggleButtonBorder: BaseColorPalette.colorPrimaryLighter,
  colorFooterBackground: "#efeff6",
  colorFooterText: "#959595",
  colorDivider: "#d5e3eb",
  colorTxnCardPrimary: BaseColorPalette.colorPrimary,
  colorTxnCardSecondary: BaseColorPalette.colorPrimaryDarker,
  colorTxnInstallmentTrackingInactiveBg: "#ceced5",
  colorBoxShadow: "#00000029",
  colorHeaderPartnerPowerByText: BaseColorPalette.colorSecondaryLight,
  colorFormInputErrorBorder: "#d80012",
  colorFormInputErrorBoxShadow: "#d80012",
  colorFormInputErrorBackgroundColor: "#fff2f3",
};

export { BaseColorPalette, DerivedColorPalette };
