// import PrerequisiteDataMock from "./prerequisiteMock";
// import HighRiskDataMock from "./highRiskMock";

const initialState = {
  isLoading: false,
  isBtnLoading: false,
  isProcessing: false,
  currentPage: "signIn",
  errorPageError: null,
  applicationConfirmed: false,
  step: null,
  merchantId: "",
  subMerchantId: "",
  clientId: "",
  sessionToken: "",
  kycToken: "",
  pToken: "",
  prerequisites: null, // PrerequisiteDataMock
  piiData: null,
  applicationId: "",
  customerId: "",
  dashboardUrl: "",
  lToken: "",
  embedded: null,
  banks: null,
  highRisk: null, // HighRiskDataMock,
  enableHighRiskQuestionnaire: true,
  skipInitiationAgreement: null,
  stepDefinition: null,
  dialog: {
    dialogTitle: "",
    dialogContent: "",
    trimQuote: true,
  },
  isDialogShow: false,
  isALDialogShow: false,
  eligibilityAmount: null,
  applicationStatus: "",
  requireConsentFlag: false,
};

export default initialState;
