import { useContext } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./ScoringLoading.scss";

const ScoringLoading = () => {
  const classes = useThemeStyles();
  const { state } = useContext(StoreContext);

  return (
    state.prerequisites
      && (
        <>
          <Header />
          <div className="page page-scoring-loading">
            <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
              <CircularProgress className="respond-loader" size={32} />
              <p className={`${classes.title} center regular title`}>Please bear with us</p>
              {/* <p className="desc">We are currently processing your application.</p> */}
              <p className="desc">This should take just a short while.</p>
              {/* <p className="validating">Validating</p> */}
            </div>
            <Footer />
          </div>
        </>
      )
  );
};

export default ScoringLoading;
