import { useContext, useEffect, useState } from "react";
import { Button, IconButton, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomCheckboxTerms from "../../../components/CustomCheckboxTerms";
import { ValidationUtil } from "../../../utils/validation";

import { useThemeStyles } from "../../../styles/themes";
import "./Step1PersonalDetails.scss";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("This is a required field.").matches(ValidationUtil.REGEX_VALID_FULLNAME, "Invalid Full Name"),
  emailAddress: Yup.string().email("Invalid email address").required("This is a required field."),
  nric: Yup.string().required("This is a required field.")
    .matches(ValidationUtil.REGEX_VALID_NRIC, "NRIC format requires 000000-00-0000")
    .test("validate-nric", "Invalid NRIC", ValidationUtil.nricDobValidator)
    .test("validate-nric", "Your age must be between 21 and 60", ValidationUtil.ageValidator),
  msisdn: Yup.string().required("This is a required field.").matches(ValidationUtil.REGEX_VALID_MSISDN, "Invalid Phone Number"),
});

const Step1PersonalDetails = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [isBoostJourney, setIsBoostJourney] = useState(!!state.piiData);

  useEffect(() => {
    // validate missing lToken when prerequisites.skipPassword = true
    if (state.prerequisites?.journeyConfigurations?.global?.skipPassword && !state.lToken && state.prerequisites?.journeyConfigurations?.global?.skipProductPage && !state.debug) {
      actions.setStep(null);
      actions.showInvalidUrlDialog();
    }
  }, [state.prerequisites?.journeyConfigurations]);

  useEffect(() => {
    if (state.prerequisites && state.lToken && !state.kycToken && state.prerequisites?.journeyConfigurations?.global?.skipProductPage) {
      actions.getKycToken();
      actions.getPostcodes();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      nric: "",
      emailAddress: "",
      msisdn: "",
      acknowledgementAcceptance: false,
    },
    validationSchema: !isBoostJourney ? validationSchema : undefined,
    onSubmit: (values) => {
      // TODO: Below info hardcode or get from client browser?
      const data = {
        ...values,
        customerType: "ENDUSER",
        ipAddress: "123.1.5",
        device: {
          type: "DESKTOP",
          subtype: "WINDOWS"
        }
      };
      // delete data.acknowledgementAcceptance;
      actions.setData({ phoneNumber: `+60 ${values.msisdn}` });

      // AnalyticsStep: End step
      analyticsStep.handleStepEnd();

      if (state.lToken) {
        actions.setStep("Step2OTP");
      } else {
        actions.initialOnboarding(data);
      }
    },
  });

  useEffect(() => {
    // Autofill form from piiData
    if (state.piiData) {
      setIsBoostJourney(true);
      const address = [];
      let city = "";
      let stateName = "";
      let postcode = "";

      if (state.piiData.homeAddress?.addressLine1) { address.push(state.piiData.homeAddress.addressLine1); }
      if (state.piiData.homeAddress?.addressLine2) { address.push(state.piiData.homeAddress.addressLine2); }
      if (state.piiData.homeAddress?.addressLine3) { address.push(state.piiData.homeAddress.addressLine3); }
      if (state.piiData.homeAddress?.city) { city = state.piiData.homeAddress.city; }
      if (state.piiData.homeAddress?.postcode) { postcode = state.piiData.homeAddress.postcode; }
      if (state.piiData.homeAddress?.state) { stateName = state.piiData.homeAddress.state; }

      if (state.postcodes && postcode) {
        const postcodeObj = state.postcodes.postcodes.find((option) => option.postcode == postcode);
        console.log(`Postcodes ${postcode}`);

        if (postcodeObj) {
          const found = state.postcodes.states.find((obj) => obj.stateId === postcodeObj.stateId);

          stateName = found.name;
          city = postcodeObj.postOfficeLocation;
          postcode = postcodeObj.postcode;
          console.log("Found");
        } else {
          city = "";
          stateName = "";
          postcode = "";
          console.log("Not Found");
        }
      } else {
        console.log("No Postcodes");
      }

      if (city) { address.push(city); }
      if (stateName) { address.push(stateName); }
      if (postcode) { address.push(postcode); }

      formik.setValues((prevValues) => ({
        ...prevValues,
        fullName: state.piiData.fullName,
        nric: state.piiData.nric,
        emailAddress: state.piiData.email,
        msisdn: state.piiData.msisdn.replace(/^(60)/, ""),
        address: address.join(", ")
      }));
    }
  }, [state.piiData, state.postcodes]);

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-personal-details">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <IconButton className="btn-back">
              <img alt="back" src="/images/rounded-left-arrow.svg" />
            </IconButton>
            {stepText && (
              <div className="stepper-holder">
                <span className={`${classes.stepText} step-text`}>
                  {stepText}
                </span>
                {/* { state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
              </div>
            )}

            {isBoostJourney ? (
              <div>
                <p className={`${classes.title} title`}>Confirm Your Details</p>
                <p className={`${classes.subtitle} sub-title`}>Please verify the information below.</p>
                <div className={`${classes.divider} divider`} />
              </div>
            ) : (
              <div>
                <p className={`${classes.title} title`}>Let’s Begin!</p>
                <p className={`${classes.subtitle} sub-title`}>Fill in your basic credentials to get started.</p>
              </div>
            )}

            <form onSubmit={formik.handleSubmit}>
              <div className="form-control-holder">
                <p className="form-title">Name As Per in MyKad</p>
                {isBoostJourney ? (
                  <p className={`${classes.inputAutoFilled} bold`}>{formik.values.fullName}</p>
                ) : (
                  <>
                    <input
                      id="fullName"
                      name="fullName"
                      placeholder="Please fill in your name as per MyKad"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.fullName && formik.errors.fullName })}
                      value={formik.values.fullName}
                      disabled={isBoostJourney}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.fullName && formik.errors.fullName
                      ? (<span className="form-error">{formik.errors.fullName}</span>) : null}
                  </>
                )}
              </div>
              <div className="form-control-holder">
                <p className="form-title">MyKad Number</p>
                {isBoostJourney ? (
                  <p className={`${classes.inputAutoFilled} bold`}>{formik.values.nric}</p>
                ) : (
                  <>
                    <input
                      id="nric"
                      name="nric"
                      placeholder="900256-10-5678"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.nric && formik.errors.nric })}
                      value={formik.values.nric}
                      disabled={isBoostJourney}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.nric && formik.errors.nric
                      ? (<span className="form-error">{formik.errors.nric}</span>) : null}
                  </>
                )}
              </div>
              <div className="form-control-holder">
                <p className="form-title">Email Address</p>
                {isBoostJourney ? (
                  <p className={`${classes.inputAutoFilled} bold`}>{formik.values.emailAddress}</p>
                ) : (
                  <>
                    <input
                      id="emailAddress"
                      name="emailAddress"
                      placeholder="username@email.com"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.emailAddress && formik.errors.emailAddress })}
                      value={formik.values.emailAddress}
                      disabled={isBoostJourney}
                      onChange={formik.handleChange}
                      {...analyticsStep.inputProps}
                      // onBlur={formik.handleBlur}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        analyticsStep.inputProps.onBlur(e);
                      }}
                    />
                    {formik.touched.emailAddress && formik.errors.emailAddress
                      ? (<span className="form-error">{formik.errors.emailAddress}</span>) : null}
                  </>
                )}

              </div>
              <div className="form-control-holder">
                <p className="form-title">Mobile Phone Number</p>
                {isBoostJourney ? (
                  <p className={`${classes.inputAutoFilled} bold`}>
                    +60
                    {" "}
                    {formik.values.msisdn}
                  </p>
                ) : (
                  <>
                    <div className="form-flex-holder">
                      <span className="phone-prefix">+60</span>
                      <input
                        id="msisdn"
                        name="msisdn"
                        placeholder="12345678"
                        className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.msisdn && formik.errors.msisdn })}
                        value={formik.values.msisdn}
                        disabled={isBoostJourney}
                        onChange={formik.handleChange}
                        {...analyticsStep.inputProps}
                        // onBlur={formik.handleBlur}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          analyticsStep.inputProps.onBlur(e);
                        }}
                      />
                    </div>
                    {formik.touched.msisdn && formik.errors.msisdn
                      ? (<span className="form-error">{formik.errors.msisdn}</span>) : null}
                  </>
                )}
              </div>
              {(isBoostJourney && state.prerequisites?.journeyConfigurations?.global?.skipAddress) ? (
                <div className="form-control-holder">
                  <p className="form-title">Address</p>
                  <p className={`${classes.inputAutoFilled} bold`}>
                    {formik.values.address}
                  </p>
                </div>
              ) : <></>}

              {isBoostJourney && (
                <div>
                  <div className={`${classes.divider} divider`} />

                  <p className="note">An account will be created for you based on your verified information with Boost.</p>
                </div>
              )}

              <FormControlLabel
                className={clsx("terms-holder", { "terms-checked": formik.values.acknowledgementAcceptance }, (formik.values.acknowledgementAcceptance ? classes.inputCheckBoxActive : classes.inputCheckBoxDefault))}
                control={<CustomCheckboxTerms newStyle color="primary" className="terms-cb" name="acknowledgementAcceptance" checked={formik.values.acknowledgementAcceptance} onChange={formik.handleChange} />}
                label={(
                  <p className="terms">
                    I confirm that I have read, understood and agree to the
                    {" "}
                    {" "}
                    <a target="_blank" rel="noopener noreferrer" href="https://aspirasi-assets.s3.ap-southeast-1.amazonaws.com/boost-credit/Boost+Credit+-+T%26C+for+Boost+PayLater+(Syariah)+07032022.pdf">Terms & Conditions</a>
                    {" "}
                    and
                    {" "}
                    <a target="_blank" rel="noopener noreferrer" href={state.prerequisites?.merchant?.privacyNoticeUrl}>Privacy Notice</a>
                    .
                  </p>
                )}
              />

              <div className="action-holder">
                <Button disabled={!formik.values.acknowledgementAcceptance} className={`${classes.btnContained} btn-next`} variant="contained" color="primary" type="submit">
                  Confirm
                </Button>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(Step1PersonalDetails, {
  stepId: "Step1PersonalDetails",
  capturingFieldNames: ["fullName", "nric", "emailAddress", "msisdn"],
  startJourneyOnStepStart: true,
  alwaysResetUserBehaviour: false,
});
