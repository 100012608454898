import Step0ProductPage from "./Step0ProductPage";
import Step1PersonalDetails from "./Step1PersonalDetails";
import Step2OTP from "./Step2OTP";
import Step3Password from "./Step3Password";
import Step4Address from "./Step4Address";
import Step5EmploymentDetails from "./Step5EmploymentDetails";
import Step6KYC from "./Step6KYC";
import Step7ShareLocation from "./Step7ShareLocation";
import Step8Mandate from "./Step8Mandate";
import StepHighRisk from "./StepHighRisk";
import ConfirmSignUpOTP from "./ConfirmSignUpOTP";
import ApplicationSubmitted from "./ApplicationSubmitted";
import ConsentConfirmed from "./ConsentConfirmed";
import ScoringFailure from "./ScoringFailure";
import ScoringLoading from "./ScoringLoading";
import ScoringSuccess from "./ScoringSuccess";
import SignUpStepDefinition, { calcStepsAndAlias } from "./SignUpStepDefinition";

const SignUpStepComponentMap = {
  "Step0ProductPage": {
    component: <Step0ProductPage /> // XD - 01 Product Page
  },
  "Step1PersonalDetails": {
    component: <Step1PersonalDetails /> // XD - 02 Info
  },
  "Step2OTP": {
    component: <Step2OTP />
  },
  "Step3Password": {
    component: <Step3Password />
  },
  "Step4Address": {
    component: <Step4Address /> // XD - 03 Address
  },
  "Step5EmploymentDetails": {
    component: <Step5EmploymentDetails /> // XD - 04a~g Know
  },
  "Step6KYC": {
    component: <Step6KYC />
  },
  "Step7ShareLocation": {
    component: <Step7ShareLocation />
  },
  "ScoringLoading": {
    component: <ScoringLoading /> // XD - Process 01~07
  },
  "ScoringSuccess": {
    component: <ScoringSuccess /> // XD - 06 Congrats
  },
  "ScoringFailure": {
    component: <ScoringFailure /> // XD - 07 Failed
  },
  "StepHighRisk": {
    component: <StepHighRisk /> // XD - High Risk 01~07
  },
  "Step8Mandate": {
    component: <Step8Mandate /> // XD - 08 Payment
  },
  "ConfirmSignUpOTP": {
    component: <ConfirmSignUpOTP />
  },
  "ApplicationSubmitted": {
    component: <ApplicationSubmitted /> // XD - 12 Submitted
  },
  "ConsentConfirmed": {
    component: <ConsentConfirmed /> // New 2024 Feb
  }
};

const SignUpStepDecider = (step, prerequisites) => {
  if (step && SignUpStepComponentMap[step]) {
    const stepObj = SignUpStepComponentMap[step];

    /**
     * @type JSX.Element
     */
    const StepRawComponent = stepObj.component;
    const StepComponentType = StepRawComponent.type;

    // adapt stepper number based on journeySkippingConfig
    let stepDefinition = JSON.parse(JSON.stringify(SignUpStepDefinition));
    if (prerequisites && prerequisites.journeyConfigurations?.global) {
      const skipConfig = prerequisites.journeyConfigurations?.global;
      const skipConfigMappingToStep = {
        "skipVerifyMsisdnOtp": "Step2OTP",
        "skipPassword": "Step3Password",
        "skipAddress": "Step4Address",
        "skipEmploymentDetails": "Step5EmploymentDetails",
        "skipKyc": "Step6KYC",
        "skipShareLocation": "Step7ShareLocation",
      };
      Object.entries(skipConfigMappingToStep).forEach(([key, value]) => {
        if (skipConfig[key]) {
          stepDefinition.steps = stepDefinition.steps.filter((stepName) => stepName != value);
        }
      });
      stepDefinition = calcStepsAndAlias(stepDefinition);
    }
    console.log("stepDefinition", stepDefinition);

    // resolve alias step
    const usedStepName = stepDefinition.alias[step] || step;

    // derive step counts
    const currentStep = stepDefinition.steps.indexOf(usedStepName);
    const { totalSteps } = stepDefinition;

    // prepare child props
    const childProps = {
      currentStep,
      totalSteps,
      stepText: currentStep > 0 ? `STEP ${currentStep} OF ${totalSteps}` : ""
    };

    // inject props into component and return
    return {
      stepDefinition,
      jsx: <StepComponentType {...StepRawComponent.props} {...childProps} />
    };
  }
  return {
    stepDefinition: null,
    jsx: null
  };
};

export default SignUpStepDecider;
