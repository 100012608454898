import { useContext, useEffect } from "react";
import { Button } from "@material-ui/core";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./ConsentConfirmed.scss";

const ConsentConfirmed = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);

  // TODO: i18n
  const lang = "en";

  const makePayment = () => {
    if (state.pToken) {
      actions.startTransaction();
    }
  };

  const redirectDashboard = () => {
    if (state.dashboardUrl) {
      window.location.href = state.dashboardUrl;
    } else {
      window.location.href = process.env.REACT_APP_BNPL_DASHBOARD_URL;
    }
  };

  // useEffect(() => {
  //   if (state.embedded) {
  //   // Fetch dashboard URL
  //     actions.getDashboardUrl();
  //   }
  // }, []);

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-consent-confirmed ">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <img
              className="success-image"
              src="/images/success-tick.png"
              alt="success"
            />
            <p className="congrats-title">
              {state.prerequisites?.journeyConfigurations?.completedApplicationPage?.title?.[lang]}
              !
            </p>
            <p
              className="congrats-sub-title"
              dangerouslySetInnerHTML={{
                __html: state.prerequisites?.journeyConfigurations?.completedApplicationPage?.messageBody?.[lang]
              }}
            />

            <div className="action-holder">
              {state.pToken && (
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={makePayment}>
                  Make Payment
                </Button>
              )}
              {!state.pToken && !state.embedded && (
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={redirectDashboard}>
                  Go to PayFlex Dashboard
                </Button>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(ConsentConfirmed, {
  stepId: "ConsentConfirmed",
  endJourneyOnStepStart: true,
});
