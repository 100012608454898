import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_en from "./en/common.json";
import common_ms from "./ms/common.json";

const translations = {
  "en": {
    translation: common_en,
  },
  "ms": {
    translation: common_ms,
  },
};

const defaultLanguage = () => {
  if (!localStorage.getItem("bnpl-language")) {
    localStorage.setItem("bnpl-language", "en");
  }
  return localStorage.getItem("bnpl-language");
};

i18n.use(initReactI18next).init({
  lng: defaultLanguage(),
  resources: translations,
  fallbackLng: defaultLanguage(),
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
