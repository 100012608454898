import { useContext, useState } from "react";
import { Button, Radio, RadioGroup, FormControlLabel, Collapse, IconButton } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import _ from "lodash";
import { StoreContext } from "../../../store/context";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";
import { formatter } from "../../../utils";

import { useThemeStyles } from "../../../styles/themes";
import "./PaymentPlan.scss";

const PaymentPlan = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { paymentPlan } = state.transaction;
  const defaultPlanIndex = _.findIndex(paymentPlan, { isDefault: true });
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(defaultPlanIndex);

  // TODO: i18n
  const lang = "en";

  const handleChange = (event) => {
    setSelectedPlanIndex(parseInt(event.target.value));
  };

  const handleSubmit = () => {
    const data = {
      principal: paymentPlan[selectedPlanIndex].installmentAmount.value,
      timeUnit: paymentPlan[selectedPlanIndex].timeunit,
      tenure: `${paymentPlan[selectedPlanIndex].tenure}`,
    };

    actions.setData({ selectedPlan: paymentPlan[selectedPlanIndex] });
    actions.getSupplementary(data);
  };

  const Plan = ({ plan, planLength, index }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = (event) => {
      // event.stopPropagation();
      event.preventDefault();
      setOpen(!open);
    };
    return (
      <div className="form-control-holder" key={`plan${index}`}>
        <FormControlLabel
          className="form-control-label"
          value={index}
          control={<Radio size="small" color="primary" className={`${classes.inputRadio} radio-button`} />}
          label={(
            <div className="form-control-label-content">
              <p className="description">
                {`${plan.installmentSchedule[0].amount.currency.symbol} ${formatter.format(plan.installmentSchedule[0].amount.value / 100)} x ${plan.tenure} instalments`}
              </p>
              <IconButton className={`${classes.btnIcon} icon-button`} color="primary" onClick={handleToggle}>
                <NavigateNextIcon className={`arrow ${open ? "dropdownOpen" : "dropdownClosed"}`} />
              </IconButton>
            </div>
          )}
        />
        <Collapse in={open}>
          <div className={`${classes.txnInstallmentHolder} installment-holder`}>
            {plan.installmentSchedule.map((installment, j) => (
              <div className="tracking-holder" key={`installment${j}`}>
                <div className="tracking-point-holder">
                  <span className="tracking-point" />
                  <span className="tracking-line" />
                </div>
                <div className="tracking-content">
                  <p className="installment-payment">{`${installment.amount.currency.symbol} ${formatter.format(installment.amount.value / 100)}`}</p>
                  <p className="installment-no">{`${installment.dueDate}`}</p>
                </div>
              </div>
            ))}
          </div>
        </Collapse>
        {(index + 1) !== planLength && (
          <div className={`${classes.divider} divider`} />
        )}
      </div>
    );
  };

  return (
    state.prerequisites
    && (
      <div>
        <Header />
        <div className="page page-payment-plan">
          <div className={`${classes.content} content hide-scrollbar`}>
            <PayLaterLogoTranslator />
            <div className="order-info-holder">
              <div className="col-left">
                <p className="total-payment">Total Payment</p>
                <p className={`${classes.txnTotalPaymentAmount} total-payment-value`}>{selectedPlanIndex >= 0 ? (`${paymentPlan[selectedPlanIndex].installmentAmount.currency.symbol} ${formatter.format(paymentPlan[selectedPlanIndex].installmentAmount.value / 100)}`) : ""}</p>
                <p className="merchant">{state.merchant?.name}</p>
                <p className="merchant-id">
                  ID:
                  {" "}
                  {state.transaction?.orderId}
                </p>
              </div>
              <div className={`${classes.verticalDivider} vertical-divider`} />
              <div className="col-right">
                <p className="available-credit">
                  Available Limit
                  <img src="/images/circle-question-grey.svg" alt="icon" className="tip-icon" onClick={() => actions.setData({ isALDialogShow: true })} />
                </p>
                <p className="available-credit-value">{selectedPlanIndex >= 0 ? (`${paymentPlan[selectedPlanIndex].availableLimit.currency.symbol} ${formatter.format(paymentPlan[selectedPlanIndex].availableLimit.value / 100)}`) : ""}</p>
              </div>
            </div>
            <div className="select-plan-holder">
              <p className="select-plan-text">Select Payment Schedule</p>
              <RadioGroup aria-label="plan" name="plan" className="form-radio-group-holder" value={selectedPlanIndex} onChange={handleChange}>
                {paymentPlan && paymentPlan.map((plan, i) => (
                  <Plan plan={plan} planLength={paymentPlan.length} index={i} key={`plan-${i}`} />
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="action-holder">
            <Button color="primary" variant="contained" className={`${classes.btnContained} btn-next`} onClick={handleSubmit}>Confirm</Button>
            <p className="confirm-terms">By clicking the button, you have confirmed that you have read, understood and agree to the Terms and Conditions and Privacy Notice.</p>
          </div>
        </div>
      </div>
    )
  );
};

export default PaymentPlan;
