import { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { DialogActions, DialogContent } from "@material-ui/core";
import { StoreContext } from "../../store/context";
import { useThemeStyles } from "../../styles/themes";

import "./AvailableModal.scss";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "328px"
  }
}));

const AvailableModal = (props) => {
  const classes = useThemeStyles();
  const classes2 = useStyles();
  const { actions, state } = useContext(StoreContext);

  const handleClose = () => {
    actions.setData({ isALDialogShow: false });
  };

  return (
    <Dialog
      open={state.isALDialogShow}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes2.paper }}
    >
      <DialogContent>
        <div className="flex-holder">
          <img src="/images/circle-question-grey.svg" alt="icon" className="tip-icon" />
          <p className="text">The facilities limit shown is the maximum amount derived based on the maximum facilities tenure available.</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className={`${classes.btnOutlinedSecondary} btn-modal`} variant="outlined" color="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvailableModal;
