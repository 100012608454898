export const IS_LOADING = "IS_LOADING";
export const IS_BTN_LOADING = "IS_BTN_LOADING";
export const IS_PROCESSING = "IS_PROCESSING";
export const SET_DATA = "SET_DATA";
export const RESET_DATA = "RESET_DATA";
export const REMOVE_DATA = "REMOVE_DATA";
export const SET_STEP = "SET_STEP";
export const SET_DIALOG = "SET_DIALOG";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const SET_PAGE_DATA = "SET_PAGE_DATA";
export const SET_SUCCESS_DIALOG = "SHOW_SUCCESS_DIALOG";
