import { useContext, useEffect, useState } from "react";
import { Button, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import moment from "moment";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomCheckboxTerms from "../../../components/CustomCheckboxTerms";
import { formatter } from "../../../utils";
import { APPLICATION_STATUS } from "../../../constants/applicationStatus";

import { useThemeStyles } from "../../../styles/themes";
import "./ApplicationSubmitted.scss";

const ApplicationSubmitted = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { analyticsStep } = props;
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  // TODO: i18n
  const lang = "en";

  const makePayment = () => {
    if (state.pToken) {
      actions.startTransaction();
    }
  };

  const redirectDashboard = () => {
    if (state.dashboardUrl) {
      window.location.href = state.dashboardUrl;
    } else {
      window.location.href = process.env.REACT_APP_BNPL_DASHBOARD_URL;
    }
  };

  const toggleConsent = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  const proceedConsent = () => {
    actions.updateLimitConsent({
      consentSigned: true,
      consentSignedDate: moment().format("YYYY-MM-DD HH:mm")
    });
  };

  useEffect(() => {
    // if (state.embedded) {
    //   // Fetch dashboard URL
    //   actions.getDashboardUrl();
    // }

    // If consent acquired, show success page
    if (state.requireConsentFlag && state.applicationStatus == APPLICATION_STATUS.SUCCESS) {
      actions.setStep("ConsentConfirmed");
    }
  }, [state.requireConsentFlag, state.applicationStatus]);

  useEffect(() => {
    if (analyticsStep.isStepStarted && analyticsStep.isJourneyStarted) {
      // If consent not required,end UBD journey
      if (!state.requireConsentFlag) {
        analyticsStep.handleJourneyEnd();
      }
    }
  }, [analyticsStep.isStepStarted, analyticsStep.isJourneyStarted]);

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-application-submitted ">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <img
              className="account-activated-image"
              src="/images/account-activated-image.svg"
              // src={state.prerequisites?.journeyConfigurations?.confirmApplicationPage?.headerImageUrl}
              alt="account-activated"
            />
            <p className="congrats-title">Hooray!</p>
            <p className="congrats-sub-title fs-18">
              {state.prerequisites?.journeyConfigurations?.confirmApplicationPage?.messageBody?.[lang]}
            </p>
            <div className="balance-holder">
              <p className="balance-desc">Your PayFlex Spending Limit</p>
              <p className="balance-remain">{`RM ${formatter.format(state.eligibilityAmount / 100)}`}</p>
            </div>

            { state.applicationStatus == APPLICATION_STATUS.CONSENT_PENDING && state.requireConsentFlag ? (
              <>
                <div className="consent-holder">
                  <div className="consent-title">
                    {state.prerequisites?.journeyConfigurations?.confirmApplicationPage?.consentHeader?.[lang] || "confirmApplicationPage.consentHeader"}
                  </div>

                  <FormControlLabel
                    className={clsx("terms-holder", { "terms-checked": isConsentChecked }, (isConsentChecked ? classes.inputCheckBoxActive : classes.inputCheckBoxDefault))}
                    control={<CustomCheckboxTerms newStyle color="primary" className="terms-cb" name="isConsentChecked" checked={isConsentChecked} onChange={toggleConsent} />}
                    label={(
                      <p
                        className="terms"
                        dangerouslySetInnerHTML={{
                          __html: (state.prerequisites?.journeyConfigurations?.confirmApplicationPage?.consentBody?.[lang] || "confirmApplicationPage.consentBody")
                        }}
                      />
                    )}
                  />

                </div>
                <div className="action-holder">
                  <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={proceedConsent} disabled={!isConsentChecked}>
                    Proceed
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="action-holder">
                  {state.pToken && (
                    <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={makePayment}>
                      Make Payment
                    </Button>
                  )}
                  {!state.pToken && !state.embedded && (
                    <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={redirectDashboard}>
                      Go to PayFlex Dashboard
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(ApplicationSubmitted, {
  stepId: "ApplicationSubmitted",
  capturingFieldNames: [],
  endJourneyOnStepStart: false
});
