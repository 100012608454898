import { useContext } from "react";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";
import { StoreContext } from "../../../store/context";

import { useThemeStyles } from "../../../styles/themes";
import "./Processing.scss";

const Processing = () => {
  const classes = useThemeStyles();
  const { state } = useContext(StoreContext);

  return (
    state.prerequisites
    && (
      <div>
        <Header />
        <div className="page page-processing">
          <div className={`${classes.content} content hide-scrollbar`}>
            <PayLaterLogoTranslator />
            <div className={`${classes.txnPaymentProcessingHolder} processing-holder`}>
              <div className="lds-default">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <p className="processing-text">Processing</p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default Processing;
