import { useContext } from "react";
import { Button } from "@material-ui/core";
import { StoreContext } from "../../../store/context";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";

import { useThemeStyles } from "../../../styles/themes";
import "./Contract.scss";

const Contract = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);

  const handleSubmit = () => {
    const data = {
      transactionId: state.transaction.transactionId,
    };
    actions.acceptTransaction(data);
  };

  return (
    state.prerequisites
    && (
      <div>
        <Header />
        <div className="page page-contract">
          <div className={`${classes.content} content hide-scrollbar`}>
            <PayLaterLogoTranslator />
            <div className="title-holder">
              <p className="title">
                Boost PayLater Key Contract Terms
              </p>
            </div>
            <div className="contract-holder">
              <div className="contract-preview" dangerouslySetInnerHTML={{ __html: state.preview?.contractPreview }} />
            </div>
          </div>
          <div className="action-holder">
            <Button color="primary" variant="contained" className={`${classes.btnContained} btn-next`} onClick={handleSubmit}>Accept</Button>
            <p className="confirm-terms">By clicking the button, you have confirmed that you have read, understood and agree to the Terms and Conditions and Privacy Notice.</p>
          </div>
        </div>
      </div>
    )
  );
};

export default Contract;
