import { useContext } from "react";
import { useFormik } from "formik";
import { Button, IconButton } from "@material-ui/core";
import * as Yup from "yup";
import clsx from "clsx";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { ValidationUtil } from "../../../utils/validation";

import { useThemeStyles } from "../../../styles/themes";
import "./Step3Password.scss";

const validationSchema = Yup.object().shape({
  applicationId: Yup.string(),
  password: Yup.string().matches(ValidationUtil.REGEX_VALID_PASSWORD, "Password must be minimum 6 characters, must contain letters and numbers.").required("This is a required field"),
  confirmPassword: Yup.string().test("password-match", "Confirm Password must match", function (value) {
    return this.parent.password === value;
  }).required("This is a required field"),
});

const Step3Password = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;

  const formik = useFormik({
    initialValues: {
      applicationId: state.applicationId,
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // AnalyticsStep: End step
      analyticsStep.handleStepEnd();

      actions.setPassword(values);
    },
  });

  return (
    state.prerequisites
      && (
        <>
          <Header />
          <div className="page page-password">
            <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
              <IconButton className="btn-back">
                <img alt="back" src="/images/rounded-left-arrow.svg" />
              </IconButton>
              {stepText && (
                <div className="stepper-holder">
                  <span className={`${classes.stepText} step-text`}>
                    {stepText}
                  </span>
                  {/* { state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                  <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
                </div>
              )}

              <div>
                <p className={`${classes.title} title`}>Setup Your Password</p>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="form-control-holder">
                  <p className="form-title">Password</p>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    placeholder="Password"
                    className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.password && formik.errors.password })}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    {...analyticsStep.sensitiveInputProps}
                    // onBlur={formik.handleBlur}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      analyticsStep.sensitiveInputProps.onBlur(e);
                    }}
                  />
                  {formik.touched.password && formik.errors.password
                    ? (<span className="form-error">{formik.errors.password}</span>) : null}
                </div>
                <div className="form-control-holder">
                  <p className="form-title">Confirm Password</p>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    placeholder="Confirm Password"
                    className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.confirmPassword && formik.errors.confirmPassword })}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    {...analyticsStep.sensitiveInputProps}
                    // onBlur={formik.handleBlur}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      analyticsStep.sensitiveInputProps.onBlur(e);
                    }}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword
                    ? (<span className="form-error">{formik.errors.confirmPassword}</span>) : null}
                </div>
                <div className="action-holder">
                  <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" type="submit">
                    Set My Password
                  </Button>
                </div>
              </form>
            </div>
            <Footer />
          </div>
        </>
      )
  );
};

export default withUbdAnalyticsStep(Step3Password, {
  stepId: "Step3Password",
  capturingFieldNames: ["password", "confirmPassword"],
});
