import React from "react";
import ReactDOM from "react-dom";
import "./translations/i18n";
import reportWebVitals from "./reportWebVitals";
import disableConsoleOutput from "./disableConsoleLog";
import App from "./App";

import "./styles/index.scss";

const { REACT_APP_ENV, REACT_APP_VERSION, PUBLIC_URL } = process.env;

// Disable some console methods
if (REACT_APP_ENV === "production") {
  // whitelist: info, warn, error
  disableConsoleOutput(["log", "debug", "time", "timeEnd"]);
}

console.info(`[boost] REACT_APP_VERSION = "${REACT_APP_VERSION}"`);
console.info(`[boost] PUBLIC_URL = "${PUBLIC_URL}"`);
console.info(`[boost] REACT_APP_ENV = "${REACT_APP_ENV}"`);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
