import { useTranslation } from "react-i18next";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import clsx from "clsx";

import "./PayLaterLogoTranslator.scss";

const PayLaterLogoTranslator = (props) => {
  const { underBackButton = false } = props;
  const { i18n } = useTranslation();

  const handleChange = (event, value) => {
    i18n.changeLanguage(value);
    if (value) {
      localStorage.setItem("bnpl-language", value);
    }
  };

  return (
    <div className={clsx("content-header", { "mt-contra-back-button": underBackButton })}>
      {/* <IconButton className="btn-back">
        <img alt="back" src="/images/rounded-left-arrow.svg"></img>
      </IconButton> */}
      <img className="logo-paylater" src="/images/paylater-logo-boost.png" alt="logo" />
      <ToggleButtonGroup className="language-toggle-group" size="small" value={i18n.language} exclusive onChange={handleChange}>
        <ToggleButton className="language-toggle en" value="en">EN</ToggleButton>
        <ToggleButton className="language-toggle" value="ms">BM</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default PayLaterLogoTranslator;
