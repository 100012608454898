import initialState from "../state";
import {
  IS_LOADING,
  IS_BTN_LOADING,
  IS_PROCESSING,
  SET_DATA,
  RESET_DATA,
  SET_STEP,
  SET_DIALOG,
} from "../types";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_DIALOG: {
      const content = { isDialogShow: action.data.isDialogShow, dialog: action.data.dialog ? action.data.dialog : { dialogTitle: "", dialogContent: "" } };
      return { ...state, ...content };
    }
    case IS_LOADING: {
      return { ...state, isLoading: action.data };
    }
    case IS_BTN_LOADING: {
      return { ...state, isBtnLoading: action.data };
    }
    case IS_PROCESSING: {
      return { ...state, isProcessing: action.data };
    }
    case SET_DATA: {
      return action.name ? { ...state, [action.name]: { ...action.data } } : { ...state, ...action.data };
    }
    case RESET_DATA: {
      return { ...initialState };
    }
    case SET_STEP: {
      return { ...state, step: action.data };
    }
    default:
      return state;
  }
};

export default reducer;
