const disableConsoleOutput = (methods) => {
  if (!window.console) {
    window.console = {};
  }
  for (let i = 0; i < methods.length; i++) {
    console[methods[i]] = function () { };
  }
};

export default disableConsoleOutput;
