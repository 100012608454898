const routes = {
  SignIn: {
    path: "/signin",
    // exact: true,
    // isProtected: false,
    // component: null
  },
  SignUp: {
    path: "/signup",
    // exact: true,
    // isProtected: false,
    // component: null
  },
  Transaction: {
    path: "/transaction",
    // exact: true,
    // isProtected: false,
    // component: null
  },
  ErrorPage: {
    path: "/error",
    // exact: true,
    // isProtected: false,
    // component: null
  }
};
export default routes;
