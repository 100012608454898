import { PRODUCT_CLIENT } from "../constants/productClient";

export const formatter = new Intl.NumberFormat("ms", {
  style: "decimal",
  currency: "MYR",
  minimumFractionDigits: 2,
});

export const hasQueryParam = (url) => {
  return url && url.includes("?");
};

export const addQueryParam = (url, params = {}) => {
  let newRoute = url;
  if (Object.keys(params).length) {
    Object.keys(params).forEach((key) => {
      newRoute += hasQueryParam(newRoute) ? "&" : "?";
      newRoute += `${key}=${params[key]}`;
    });
  }
  return newRoute;
};

export const checkIsPayFlex = () => {
  const clientId = sessionStorage.getItem("clientId");

  return clientId == PRODUCT_CLIENT.BOOST.clientId;
};
