import { useContext, useEffect } from "react";
import clsx from "clsx";
import { useLocation, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import routes from "../../../router";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";

import { useThemeStyles } from "../../../styles/themes";
import "./Landing.scss";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("This is a required field"),
  password: Yup.string().required("This is a required field")
});

const Landing = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        customerType: "ENDUSER",
        ipAddress: "123.1.5",
        device: {
          type: "DESKTOP",
          subtype: "WINDOWS"
        }
      };
      actions.signIn(data);
    },
  });

  const toSignUp = () => {
    actions.setStep("Step0ProductPage");
    history.push(`${routes.SignUp.path}${location.search}`);
  };

  const forgotPassword = () => {
    actions.setStep("ForgotPassword");
  };

  useEffect(() => {
    if (!state.prerequisites && state.clientId) {
      actions.prerequisites({
        clientId: state.clientId
      });
    }
  }, []);

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-landing">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <PayLaterLogoTranslator />
            <p className={`${classes.title} center title`}>Pay later with Boost</p>
            <p className={`${classes.subtitle} small center description`}>Login to your account to get started</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-control-holder">
                <p className="form-title">Email or Username</p>
                <input
                  id="email"
                  name="email"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.email && formik.errors.email })}
                  placeholder="username@email.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email && (<p className="form-error">{formik.errors.email}</p>)}
              </div>
              <div className="form-control-holder">
                <p className="form-title">Password</p>
                <input
                  autoComplete="off"
                  id="password"
                  name="password"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.password && formik.errors.password })}
                  placeholder="Password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.errors.password && formik.touched.password && (<p className="form-error">{formik.errors.password}</p>)}
              </div>
              <Button className={`${classes.btnContained} btn-next btn-login`} variant="contained" type="submit" color="primary">
                Login
              </Button>
              <p className="forgot-password" onClick={forgotPassword}>forgot password?</p>
              <div className="or-holder">
                <div className={`${classes.divider} divider`} />
                <span className="or">or</span>
                <div className={`${classes.divider} divider`} />
              </div>
              <Button className={`${classes.btnOutlinedSecondary} btn-next`} variant="outlined" color="secondary" onClick={toSignUp}>
                SIGN UP
              </Button>
            </form>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default Landing;
