import { useContext, useState } from "react";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { StoreContext } from "../../../store/context";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";

import { useThemeStyles } from "../../../styles/themes";
import "./Insurance.scss";

const Insurance = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const [checked, setChecked] = useState(false);

  const handleSubmit = () => {
    const data = {
      transactionId: state.transaction.transactionId,
      paymentPlan: {
        timeUnit: state.selectedPlan?.timeunit,
        tenure: state.selectedPlan?.tenure,
      }
    };
    if (checked) {
      data.supplementaryServiceIds = [state.supplementary.supplementaryServiceId];
    }
    actions.updatePreviewTransaction(data);
  };

  return (
    state.prerequisites
    && (
      <div>
        <Header />
        <div className="page page-insurance">
          <div className={`${classes.content} content hide-scrollbar`}>
            <PayLaterLogoTranslator />
            <div className="insurance-info-holder">
              <img className="insurance-logo" src="/images/insurance-logo2x.png" alt="logo" />
              <p className="free-insurance-text">{state.supplementary?.name}</p>
              <p className="free-insurance-sub-text">{state.supplementary?.description}</p>
              <FormControlLabel
                className="terms-holder"
                control={<Checkbox color="primary" className="terms-cb" checked={checked} name="termsAcknowledgement" onChange={() => setChecked(!checked)} />}
                label={(
                  <div>
                    <p className="terms-text" dangerouslySetInnerHTML={{ __html: state.supplementary?.presentationText }} />
                    {/* <p className="terms-text">
                        Yes, I would like to accept FREE Boost PayLater Protect insurance – Personal Accident insurance coverage.
                      </p>
                      <p className="terms-sub-text">
                        I hereby acknowledge that I have read, understand and agree to the Terms and Conditions of this FREE insurance coverage.
                      </p> */}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="action-holder">
            <Button color="primary" variant="contained" className={`${classes.btnContained} btn-next`} onClick={handleSubmit} disabled={!checked && state.supplementary?.mandatory === 1}>Next</Button>
            <p className="confirm-terms">By clicking the button, you have confirmed that you have read, understood and agree to the Terms and Conditions and Privacy Notice.</p>
          </div>
        </div>
      </div>
    )
  );
};

export default Insurance;
