import { useContext } from "react";
import Button from "@material-ui/core/Button";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./PasswordSuccess.scss";

const PasswordSuccess = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);

  const toSignIn = () => {
    actions.setStep("Landing");
  };

  return (
    state.prerequisites
      && (
        <>
          <Header />
          <div className="page page-password-success">
            <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
              <PayLaterLogoTranslator />
              <img className="successful-logo" src="/images/password-success.svg" alt="success" />
              <p className={`${classes.subtitle} center description`}>Password has been set</p>
              <div className="action-holder">
                <Button className={`${classes.btnContained} btn-next`} variant="contained" type="submit" color="primary" onClick={toSignIn}>
                  Log in
                </Button>
              </div>
            </div>
            <Footer />
          </div>
        </>
      )
  );
};

export default PasswordSuccess;
