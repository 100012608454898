import { useContext } from "react";
import { StoreContext } from "../../store/context";
import OfflinePurchase from "./OfflinePurchase";
import PaymentPlan from "./PaymentPlan";
import Insurance from "./Insurance";
import WakalahContract from "./WakalahContract";
import Contract from "./Contract";
import ContractOTP from "./ContractOTP";
import SuccessfulPayment from "./SuccessfulPayment";
import Processing from "./Processing";

import "./Transaction.scss";

const getContent = (step) => {
  switch (step) {
    case "OfflinePurchase":
      return <OfflinePurchase />;
    case "PaymentPlan":
      return <PaymentPlan />;
    case "Insurance":
      return <Insurance />;
    case "WakalahContract":
      return <WakalahContract />;
    case "Contract":
      return <Contract />;
    case "ContractOTP":
      return <ContractOTP />;
    case "SuccessfulPayment":
      return <SuccessfulPayment />;
    case "Processing":
      return <Processing />;
    default:
      return null;
  }
};

const Transaction = () => {
  const { state } = useContext(StoreContext);

  return (
    <div>
      {getContent(state.step)}
    </div>
  );
};

export default Transaction;
