import { useContext, useState } from "react";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./Step7ShareLocation.scss";

let requestLocationServiceRetry = 0;

const Step7ShareLocation = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [disable, setDisable] = useState(false);

  const performScoring = () => {
    // AnalyticsStep: End step
    analyticsStep.handleStepEnd();

    actions.creditScoring({ applicationId: state.applicationId });
  };

  const invokeLocation = () => {
    // added limit of retry for requesting location service, to prevent the sales gone.

    setDisable(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    } else {
      console.log("Geolocation is not supported by this browser.");

      performScoring();

      setDisable(false);
    }
  };

  function showPosition(position) {
    if (position) {
      // AnalyticsStep: Store Geolocation
      analyticsStep.storeLocation(position);

      performScoring();
    }
    setDisable(false);
  }

  function showError(error) {
    if (requestLocationServiceRetry < 2) {
      requestLocationServiceRetry += 1;
      switch (error.code) {
        case error.PERMISSION_DENIED:
          actions.showCustomErrorDialog("Location Service",
            "User denied the request for Geolocation.<br/>Please turn on location service from phone setting to help us get to know you better.<br/>• improve your credit scoring<br/>• Evaluate your applied amount more thoroughly.");
          console.log("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          requestLocationServiceRetry = 2;
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          requestLocationServiceRetry = 2;
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          requestLocationServiceRetry = 2;
          console.log("An unknown error occurred.");
          break;
        default:
          break;
      }
    }

    // prevent the sales gone.
    if (requestLocationServiceRetry >= 2) {
      performScoring();
    }

    setDisable(false);
  }

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-scoring">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <IconButton className="btn-back">
              <img alt="back" src="/images/rounded-left-arrow.svg" />
            </IconButton>
            {stepText && (
              <div className="stepper-holder">
                <span className={`${classes.stepText} step-text`}>
                  {stepText}
                </span>
                {/* {state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
              </div>
            )}

            <div>
              <p className={`${classes.title} title`}>Click “Allow or Share Location”</p>
            </div>

            <img className="map-image" src="/images/map.png" alt="map" />
            <p className={`${classes.subtitle} sub-title`}>Help us get to know you better. This will help us to:</p>
            <p className="point">
              • Improve your credit scoring
            </p>
            <p className="point">
              • Evaluate your applied amount more thoroughly
            </p>

            <div className="action-holder">
              <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={invokeLocation} disabled={disable}>
                {disable ? <CircularProgress size={25} />
                  : (
                    <>
                      Allow & Check Eligibility
                    </>
                  )}
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(Step7ShareLocation, {
  stepId: "Step7ShareLocation",
  capturingFieldNames: [],
});
