// repo: service-paylater\app\helpers\Constants.java
// enum `ApplicationStatus`
export const APPLICATION_STATUS = {
  OTP_GENERATED: "OTP_GENERATED",
  OTP_VERIFIED: "OTP_VERIFIED",
  CUSTOMER_CREATED: "CUSTOMER_CREATED",
  APPLICATION_SUBMITTED: "APPLICATION_SUBMITTED",
  APPLICATION_CONFIRMED: "APPLICATION_CONFIRMED",
  KYC_SUCCESS: "KYC_SUCCESS",
  KYC_FAILED: "KYC_FAILED",
  CREDIT_SCORE_SUCCESS: "CREDIT_SCORE_SUCCESS",
  CREDIT_SCORE_FAILED: "CREDIT_SCORE_FAILED",
  MANDATE_INITIATED: "MANDATE_INITIATED",
  MANDATE_APPROVED: "MANDATE_APPROVED",
  MANDATE_FAILED: "MANDATE_FAILED",
  MANDATE_PENDING: "MANDATE_PENDING",
  SIGN_UP_CONFIRMED: "SIGN_UP_CONFIRMED",
  UNDER_REVIEW: "UNDER_REVIEW",
  FAILED: "FAILED",
  EXPIRED: "EXPIRED",
  DEACTIVATED: "DEACTIVATED",
  DISABLED: "DISABLED",
  SUCCESS: "SUCCESS",
  TERMINATED: "TERMINATED",
  BLACKLISTED: "BLACKLISTED",
  MANUALLY_BLACKLISTED: "MANUALLY_BLACKLISTED",
  CONSENT_PENDING: "CONSENT_PENDING",
};
