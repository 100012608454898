import { useContext, useEffect } from "react";
import { Button } from "@material-ui/core";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./ScoringFailure.scss";

const ScoringFailure = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);

  const redirectHome = () => {
    if (state.pToken) {
      actions.cancelPayment();
    } else {
      window.location.href = process.env.REACT_APP_BNPL_DASHBOARD_URL;
    }
  };

  const handleContact = () => {
    window.open(`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`);
  };

  return (
    state.prerequisites && (
      <>
        <Header />
        <div className="page page-scoring-failure">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <div className="banner-holder" style={{ backgroundImage: "url(/images/sorry-banner.svg)" }}>
              <p className="banner-title">Sorry...</p>
              <p className="banner-desc">We are unable to process your application right now.</p>
            </div>
            <p className="text-desc">
              Thank you for your Boost PayFlex application. After carefully reviewing your application, which included a CCRIS assessment, we are
              currently unable to offer you a PayFlex credit limit at this time.
              <br />
              <br />
              However, you can improve your eligibility for future consideration by:
              <br />
              <br />
              <div className="text-desc-point">
                <div className="text-desc-point-dot">•</div>
                <div className="text-desc-point-desc">
                  Finding out more about your CCRIS score and how to improve it:{" "}
                  <a href="https://www.bnm.gov.my/ccris">https://www.bnm.gov.my/ccris</a>
                </div>
              </div>
              <div className="text-desc-point">
                <div className="text-desc-point-dot">•</div>
                <div className="text-desc-point-desc">
                  Checking your eligibility again through the Boost App in the future. To know more about the PayFlex eligibility criteria, please
                  visit our FAQ: <a href="https://myboost.co/boost-payflex">https://myboost.co/boost-payflex</a>
                </div>
              </div>
            </p>
            <div className="action-holder">
              {!state.embedded && (
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" onClick={redirectHome}>
                  Return Home
                </Button>
              )}
              <Button className={`${classes.btnOutlinedSecondary} btn-next`} variant="outlined" color="secondary" onClick={handleContact}>
                Contact Support
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(ScoringFailure, {
  stepId: "ScoringFailure",
  endJourneyOnStepStart: true,
});
