import { useContext, useEffect, useState } from "react";
import ls from "local-storage";
import { useFormik } from "formik";
import { Button, InputBase, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import * as Yup from "yup";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { StoreContext } from "../../../store/context";

import { useThemeStyles } from "../../../styles/themes";
import "./Step8Mandate.scss";

const validationSchema = Yup.object().shape({
  applicationId: Yup.string(),
  bankId: Yup.string().nullable().required("This is a required field"),
});

const Step8Mandate = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [isBoostJourney, setIsBoostJourney] = useState(!!state.piiData);
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (state.applicationId && params.get("fpx_debitAuthCode")) {
      // AnalyticsStep: Import state
      analyticsStep.importStateLS();

      const data = {
        applicationId: state.applicationId,
      };
      actions.getMandateStatus(data);
      ls.clear();
    } else {
      actions.getBankList();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      applicationId: state.applicationId,
      bankId: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values
      };
      // delete state.prerequisites;
      ls.set("bnplstate", state);
      const checkoutUrl = `${window.location.href.split("?")[0]}?step=8&embedded=${params.get("embedded")}`;
      // const encodedUrl = encodeURIComponent(checkoutUrl);
      data.checkoutUrl = checkoutUrl;

      // AnalyticsStep: End step
      analyticsStep.handleStepEnd();

      actions.initialMandate(data);
    },
  });

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-mandate">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            {/* <IconButton className="btn-back">
                <img alt="back" src="/images/rounded-left-arrow.svg"></img>
              </IconButton> */}
            {stepText && (
              <div className="stepper-holder">
                <span className={`${classes.stepText} step-text`}>
                  {stepText}
                </span>
                {/* {state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
              </div>
            )}

            {/* {isBoostJourney ? ( */}
            <div>
              <p className={`${classes.title} title`}>Set Repayment Method</p>
              <p className={`${classes.subtitle} small bold sub-title`}>Link a bank account to set it as your repayment method. Your PayFlex repayments will be auto-deducted from this bank account.</p>
            </div>
            {/* ) : (
              <div>
            <p className={`${classes.title} title`}>Setup Repayment Method</p>
            <p className={`${classes.subtitle} small bold sub-title`}>REPAY SUM AMOUNT VIA:</p>
              </div>
            )} */}

            <form onSubmit={formik.handleSubmit}>
              <div className="form-control-holder">
                <p className="form-title">Select your bank</p>
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  id="bankId"
                  name="bankId"
                  displayEmpty
                  className={clsx("form-input form-select-input", classes.inputSelectBox,
                    { placeholder: !formik.values.bankId },
                    { "form-error-border": formik.touched.bankId && formik.errors.bankId })}
                  onChange={formik.handleChange}
                  value={formik.values.bankId}
                  input={<InputBase />}
                >
                  <MenuItem value="" disabled>Bank Name</MenuItem>
                  {state.banks?.map((item, i) => <MenuItem key={`banks${i}`} value={item.id}>{item.name}</MenuItem>)}
                </Select>
                {formik.touched.bankId && formik.errors.bankId
                  ? (<span className="form-error">{formik.errors.bankId}</span>) : null}
              </div>

              <div className={`${classes.divider} divider`} />

              <p className="note">
                <b>NOTE:</b>
                <br />
                RM 1.00 (non-refundable) will be deducted to verify and link your bank account.
              </p>

              <div className={`${classes.divider} divider`} />

              <div className="action-holder">
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" type="submit">
                  Continue to FPX Verification
                </Button>
              </div>
              <p className="contact-us">
                If your bank is unavailable, contact us at:
                <br />
                <a className="contact-link" href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
              </p>
              <p className="partners-space">
                <img src="/images/paynet/FPX_Logo_Standard_FC.png" alt="fpx" className="partner-logo" />
              </p>
            </form>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(Step8Mandate, {
  stepId: "Step8Mandate",
  capturingFieldNames: [],
  importStateBeforeStepStart: false, // manually import for after mandate
  exportStateAfterStepEnd: true,
});
