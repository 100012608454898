/*
  Phase 1 Web - Component Steps:
    0. Step0ProductPage (content/image from prerequisiteApi)
    1. Step1PersonalDetails
    2. Step2OTP
    3. Step3Password
    4. Step4Address
    5. Step5EmploymentDetails
    6. Step6KYC
    7. Step7ShareLocation
      x. ScoringLoading
      x. ScoringSuccess / ScoringFailure
    8. Step8Mandate
      x. ConfirmSignUpOTP (not used)
      x. ApplicationSubmitted (content/image from API)

  Phase 2 Boost - Component Steps:
  *lToken = provided in redirectUrl, used to fetch piiData
     . Step0ProductPage (skipped - boolean and content/image from prerequisiteApi - skipProductPage)
    0. Step1PersonalDetails (autofill from piiData)
     . Step2OTP (skipped - boolean from prerequisiteApi - skipVerifyMsisdnOtp)
     . Step3Password (skipped - boolean from prerequisiteApi - skipPassword)
     . Step4Address (skipped - autofill from piiData - skipAddress)
     . Step5EmploymentDetails (skipped - boolean from prerequisiteApi - skipEmploymentDetails)
     . Step6KYC (skipped - boolean from prerequisiteApi - skipKyc)
     . Step7ShareLocation (skipped - boolean from prerequisiteApi - skipShareLocation)
      x. ScoringLoading
      x. ScoringSuccess / ScoringFailure
    2. Step8Mandate
      x. ConfirmSignUpOTP (not used)
      x. ApplicationSubmitted (content/image from API)
      x. ConsentConfirmed
 */

const calcStepsAndAlias = (stepDefinition) => {
  const newStepDefinition = JSON.parse(JSON.stringify(stepDefinition));
  // update totalSteps
  newStepDefinition.totalSteps = newStepDefinition.steps.length - 1;
  // update alias step
  const lastStepBeforeMandate = newStepDefinition.steps.filter((stepName) => stepName != "Step8Mandate").pop();
  newStepDefinition.alias = {
    "StepHighRisk": lastStepBeforeMandate,
    "ScoringLoading": lastStepBeforeMandate,
    "ScoringFailed": lastStepBeforeMandate,
    "ScoringSuccess": lastStepBeforeMandate,
  };
  return newStepDefinition;
};

/*
  Sample journeyConfig from prerequisites API
  prerequisites.journeyConfigurations.global: {
    "skipProductPage": false,
    "skipVerifyMsisdnOtp": false,
    "skipPassword": false,
    "skipAddress": false,
    "skipEmploymentDetails": false,
    "skipKyc": false,
    "skipShareLocation": false
  }
*/
const SignUpStepDefinition = calcStepsAndAlias({
  steps: [
    "Step0ProductPage", // skipProductPage
    "Step1PersonalDetails",
    "Step2OTP", // skipVerifyMsisdnOtp
    "Step3Password", // skipPassword
    "Step4Address", // skipAddress
    "Step5EmploymentDetails", // skipEmploymentDetails
    "Step6KYC", // skipKyc
    "Step7ShareLocation", // skipShareLocation
    "Step8Mandate",
  ],
  totalSteps: 0, // will auto populate using calcStepsAndAlias()
  alias: {
    // will auto populate using calcStepsAndAlias()
    // other steps that should have the same stepper number
    "StepHighRisk": "Step7ShareLocation",
    "ScoringLoading": "Step7ShareLocation",
    "ScoringFailed": "Step7ShareLocation",
    "ScoringSuccess": "Step7ShareLocation",
  }
});

const getFallbackStep = (step) => {
  if (step) {
    // resolve alias step
    const usedStepName = SignUpStepDefinition.alias?.[step] || step;
    return usedStepName;
  }
  return null;
};

export {
  SignUpStepDefinition as default,
  getFallbackStep,
  calcStepsAndAlias
};
