import Backdrop from "@material-ui/core/Backdrop";
import { useContext } from "react";
import { StoreContext } from "../../store/context";

import "./PageLoader.scss";

const PageLoader = () => {
  const { state } = useContext(StoreContext);

  return (
    <Backdrop className="backdrop" open={state.isLoading}>
      <span className="spinner loader_1" role="status" />
      <span className="spinner loader_2" role="status" />
      <span className="spinner loader_3" role="status" />
    </Backdrop>
  );
};

export default PageLoader;
