import { useContext } from "react";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./ForgotPassword.scss";

const validationSchema = Yup.object().shape({
  msisdn: Yup.string().required("This is a required field."),
});

const ForgotPassword = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);

  const formik = useFormik({
    initialValues: {
      msisdn: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        msisdn: `60${values.msisdn}`,
        customerType: "ENDUSER"
      };
      actions.setData({ msisdn: values.msisdn });
      actions.forgotPassword(data);
    },
  });

  const toSignIn = () => {
    actions.setStep("Landing");
  };

  return (
    state.prerequisites
      && (
        <>
          <Header />
          <div className="page page-forgot-password">
            <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
              <PayLaterLogoTranslator />
              <p className={`${classes.title} center title`}>Reset Password</p>
              <p className={`${classes.subtitle} small center description`}>Please confirm your mobile phone number to set a new password</p>
              <form onSubmit={formik.handleSubmit}>
                <div className="form-control-holder">
                  <p className="form-title">Mobile Phone Number</p>
                  <div className="form-flex-holder">
                    <span className="phone-prefix">+60</span>
                    <input
                      id="msisdn"
                      name="msisdn"
                      placeholder="12345678"
                      className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.msisdn && formik.errors.msisdn })}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.msisdn}
                    />
                  </div>
                  {formik.touched.msisdn && formik.errors.msisdn
                    ? (<span className="form-error">{formik.errors.msisdn}</span>) : null}
                </div>
                <Button className={`${classes.btnContained} btn-next`} variant="contained" type="submit" color="primary">
                  Confirm
                </Button>
                <p className="back-to-signin" onClick={toSignIn}>Back to Login / Signup</p>
              </form>
            </div>
            <Footer />
          </div>
        </>
      )
  );
};

export default ForgotPassword;
