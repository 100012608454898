import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ls from "local-storage";
import { StoreContext } from "../../store/context";
import SignUpStepDecider from "./SignUpStepDecider";
// import Template from '../../components/Template';

import "./SignUp.scss";

const SignUp = () => {
  const { state, actions } = useContext(StoreContext);
  const params = new URLSearchParams(useLocation().search);
  const [stepContent, setStepContent] = useState(null);

  useEffect(() => {
    // Skip to Step1PersonalDetails if we did not come from mandate step.
    if (state.prerequisites?.journeyConfigurations?.global?.skipProductPage && !state.kycToken && !state.debug) {
      actions.setStep("Step1PersonalDetails");
    }
  }, [state.prerequisites?.journeyConfigurations]);

  useEffect(() => {
    // Handling skipping of steps to avoid loading unwanted component
    let skipped = false;
    switch (state.step) {
      case "Step0ProductPage":
        if (state.prerequisites?.journeyConfigurations?.global.skipProductPage) {
          skipped = true;
          actions.setStep("Step1PersonalDetails");
        }
        break;
      case "Step2OTP":
        if (state.prerequisites?.journeyConfigurations?.global.skipVerifyMsisdnOtp) {
          skipped = true;
          actions.setStep("Step3Password");
        }
        break;
      case "Step3Password":
        if (state.prerequisites?.journeyConfigurations?.global.skipPassword) {
          skipped = true;
          actions.setStep("Step4Address");
        }
        break;
      case "Step4Address":
        if (state.prerequisites?.journeyConfigurations?.global.skipAddress) {
          skipped = true;
          actions.setStep("Step5EmploymentDetails");
        }
        break;
      case "Step5EmploymentDetails":
        if (state.prerequisites?.journeyConfigurations?.global.skipEmploymentDetails) {
          skipped = true;
          if (!state.applicationConfirmed) {
            actions.confirmApplication({ applicationId: state.applicationId }, true);
          } else {
            actions.setStep("Step6KYC");
          }
        }
        break;
      case "Step6KYC":
        if (state.prerequisites?.journeyConfigurations?.global.skipKyc) {
          skipped = true;
          actions.setStep("Step7ShareLocation");
        }
        break;
      case "Step7ShareLocation":
        if (state.prerequisites?.journeyConfigurations?.global.skipShareLocation) {
          skipped = true;
          actions.creditScoring({ applicationId: state.applicationId });
        }
        break;
      default:
        break;
    }
    if (!skipped) {
      const { stepDefinition, jsx } = SignUpStepDecider(state.step, state.prerequisites);
      actions.setData({
        stepDefinition,
      });
      setStepContent(jsx);
    }
  }, [state.step, state.prerequisites]);

  useEffect(() => {
    // validate missing lToken when prerequisites.skipPassword = true
    const deepLinkUrl = params.get("deepLinkUrl");
    if (deepLinkUrl && deepLinkUrl.length > 0) {
      window.location.href = deepLinkUrl;
    }
  }, [params.get("deepLinkUrl")]);

  useEffect(() => {
    actions.setData({
      currentPage: "signUp",
    });

    // Removes storage
    sessionStorage.removeItem("clientId");

    const clientId = params.get("clientId");
    if (clientId) {
      sessionStorage.setItem("clientId", clientId);
    }

    // dev - quick jump to component
    // if (!state.prerequisites) {
    //   actions.prerequisites({
    //     clientId,
    //   });
    // }
    // actions.setData({
    //   debug: params.get("debug"),
    //   lToken: params.get("lToken"),
    //   // pToken: params.get("pToken"),
    //   piiData: {
    //     fullName: "Some Full Name",
    //     nric: "880808088888",
    //     email: "example@myboost.co",
    //     msisdn: "60123456789",
    //   },
    //   embedded: params.get("embedded") === "true",
    //   clientId,
    //   // applicationId: params.get("applicationId"),
    //   // customerId: params.get("customerId"),
    //   step: params.get("step"),
    // });
    // return;
    // /* eslint-disable */

    if (params.get("testCamera") === "1") {
      actions.setData({
        pToken: params.get("pToken"),
        applicationId: params.get("applicationId"),
        customerId: params.get("customerId"),
        lToken: params.get("lToken"),
        embedded: params.get("embedded") === "true",
        step: "Step6KYC", // Step6KYC Step0ProductPage
      });
    } else if (clientId && params.get("step") !== "8") {
      if (!state.prerequisites) {
        actions.prerequisites({
          clientId,
        });
      }
      // create account with valid URL
      actions.setData({
        clientId,
        pToken: params.get("pToken"),
        applicationId: params.get("applicationId"),
        customerId: params.get("customerId"),
        lToken: params.get("lToken"),
        embedded: params.get("embedded") === "true",
        step: "Step0ProductPage",
      });
    } else if (params.get("step") === "8") {
      // after mandate come back and redirect them to desire page and check mandate status
      actions.setData({ ...ls.get("bnplstate") });
    } else {
      actions.setStep(null);
      actions.showInvalidUrlDialog();
    }
  }, []);

  return (
    <>
      {/* <Template paylaterLogo={true} footer={true}> */}
      {stepContent}
      {/* </Template> */}
    </>
  );
};

export default SignUp;
