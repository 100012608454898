import { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import clsx from "clsx";
import { StoreContext } from "../../store/context";

import { useThemeStyles } from "../../styles/themes";
import "./Footer.scss";

const Footer = () => {
  const classes = useThemeStyles();
  const { state } = useContext(StoreContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <footer className={classes.footer}>
      <a href={state.prerequisites?.merchant?.faqUrl} target="_blank" rel="noopener noreferrer">FAQ</a>
      <a href={state.prerequisites?.merchant?.termsOfUseUrl} target="_blank" rel="noopener noreferrer">Terms Of Use</a>
      <a href={state.prerequisites?.merchant?.privacyNoticeUrl} target="_blank" rel="noopener noreferrer">Privacy Notice</a>
      <span onClick={handleClickOpen}>Contact</span>

      <Dialog
        fullWidth={false}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="contact"
        className="contact-dialog"
      >
        <DialogTitle id="contact" className="contact-title">
          Get in touch with us
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="contact-content-text">
            Monday to Friday, 9:30AM to 5:30PM
            <div className="flex-holder">
              <Link className="contact-link" href={`tel:${process.env.REACT_APP_SUPPORT_TEL}`}>
                <PhoneIcon />
                {" "}
                {process.env.REACT_APP_SUPPORT_TEL}
              </Link>
              <Link className="contact-link" href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                <MailIcon />
                {" "}
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </Link>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={clsx(`${classes.btnOutlinedSecondary} btn-modal`, { "btn-boost": !state.prerequisites })} variant="outlined" color="secondary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </footer>
  );
};

export default Footer;
