import moment from "moment";

export const ValidationUtil = {
  REGEX_VALID_FULLNAME: /^[a-zA-Z/.@'\- ]*$/,
  REGEX_VALID_NRIC: /^\d{6}-\d{2}-\d{4}$/,
  REGEX_VALID_MSISDN: /^(0)?[1][0-9]{8,9}$/,
  REGEX_VALID_PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])(.{6,})$/,
  nricDobValidator: (value) => {
    if (value) {
      const nric = value;
      const dob = nric?.split("-")[0]?.substring(0, 6);
      const momentDate = moment(dob, "YYMMDD");
      return momentDate.isValid();
    }
    return false;
  },
  ageValidator: (value) => {
    if (value) {
      const minimumAge = 21;
      const maximumAge = 60;
      const nric = value;
      const dob = nric?.split("-")[0]?.substring(0, 6);
      const momentDate = moment(dob, "YYMMDD");
      if (momentDate.year() > moment().year()) {
        momentDate.subtract(100, "year");
      }
      const age = Math.abs(moment().diff(momentDate, 'years'));
      if (age >= 21 && age <= 60) {
        return true;
      }
      return false;
    }
    return false;
  },
};
