import { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import OTPInput, { ResendOTP } from "otp-input-react";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Header from "../../../components/Header";
import { StoreContext } from "../../../store/context";

import { useThemeStyles } from "../../../styles/themes";
import "./ContractOTP.scss";

const ContractOTP = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { transactionValidateOTP, transactionResendOTP } = actions;
  const [OTP, setOTP] = useState("");

  const resendOTP = () => {
    const params = {
      transactionId: state.transaction.transactionId
    };
    transactionResendOTP(params);
  };

  const submitOTP = () => {
    const params = {
      transactionId: state.transaction.transactionId,
      otp: OTP,
      otpType: "FINANCING_AGREEMENT",
      paymentPlan: state.selectedPlan
    };
    transactionValidateOTP(params);
  };

  const otpBtn = (otpProp) => (
    <Button
      onClick={otpProp.onClick}
      disabled={otpProp.disabled}
      color="secondary"
      variant="outlined"
      className={`${classes.btnOutlinedSecondary} btn-next btn-second btn-otp-resend`}
    >
      {otpProp.remainingTime !== 0
        ? `Please wait ${otpProp.remainingTime} seconds`
        : "Resend OTP"}
    </Button>
  );

  return (
    state.prerequisites
    && (
      <div>
        <Header />
        <div className="page page-contract-otp">
          <div className={`${classes.content} content hide-scrollbar`}>
            <PayLaterLogoTranslator />
            <p className={`${classes.title} center title`}>Accept Your PayLater</p>
            <p className={`${classes.subtitle} small center sub-title`}>
              We have sent a 6-digit OTP verification code to
              {" "}
              {" "}
              <span className="phone">
                {state.customer?.msisdn}
              </span>
            </p>

            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus={false}
              OTPLength={6}
              otpType="number"
              className={`${classes.inputOtpDigit} otp-input`}
              disabled={false}
            />
            <p className="six-digit-text">6-Digit OTP Code</p>

            <div className="action-holder">
              <Button variant="contained" color="primary" className={`${classes.btnContained} btn-next`} type="submit" disabled={OTP.length < 6 || state.isLoading} onClick={submitOTP}>
                Verify & Accept Your PayLater
              </Button>
              <ResendOTP
                className="otp-resend"
                maxTime={30}
                renderButton={otpBtn}
                renderTime={() => null}
                onResendClick={resendOTP}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ContractOTP;
