import { useContext } from "react";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import Header from "../../../components/Header";
import PayLaterLogoTranslator from "../../../components/PayLaterLogoTranslator";
import Footer from "../../../components/Footer";
import { StoreContext } from "../../../store/context";
import { ValidationUtil } from "../../../utils/validation";

import { useThemeStyles } from "../../../styles/themes";
import "./NewPassword.scss";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().matches(ValidationUtil.REGEX_VALID_PASSWORD, "Password must be minimum 6 characters, must contain letters and numbers.").required("This is a required field"),
  confirmNewPassword: Yup.string().test("password-match", "Confirm Password must match", function (value) {
    return this.parent.newPassword === value;
  }).required("This is a required field"),
});

const NewPassword = () => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
      };
      delete data.confirmNewPassword;
      actions.resetPassword(data);
    },
  });

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-new-password">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <PayLaterLogoTranslator />
            <p className={`${classes.title} center title`}>Set new password</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-control-holder">
                <p className="form-title">New Password</p>
                <input
                  autoComplete="off"
                  id="newPassword"
                  name="newPassword"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.newPassword && formik.errors.newPassword })}
                  placeholder="Password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />
                {formik.errors.password && formik.touched.newPassword && (<p className="form-error">{formik.errors.newPassword}</p>)}
              </div>
              <div className="form-control-holder">
                <p className="form-title">Confirm New password</p>
                <input
                  autoComplete="off"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.confirmNewPassword && formik.errors.confirmNewPassword })}
                  placeholder="Password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmNewPassword}
                />
                {formik.errors.confirmNewPassword && formik.touched.confirmNewPassword && (<p className="form-error">{formik.errors.confirmNewPassword}</p>)}
              </div>
              <div className="action-holder">
                <Button className={`${classes.btnContained} btn-next`} variant="contained" type="submit" color="primary">
                  Set my Password
                </Button>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default NewPassword;
