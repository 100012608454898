import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, IconButton, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Yup from "yup";
import _ from "lodash";
import clsx from "clsx";
import { withUbdAnalyticsStep } from "@aspirasi/react-data-analytics";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import { useThemeStyles } from "../../../styles/themes";
import "./Step4Address.scss";

const validationSchema = Yup.object().shape({
  applicationId: Yup.string(),
  addressLine1: Yup.string().required("This is a required field"),
  addressLine2: Yup.string(),
  addressLine3: Yup.string(),
  postCodeObj: Yup.object().nullable().required("This is a required field"),
});

// const filterOptions = createFilterOptions({
//   matchFrom: "start",
//   stringify: (option) => option.postcode,
// });

const filterOptions = (options, inputValue) => {
  return _.filter(options, ((option) => option.postcode.startsWith(inputValue)));
};

const Step4Address = (props) => {
  const classes = useThemeStyles();
  const { state, actions } = useContext(StoreContext);
  const { currentStep, totalSteps, stepText, analyticsStep } = props;
  const [isBoostJourney, setIsBoostJourney] = useState(!!state.piiData);
  const [postcodeOptions, setPostcodeOptions] = useState([]);
  const [statesOfCountry, setStatesOfCountry] = useState([]);
  const [postcode, setPostcode] = useState("");

  const formik = useFormik({
    initialValues: {
      applicationId: state.applicationId,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      stateOfCountry: "",
      // postCodeId: "MY-01-02",
      postCodeObj: null,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        postCodeId: values.postCodeObj.postcodeId,
      };

      // AnalyticsStep: End step
      analyticsStep.handleStepEnd();

      actions.updateOnboarding(data);
    },
  });

  useEffect(() => {
    // Autofill form from piiData
    if (state.piiData?.homeAddress && postcodeOptions.length) {
      setIsBoostJourney(true);
      formik.setValues((prevValues) => ({
        ...prevValues,
        addressLine1: state.piiData.homeAddress?.addressLine1,
        addressLine2: state.piiData.homeAddress?.addressLine2,
        addressLine3: state.piiData.homeAddress?.addressLine3,
        postCodeObj: getPostcodeObjByPostcode(state.piiData.homeAddress?.postcode),
      }));
    }
  }, [state.piiData, postcodeOptions]);

  const getPostcodeObjByPostcode = (postCodeStr) => {
    if (String(postCodeStr).length == 5) {
      return postcodeOptions.find((option) => option.postcode == postCodeStr);
    }
    return undefined;
  };

  const handlePostcodeStrChange = (postCodeStr) => {
    const postcodeObj = getPostcodeObjByPostcode(postCodeStr);
    if (postcodeObj) {
      handlePostcodeChange(postcodeObj);
    }
  };

  const handlePostcodeChange = (postcodeObj) => {
    if (postcodeObj) {
      const found = statesOfCountry.find((obj) => obj.stateId === postcodeObj.stateId);

      formik.setValues((prevValues) => ({
        ...prevValues,
        stateOfCountry: found.name,
        city: postcodeObj.postOfficeLocation,
        postCodeObj: postcodeObj,
      }));
    } else {
      formik.setValues((prevValues) => ({
        ...prevValues,
        stateOfCountry: "",
        city: "",
        postCodeObj: null,
      }));
    }
  };

  useEffect(() => {
    if (!state.postcodes) {
      actions.getPostcodes();
    }
  }, []);

  useEffect(() => {
    if (state.postcodes) {
      setPostcodeOptions(state.postcodes.postcodes);
      setStatesOfCountry(state.postcodes.states);
    }
  }, [state.postcodes]);

  return (
    state.prerequisites
    && (
      <>
        <Header />
        <div className="page page-address">
          <div className={`${classes.content} ${classes.customScrollbar} content custom-scrollbar`}>
            <IconButton className="btn-back">
              <img alt="back" src="/images/rounded-left-arrow.svg" />
            </IconButton>
            {stepText && (
              <div className="stepper-holder">
                <span className={`${classes.stepText} step-text`}>
                  {stepText}
                </span>
                {/* { state.prerequisites?.themingConfigurations?.asset?.lockIconUrl && <img className="step-icon" alt="lock" src={state.prerequisites?.themingConfigurations?.asset.lockIconUrl} />} */}
                <img className="step-icon" alt="lock" src="https://aspirasi-static.s3.ap-southeast-1.amazonaws.com/paylater/assets/20210817/icon/0cb37674-336d-4f5d-a77d-2af6a74ad0ea/lock_icon_red.svg" />
              </div>
            )}

            {isBoostJourney ? (
              <div>
                <p className={`${classes.title} title`}>Fill In Your Address</p>
                <p className={`${classes.subtitle} sub-title`}>This will help us to send document easily.</p>
              </div>
            ) : (
              <div>
                <p className={`${classes.title} title`}>Where are you from?</p>
                <p className={`${classes.subtitle} sub-title`}>Please provide us with your details.</p>
              </div>
            )}

            <form onSubmit={formik.handleSubmit}>
              <div className="form-control-holder form-address">
                <p className="form-title">Billing Address</p>
                <input
                  id="addressLine1"
                  name="addressLine1"
                  placeholder="Address 1"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.addressLine1 && formik.errors.addressLine1 })}
                  value={formik.values.addressLine1}
                  // disabled={isBoostJourney}
                  onChange={formik.handleChange}
                  {...analyticsStep.inputProps}
                  // onBlur={formik.handleBlur}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    analyticsStep.inputProps.onBlur(e);
                  }}
                />
                {formik.touched.addressLine1 && formik.errors.addressLine1
                  ? (<span className="form-error">{formik.errors.addressLine1}</span>) : null}
              </div>
              <div className="form-control-holder form-address">
                <input
                  id="addressLine2"
                  name="addressLine2"
                  placeholder="Address 2"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.addressLine2 && formik.errors.addressLine2 })}
                  value={formik.values.addressLine2}
                  // disabled={isBoostJourney}
                  onChange={formik.handleChange}
                  {...analyticsStep.inputProps}
                  // onBlur={formik.handleBlur}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    analyticsStep.inputProps.onBlur(e);
                  }}
                />
                {formik.touched.addressLine2 && formik.errors.addressLine2
                  ? (<span className="form-error">{formik.errors.addressLine2}</span>) : null}
              </div>
              <div className="form-control-holder">
                <input
                  id="addressLine3"
                  name="addressLine3"
                  placeholder="Address 3"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.addressLine3 && formik.errors.addressLine3 })}
                  value={formik.values.addressLine3}
                  // disabled={isBoostJourney}
                  onChange={formik.handleChange}
                  {...analyticsStep.inputProps}
                  // onBlur={formik.handleBlur}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    analyticsStep.inputProps.onBlur(e);
                  }}
                />
                {formik.touched.addressLine3 && formik.errors.addressLine3
                  ? (<span className="form-error">{formik.errors.addressLine3}</span>) : null}
              </div>
              <div className="form-control-holder">
                <p className="form-title">Postcode</p>
                <Autocomplete
                  id="postCodeObj"
                  name="postCodeObj"
                  className={clsx("form-auto-complete", classes.inputSelectBox, { "form-error-border": formik.touched.postCodeObj && formik.errors.postCodeObj }, { "form-disabled": false /* isBoostJourney */ })}
                  inputValue={postcode}
                  value={formik.values.postCodeObj}
                  onInputChange={(event, userInputPostcode) => {
                    setPostcode(userInputPostcode);
                    handlePostcodeStrChange(userInputPostcode);
                  }}
                  onChange={(event, userSelectedPostcodeObj) => {
                    handlePostcodeChange(userSelectedPostcodeObj);
                  }}
                  filterOptions={(options, { inputValue }) => filterOptions(options, postcode)}
                  options={postcodeOptions}
                  getOptionLabel={(option) => option.postcode}
                  getOptionSelected={(option) => option.postcodeId}
                  renderInput={(params) => (
                    <TextField
                      className="form-text-field"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        maxLength: 5,
                        placeholder: "Postcode",
                        name: "postCodeObj",
                        ...analyticsStep.inputProps
                      }}
                    // disabled={isBoostJourney}
                    />
                  )}
                />
                {formik.touched.postCodeObj && formik.errors.postCodeObj
                  ? (<span className="form-error">{formik.errors.postCodeObj}</span>) : null}
              </div>
              <div className="form-control-holder">
                <p className="form-title">City</p>
                <input
                  id="City"
                  name="City"
                  disabled
                  placeholder="City"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.city && formik.errors.city })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
                {formik.touched.city && formik.errors.city
                  ? (<span className="form-error">{formik.errors.city}</span>) : null}
              </div>
              <div className="form-control-holder">
                <p className="form-title">State</p>
                <input
                  id="stateOfCountry"
                  name="stateOfCountry"
                  disabled
                  placeholder="State"
                  className={clsx("form-input", classes.inputTextBox, { "form-error-border": formik.touched.stateOfCountry && formik.errors.stateOfCountry })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.stateOfCountry}
                />
                {formik.touched.stateOfCountry && formik.errors.stateOfCountry
                  ? (<span className="form-error">{formik.errors.stateOfCountry}</span>) : null}
              </div>
              <div className="action-holder">
                <Button className={`${classes.btnContained} btn-next`} variant="contained" color="primary" type="submit">
                  Continue
                </Button>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </>
    )
  );
};

export default withUbdAnalyticsStep(Step4Address, {
  stepId: "Step4Address",
  capturingFieldNames: ["addressLine1", "addressLine2", "addressLine3", "postCodeObj"],
});
