import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fab, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, IconButton, FormControlLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { StoreContext } from "../../../store/context";
import Header from "../../../components/Header";

import "./WakalahContract.scss";

const useStyles = makeStyles({
  title: {
    padding: "8px 24px"
  },
  closeButton: {
    position: "absolute",
    right: 1,
    top: 1
  },
  paperFullWidth: {
    width: "calc(100% - 30px)"
  },
  paper: {
    height: "100%",
    margin: "15px"
  },
  dialogContentText: {
    height: "100%",
    padding: "0px",
    margin: "0px"
  },
  paperScrollPaper: {
    "max-height": "calc(95% - 30px)"
  },
  dialogContent: {
    // "overflow-y": "clip",
    padding: "0px 2px"
  }
});

const WakalahContract = () => {
  const classes = useStyles();
  const { state, actions } = useContext(StoreContext);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const handleDisagree = () => {
    actions.setStep("PaymentPlan");
  };

  const handleAgree = () => {
    actions.acceptInitiationAgreement();
  };

  return (
    state.prerequisites
      && (
        <div>
          <Header />
          <div className="page-wakalah-contract">
            <Dialog
              open
              // onClose={handleDisagree}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="md"
              className="PdfDialog"
              classes={{ paper: classes.paper, paperScrollPaper: classes.paperScrollPaper, paperFullWidth: classes.paperFullWidth }}
            >
              <DialogTitle classes={{ root: classes.title }} id="alert-dialog-title">
                {t("wakalahContract.name")}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleDisagree}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.dialogContent }}>
                <DialogContentText classes={{ root: classes.dialogContentText }} id="alert-dialog-description">
                  <object data={state.initiationAgreement?.initiationAgreementUrl} type="application/pdf" style={{ width: "100%", height: "100%" }}>
                    <iframe
                      src={`https://docs.google.com/viewer?url=${state.initiationAgreement?.initiationAgreementUrl}&embedded=true`}
                      style={{ width: "100%", height: "100%" }}
                      title="Wakalah Contract"
                      frameBorder="0"
                    />
                  </object>
                </DialogContentText>
              </DialogContent>
              <div className="dialog-bottom">
                <FormControlLabel
                  className="terms-holder"
                  control={
                    <img className="agreement-checkbox" onClick={() => setChecked(!checked)} alt="agreement" height="24" src={checked ? "/images/checkbox_active.svg" : "/images/checkbox_inactive.svg"} />
                    // <Checkbox color="primary" className="terms-cb" checked={checked} name="termsAcknowledgement" onChange={() => setChecked(!checked)} />
                  }
                  label={(
                    <div>
                      <p className="terms-text" dangerouslySetInnerHTML={{ __html: state.initiationAgreement?.initiationAgreementDecoration }} />
                    </div>
                  )}
                />
                <DialogActions>
                  {/* <Button onClick={handleDisagree} color="primary">
                    Disagree
                  </Button>
                  <Button onClick={handleAgree} disabled={!checked} color="primary" autoFocus variant="contained">
                    Agree
                  </Button> */}
                  <Fab size="small" onClick={handleDisagree} color="secondary" aria-label="disagree">
                    <img className="" alt="cancel" height="24" src="/images/ico_cancel.svg" />
                  </Fab>
                  <Fab size="small" onClick={handleAgree} disabled={!checked} color="primary" aria-label="agree">
                    <img className="" alt="next" height="24" src="/images/ico_arrownext.svg" />
                  </Fab>
                </DialogActions>
              </div>
            </Dialog>
          </div>
        </div>
      )
  );
};

export default WakalahContract;
